import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AuthRoute from 'routes/AuthRoute';
import PublicRoute from 'routes/PublicRoute';
import {
  Home,
  HealthZ,
  SigninCover,
  Admin,
  InviteUser,
  CreateContest,
  StandardData,
  Contests,
  DemoRequests,
  FineTuneModels,
  RagQueries,
  FeedbackView,
  CompetitionParticipants,
  Practice,
  ContestReport,
  Jobs,
  LabellingFramework,
  AICreateContest,
  ConversationAPI,
  SalesCallAnalysis,
  AWSTranscribePoc,
  FETranscriptionPoc,
  Users,
  Prompts,
  Bots,
  ConversationAI,
  ConversationAIAdmin,
  PracticeCallLinks,
  BotsRequests,
  SalesCallAnalysisUsers,
  PlaybookGeneration,
  PrivacyTos,
} from 'views';
import ConversationAIUsers from 'views/Common/ConversationAIUsers/ConversationAIUsers';
import Organizations from 'views/Common/Organizations';
import PlanManagement from 'views/Common/PlanManagement';
import Customers from 'views/Common/Customers';
import PresetQuestions from 'views/Common/PresetQuestions';
import DefaultSuggestions from 'views/Common/DefaultSuggestions';
import CustomPlan from 'views/Common/CustomPlan';
import WLClients from 'views/Common/WLClients';
import OrganizationsQuestionFramework from 'views/Common/OrganizationsQuestionFramework';
import InviteV2Customer from 'views/Common/InviteV2Customer';
import OrganizationsV2 from 'views/Common/OrganizationsV2';

const Routes = () => {
  return (
    <Switch>
      <PublicRoute restricted={false} exact path="/" component={Home} />
      <PublicRoute
        restricted={false}
        exact
        path="/healthz"
        component={HealthZ}
      />
      <PublicRoute restricted={false} exact path="/ready" component={HealthZ} />
      <PublicRoute
        restricted={true}
        exact
        path="/login-f550aeea-0bb4-41e9-b615-5346dvdsfg34"
        component={SigninCover}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740"
        exact
        component={Admin}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/inviteUser"
        exact
        component={InviteUser}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/invite"
        exact
        component={InviteV2Customer}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/customers"
        exact
        component={Customers}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/clients"
        exact
        component={WLClients}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/org/:version"
        exact
        component={Organizations}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/orgn/qf"
        exact
        component={OrganizationsQuestionFramework}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/orgn/v2"
        exact
        component={OrganizationsV2}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/users"
        exact
        component={Users}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/prompts/:version"
        exact
        component={Prompts}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/privacy-tos"
        exact
        component={PrivacyTos}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/plans"
        exact
        component={PlanManagement}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/all-plans"
        exact
        component={CustomPlan}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots"
        exact
        component={Bots}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots-requests"
        exact
        component={BotsRequests}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/preset-questions"
        exact
        component={PresetQuestions}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls"
        exact
        component={ConversationAI}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls-admin"
        exact
        component={ConversationAIAdmin}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/dvd"
        exact
        component={DefaultSuggestions}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/playbook-generation"
        exact
        component={PlaybookGeneration}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-call-links"
        exact
        component={PracticeCallLinks}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/demo-requests"
        exact
        component={DemoRequests}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/fine-tune-models"
        exact
        component={FineTuneModels}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/rag-queries"
        exact
        component={RagQueries}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/feedback-view"
        exact
        component={FeedbackView}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/conversation-ai-admin"
        exact
        component={ConversationAPI}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/conversation-ai-users"
        exact
        component={ConversationAIUsers}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis"
        exact
        component={SalesCallAnalysis}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis-users"
        exact
        component={SalesCallAnalysisUsers}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/aws-transcribe-poc"
        exact
        component={AWSTranscribePoc}
      />
      <AuthRoute
        path="/f550aeea-0bb4-41e9-b615-7a6cf101e740/fe-transcribe-poc"
        exact
        component={FETranscriptionPoc}
      />
      <Redirect to={'/not-found-cover'} />
    </Switch>
  );
};

export default Routes;
