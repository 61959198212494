import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Tabs,
  Tab,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExpandMore,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Search,
  Send,
  Settings,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import updateOrganization from 'redux/actions/Common/updateOrganization';
import { capitalizeText, validateEmail } from 'utils';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getPlans from 'redux/actions/Common/getPlans';
import updatePlanForOrganization from 'redux/actions/Common/updatePlanForOrganization';
import refreshUsage from 'redux/actions/Common/refreshUsage';
import refreshUsageForOrg from 'redux/actions/Common/refreshUsageForOrg';
import updatePlanForOrgUser from 'redux/actions/Common/updatePlanForOrgUser';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import updatePlanTrialEndsOn from 'redux/actions/Common/updatePlanTrialEndsOn';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';
import deepEqual from 'deep-equal';
import getBotsByUserId from 'redux/actions/Common/getBotsByUserId';
import addCreditsForOrg from 'redux/actions/Common/addCreditsForOrg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchemaPlaybook = yup.object().nullable();
const validationSchemaSearch = yup.object().nullable();

const OrganizationsV2 = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const { version } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    organizations,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allOrganizations);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [updatingRole, setUpdatingRole] = useState(false);

  const [organizationName, setOrganizationName] = useState('');
  const [customerType, setCustomerType] = useState('QAI-V1');
  const [country, setCountry] = useState('united states');
  const [withPlaybook, setWithPlaybook] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [teamRole, setTeamRole] = useState('ORG_ADMIN');
  const [error, setError] = useState('');

  const [sendingInvite, setSendingInvite] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [showPlaybookToManagersOnly, setShowPlaybookToManagersOnly] = useState(
    false,
  );
  const [transliterationInEnglish, setTransliterationInEnglish] = useState(
    false,
  );
  const [
    multiLanguageAnalysisSupport,
    setMultiLanguageAnalysisSupport,
  ] = useState(true);
  const [
    multiLanguageRoleplaySupport,
    setMultiLanguageRoleplaySupport,
  ] = useState(true);

  const [supportedLanguages, setSupportedLanguages] = useState(['english']);

  const [supportedAnalysisLanguages, setSupportedAnalysisLanguages] = useState([
    'english',
  ]);
  const [supportedRoleplayLanguages, setSupportedRoleplayLanguages] = useState([
    'english',
  ]);
  const [defaultAnalysisLanguages, setDefaultAnalysisLanguages] = useState([
    'english',
  ]);
  const [defaultRoleplayLanguages, setDefaultRoleplayLanguages] = useState([
    'english',
  ]);

  const [defaultCallType, setDefaultCallType] = useState('outbound');

  const [tags, setTags] = useState([]);
  const [showUpdateTrialDialog, setShowUpdateTrialDialog] = useState(false);
  const [type, setType] = useState('date');
  const [date, setDate] = useState(Date.now());
  const [days, setDays] = useState(14);

  const [withContentScoringConfig, setWithContentScoringConfig] = useState(
    false,
  );
  const [
    showEditContentScoringConfig,
    setShowEditContentScoringConfig,
  ] = useState(false);

  const [contentScoringConfig, setContentScoringConfig] = useState({
    inbound: null,
    outbound: null,
  });

  const [callType, setCallType] = useState('outbound');
  const [sameConfig, setSameConfig] = useState(true);

  const [criteriaName, setCriteriaName] = useState('');
  const [criteriaFactorName, setCriteriaFactorName] = useState('');

  const [configType, setConfigType] = useState('form');

  const [contentScoreConfigText, setContentScoreConfigText] = useState({
    inbound: '',
    outbound: '',
  });
  const [roleplayVisibilityControl, setRoleplayVisibilityControl] = useState(
    false,
  );
  const [crmAutoSync, setCrmAutoSync] = useState(false);

  const [showAssignedBots, setShowAssignedBots] = useState(null);
  const [assignedBots, setAssignedBots] = useState([]);
  const [fetchingAssingedBots, setFetchingAssignedBots] = useState(false);

  const [showAddCreditsDialog, setShowAddCreditsDialog] = useState(false);
  const [credits, setCredits] = useState(100);
  const [creditExpiryTimeUnit, setCreditsExpiryTimeUnit] = useState('month');
  const [creditExpiryTimeValue, setCreditExpiryTimeValue] = useState(1);
  const [creditAdmin, setCreditAdmin] = useState('deepak');
  const [purpose, setPurpose] = useState('');
  const [
    showConfirmResetSubscriptionDialog,
    setShowConfirmResetSubscriptionDialog,
  ] = useState(false);

  const [currentTab, setCurrentTab] = useState('settings');

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    keyFeatures: '',
    idealCustomerProfile: '',
    methodologies: [],
    customerQualificationQuestions: '',
    objectionsAndAnswers: [
      {
        question: '',
        answer: '',
      },
    ],
    challengesForCustomer: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
    awardsRatingAndReviews: '',
  };

  const initialValuesSearch = {
    searchKeyword: '',
  };

  const onSubmitPlaybook = (values) => {
    console.log(values);
    if (withPlaybook) {
      dispatch(
        updateOrganization(
          selectedOrganization,
          {
            organizationName,
            methodologies: formikPlaybook.values.methodologies,
            showPlaybookToManagersOnly,
            languagesForAnalysis: supportedAnalysisLanguages,
            languagesForRolePlay: supportedRoleplayLanguages,
            defaultLanguagesForAnalysis: defaultAnalysisLanguages,
            defaultLanguagesForRolePlay: defaultRoleplayLanguages,
            rolePlayVisibilityControl: roleplayVisibilityControl,
            transliterationInEnglish,
            crmAutoSync: crmAutoSync,
            contentScoringConfig: withContentScoringConfig
              ? {
                  inbound: sameConfig
                    ? contentScoringConfig.outbound
                    : contentScoringConfig.inbound,
                  outbound: contentScoringConfig.outbound,
                }
              : null,
            playbookTags: tags,
            generalAutoAnalysis: organizations?.find(
              (org) => org?.id === selectedOrganization,
            )?.integrations?.generalAutoAnalysis,
            playbookStructure: {
              // bant: formikPlaybook.values.methodologies.includes('bant'),
              companyName: formikPlaybook?.values?.companyName,
              competingProducts: formikPlaybook.values.competingProducts,
              customerQualificationQuestions:
                formikPlaybook.values.customerQualificationQuestions,
              customerQuestions: formikPlaybook.values.objectionsAndAnswers,
              challengesForCustomer:
                formikPlaybook.values.challengesForCustomer,
              expectedCallToAction: formikPlaybook.values.expectedCallToAction,
              idealCustomerProfile: formikPlaybook.values.idealCustomerProfile,
              productDescription: formikPlaybook.values.productDescription,
              productKeyFeatures: formikPlaybook.values.keyFeatures,
            },
          },
          () => {
            dispatch(
              getAllOrganizations(
                [],
                true,
                'n_org',
                15,
                null,
                version === 'v1' ? 'all' : 'v2',
                () => {
                  setSelectedOrganization(null);
                },
              ),
            );
            alert('Information Updated!');
          },
        ),
      );
    }
  };

  const onSearch = (values) => {
    console.log(values);
    if (values?.searchKeyword?.trim()) {
      dispatch(
        getAllOrganizations(
          [values?.searchKeyword],
          true,
          'n_org',
          15,
          null,
          version === 'v1' ? 'all' : 'v2',
          () => {},
        ),
      );
    } else {
      dispatch(
        getAllOrganizations(
          [],
          true,
          'n_org',
          15,
          null,
          version === 'v1' ? 'all' : 'v2',
          () => {},
        ),
      );
    }
  };

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const formikSearch = useFormik({
    initialValues: initialValuesSearch,
    validationSchema: validationSchemaSearch,
    onSubmit: onSearch,
  });

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        organizations &&
        organizations.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllOrganizations(
          formikSearch?.values?.searchKeyword
            ? [formikSearch?.values?.searchKeyword]
            : [],
          true,
          'n_org',
          15,
          lastTime,
          version === 'v1' ? 'all' : 'v2',
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    if (selectedOrganization) {
      setSelectedPlan(
        organizations?.find((org) => org?.id === selectedOrganization)?.plan
          ?.id,
      );
      setCountry(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.countries?.[0] || 'united states',
      );
      setOrganizationName(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.organizationName || '',
      );
      setShowPlaybookToManagersOnly(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.showPlaybookToManagersOnly || false,
      );
      setTransliterationInEnglish(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.transliterationInEnglish || false,
      );
      setSupportedAnalysisLanguages(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.languagesForAnalysis || ['english'],
      );
      setSupportedRoleplayLanguages(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.languagesForRolePlay || ['english'],
      );
      setDefaultAnalysisLanguages(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.defaultLanguagesForAnalysis || ['english'],
      );
      setDefaultRoleplayLanguages(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.defaultLanguagesForRolePlay || ['english'],
      );
      setDefaultCallType(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.salesCallType || 'outbound',
      );
      formikPlaybook.setValues({
        companyName:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.companyName || '',
        productDescription:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.productDescription || '',
        keyFeatures:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.productKeyFeatures || '',
        idealCustomerProfile:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.idealCustomerProfile || '',
        methodologies:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.methodologies || [],
        competingProducts:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.competingProducts || '',
        expectedCallToAction:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.expectedCallToAction || '',
        customerQualificationQuestions:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.customerQualificationQuestions || '',
        objectionsAndAnswers:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.customerQuestions || [],
        challengesForCustomer:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.challengesForCustomer || [],
      });
      setDate(
        organizations?.find((org) => org?.id === selectedOrganization)?.plan
          ?.trailEndsOn,
      );
      setWithContentScoringConfig(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig || false,
      );
      setRoleplayVisibilityControl(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.rolePlayVisibilityControl || false,
      );
      setCrmAutoSync(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.crmAutoSync || false,
      );
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (showEditContentScoringConfig) {
      setSameConfig(
        deepEqual(
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.contentScoringConfig?.inbound,
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.contentScoringConfig?.outbound,
        ),
      );
      if (
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig?.inbound
      ) {
        setContentScoringConfig((prev) => {
          return {
            ...prev,
            inbound: organizations?.find(
              (org) => org?.id === selectedOrganization,
            )?.contentScoringConfig?.inbound,
          };
        });
        setContentScoreConfigText((prev) => {
          return {
            ...prev,
            inbound: JSON.stringify(
              Object.keys(
                organizations?.find((org) => org?.id === selectedOrganization)
                  ?.contentScoringConfig?.inbound,
              )?.reduce((acc, curr) => {
                acc[curr] = organizations?.find(
                  (org) => org?.id === selectedOrganization,
                )?.contentScoringConfig?.inbound?.[curr];
                return acc;
              }, {}),
              null,
              2,
            ),
          };
        });
      } else {
        dispatch(
          getContentScoringConfigTemplate((data) => {
            setContentScoringConfig((prev) => {
              return {
                ...prev,
                inbound: data?.callType?.inbound,
              };
            });
            setContentScoreConfigText((prev) => {
              return {
                ...prev,
                inbound: JSON.stringify(data?.callType?.inbound, null, 2),
              };
            });
          }),
        );
      }
      if (
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig?.outbound
      ) {
        setContentScoringConfig((prev) => {
          return {
            ...prev,
            outbound: organizations?.find(
              (org) => org?.id === selectedOrganization,
            )?.contentScoringConfig?.outbound,
          };
        });
        setContentScoreConfigText((prev) => {
          return {
            ...prev,
            outbound: JSON.stringify(
              Object.keys(
                organizations?.find((org) => org?.id === selectedOrganization)
                  ?.contentScoringConfig?.outbound,
              )?.reduce((acc, curr) => {
                acc[curr] = organizations?.find(
                  (org) => org?.id === selectedOrganization,
                )?.contentScoringConfig?.outbound?.[curr];
                return acc;
              }, {}),
              null,
              2,
            ),
          };
        });
      } else {
        dispatch(
          getContentScoringConfigTemplate((data) => {
            setContentScoringConfig((prev) => {
              return {
                ...prev,
                outbound: data?.callType?.outbound,
              };
            });
            setContentScoreConfigText((prev) => {
              return {
                ...prev,
                outbound: JSON.stringify(data?.callType?.outbound, null, 2),
              };
            });
          }),
        );
      }
    }
  }, [showEditContentScoringConfig, selectedOrganization]);

  useEffect(() => {
    if (showAssignedBots) {
      setFetchingAssignedBots(true);
      dispatch(
        getBotsByUserId(showAssignedBots, (data) => {
          console.log(data);
          setAssignedBots([...(data?.personal || []), ...(data?.public || [])]);
          setFetchingAssignedBots(false);
        }),
      );
    }
  }, [showAssignedBots]);

  useEffect(() => {
    if (version === 'v1') {
      setCurrentTab('settings');
    } else {
      setCurrentTab('framework');
    }
  }, [version]);

  useEffect(() => {
    dispatch(
      getAllOrganizations(
        [],
        true,
        'n_org',
        15,
        null,
        version === 'v1' ? 'all' : 'v2',
        () => {},
      ),
    );
    dispatch(
      getPlans((plans) => {
        setPlans(plans);
        // setFetchingPlans(false);
      }),
    );
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setSupportedLanguages(data?.supportedLanguages || ['english']);
      }),
    );
  }, []);

  // console.log(contentScoringConfig);
  // console.log(
  //   deepEqual(contentScoringConfig.inbound, contentScoringConfig.outbound),
  // );
  // console.log(contentScoringConfig);
  // console.log(contentScoreConfigText);
  console.log(version);
  console.log(selectedOrganization);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          sx={{ position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 2,
              zIndex: 12,
            }}
          >
            <form onSubmit={formikSearch.handleSubmit}>
              <TextField
                label="Search"
                variant="outlined"
                name={'searchKeyword'}
                size="small"
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={formikSearch.values.searchKeyword}
                onChange={(e) => {
                  formikSearch.setFieldValue('searchKeyword', e.target.value);
                }}
              />
            </form>
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                    paddingTop: 10,
                  }
            }
          >
            {fetching ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <CircularProgress size={16} />
                <Typography>Loading organizations ...</Typography>
              </Box>
            ) : (
              organizations?.map((org) => (
                <Box
                  // flex={1}
                  key={org?.id}
                  sx={{
                    // flex: 1,
                    width: 1,
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    cursor: 'pointer',
                    background:
                      selectedOrganization === org?.id
                        ? theme.palette.primary.main
                        : 'default',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setSelectedOrganization(org?.id)}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.primary',
                    }}
                  >
                    {org?.organizationName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.secondary',
                    }}
                  >
                    {org?.customFields?.users?.length || 0} Members
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.secondary',
                    }}
                  >
                    {org?.customFields?.users?.find(
                      (user) => user?.id === org?.adminId,
                    )?.email || '--'}
                  </Typography>
                </Box>
              ))
            )}
            {fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more organizations ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && organizations?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? 0 : 0,
              }}
            >
              {version === 'v2' ? (
                <Tabs
                  value={currentTab}
                  onChange={(e, newValue) => {
                    setCurrentTab(newValue);
                  }}
                  aria-label="Tabs"
                  orientation={'horizontal'}
                  // indicatorColor={theme.palette.background.default}
                  variant="scrollable"
                  // scrollButtons={!isMd}
                  allowScrollButtonsMobile
                  sx={{ border: '1px solid #d3d3d3' }}
                >
                  <Tab
                    value={'framework'}
                    label={
                      <Typography variant="caption" fontWeight={'bold'}>
                        Question Framework
                      </Typography>
                    }
                    id={`simple-tab-${0}`}
                    aria-controls={`simple-tabpanel-${0}`}
                    sx={{}}
                  />
                  <Tab
                    value={'settings'}
                    label={
                      <Typography variant="caption" fontWeight={'bold'}>
                        Settings
                      </Typography>
                    }
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                </Tabs>
              ) : null}
              {selectedOrganization ? (
                version === 'v1' || currentTab === 'settings' ? (
                  <Box>
                    <Accordion
                      expanded={settingsExpanded}
                      onChange={() => {
                        setSettingsExpanded((prev) => !prev);
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="submission-content"
                        id="submission-header"
                        sx={{
                          height: '30px',
                          background: '#d3d3d3',
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        Organization Settings
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display={'flex'} gap={2}>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                              alignItems={'start'}
                              sx={{
                                flex: 1,
                                padding: 2,
                                border: '1px solid #d3d3d3',
                                borderRadius: 2,
                                position: 'relative',
                                paddingTop: 4,
                              }}
                            >
                              <Typography
                                variant={'body1'}
                                sx={{
                                  position: 'absolute',
                                  top: -20,
                                  left: 10,
                                  background: '#fff',
                                  padding: 1,
                                }}
                                color={'text.secondary'}
                              >
                                Update Plan
                              </Typography>
                              <Box display={'flex'} gap={1}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    dispatch(
                                      refreshUsageForOrg(
                                        selectedOrganization,
                                        () => {
                                          alert('Information Updated');
                                        },
                                      ),
                                    );
                                  }}
                                >
                                  Refresh Usage
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setShowUpdateTrialDialog(true);
                                  }}
                                >
                                  Update Trial
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setShowAssignedBots(
                                      organizations?.find(
                                        (org) =>
                                          org?.id === selectedOrganization,
                                      )?.adminId,
                                    );
                                  }}
                                >
                                  Linked Bots
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setShowAddCreditsDialog(
                                      organizations?.find(
                                        (org) =>
                                          org?.id === selectedOrganization,
                                      )?.id,
                                    );
                                  }}
                                >
                                  Add Credits
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setShowConfirmResetSubscriptionDialog(true);
                                  }}
                                >
                                  Reset Subscription
                                </Button>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                // sx={{ marginBottom: 1 }}
                              >
                                Select Plan
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  labelId="plan-select-label"
                                  id="plan-select"
                                  name="selectedPlan"
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) => {
                                    dispatch(
                                      updatePlanForOrganization(
                                        selectedOrganization,
                                        e.target.value,
                                        () => {
                                          setSelectedPlan(e.target.value);
                                          alert('Information updated');
                                        },
                                      ),
                                    );
                                  }}
                                  value={selectedPlan}
                                >
                                  {plans
                                    ?.filter((plan) => !plan?.forWlOrg)
                                    ?.map((plan) => (
                                      <MenuItem key={0} value={plan?.id}>
                                        {capitalizeText(plan?.title)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                              alignItems={'start'}
                              sx={{
                                flex: 1,
                                padding: 2,
                                border: '1px solid #d3d3d3',
                                borderRadius: 2,
                                position: 'relative',
                                paddingTop: 4,
                              }}
                            >
                              <Typography
                                variant={'body1'}
                                sx={{
                                  position: 'absolute',
                                  top: -20,
                                  left: 10,
                                  background: '#fff',
                                  padding: 1,
                                }}
                                color={'text.secondary'}
                              >
                                Update Details
                              </Typography>
                              <Box
                                display={'flex'}
                                gap={2}
                                alignItems={'center'}
                              >
                                <FormControl sx={{ minWidth: 300 }}>
                                  <InputLabel id="country-select-label">
                                    Select Country
                                  </InputLabel>
                                  <Select
                                    size="small"
                                    labelId="country-select-label"
                                    id="country-select"
                                    name="country"
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                    input={
                                      <OutlinedInput label="Select Country" />
                                    }
                                  >
                                    <MenuItem key={0} value="india">
                                      India
                                    </MenuItem>
                                    <MenuItem key={1} value="united states">
                                      United States
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 300 }}>
                                  <InputLabel id="country-select-label">
                                    Select Customer Type
                                  </InputLabel>
                                  <Select
                                    size="small"
                                    labelId="customerType-select-label"
                                    id="customerType-select"
                                    name="customerType"
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) =>
                                      setCustomerType(e.target.value)
                                    }
                                    value={customerType}
                                    input={
                                      <OutlinedInput label="Select Customer Type" />
                                    }
                                  >
                                    <MenuItem key={0} value="QAI-V1">
                                      Qualification AI
                                    </MenuItem>
                                    <MenuItem key={1} value="QAI-V2">
                                      Deal AI
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                              <TextField
                                sx={{ minWidth: 300 }}
                                label="Organization Name"
                                variant="outlined"
                                name={'organizationName'}
                                size="small"
                                // fullWidth
                                value={organizationName}
                                onChange={(e) =>
                                  setOrganizationName(e.target.value)
                                }
                              />
                              <Grid item xs={12}>
                                <Typography
                                  variant={'subtitle2'}
                                  // sx={{  }}
                                  color={'text.secondary'}
                                  fontWeight={'bold'}
                                >
                                  <span>
                                    Pick all the sales methodologies that the
                                    sales team is expected to follow.
                                  </span>
                                </Typography>
                                <FormControl
                                  component="fieldset"
                                  variant="standard"
                                >
                                  <FormGroup row>
                                    <FormControlLabel
                                      checked={formikPlaybook.values.methodologies?.includes(
                                        'meddpic',
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={'meddpic'}
                                          name="methods"
                                        />
                                      }
                                      label="MEDDICC"
                                      onChange={(e) => {
                                        if (
                                          formikPlaybook.values.methodologies?.includes(
                                            'meddpic',
                                          )
                                        ) {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            formikPlaybook.values.methodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            [
                                              ...formikPlaybook.values
                                                .methodologies,
                                              e.target.value,
                                            ],
                                          );
                                        }
                                      }}
                                    />
                                    <FormControlLabel
                                      checked={formikPlaybook.values.methodologies?.includes(
                                        'spin',
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={'spin'}
                                          name="methods"
                                        />
                                      }
                                      label="SPIN"
                                      onChange={(e) => {
                                        if (
                                          formikPlaybook.values.methodologies?.includes(
                                            'spin',
                                          )
                                        ) {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            formikPlaybook.values.methodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            [
                                              ...formikPlaybook.values
                                                .methodologies,
                                              e.target.value,
                                            ],
                                          );
                                        }
                                      }}
                                    />
                                    <FormControlLabel
                                      checked={formikPlaybook.values.methodologies?.includes(
                                        'spiced',
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={'spiced'}
                                          name="methods"
                                        />
                                      }
                                      label="SPICED"
                                      onChange={(e) => {
                                        if (
                                          formikPlaybook.values.methodologies?.includes(
                                            'spiced',
                                          )
                                        ) {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            formikPlaybook.values.methodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            [
                                              ...formikPlaybook.values
                                                .methodologies,
                                              e.target.value,
                                            ],
                                          );
                                        }
                                      }}
                                    />
                                    <FormControlLabel
                                      checked={formikPlaybook.values.methodologies?.includes(
                                        'bant',
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={'bant'}
                                          name="methods"
                                        />
                                      }
                                      label="BANT"
                                      onChange={(e) => {
                                        if (
                                          formikPlaybook.values.methodologies?.includes(
                                            'bant',
                                          )
                                        ) {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            formikPlaybook.values.methodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            [
                                              ...formikPlaybook.values
                                                .methodologies,
                                              e.target.value,
                                            ],
                                          );
                                        }
                                      }}
                                    />
                                    <FormControlLabel
                                      checked={formikPlaybook.values.methodologies?.includes(
                                        'challenger',
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={'challenger'}
                                          name="methods"
                                        />
                                      }
                                      label="CHALLENGER"
                                      onChange={(e) => {
                                        if (
                                          formikPlaybook.values.methodologies?.includes(
                                            'challenger',
                                          )
                                        ) {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            formikPlaybook.values.methodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          formikPlaybook.setFieldValue(
                                            'methodologies',
                                            [
                                              ...formikPlaybook.values
                                                .methodologies,
                                              e.target.value,
                                            ],
                                          );
                                        }
                                      }}
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={showPlaybookToManagersOnly}
                                    name="showPlaybookToManagersOnly"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Show playbook to managers only
                                  </Typography>
                                }
                                onChange={(e) => {
                                  setShowPlaybookToManagersOnly(
                                    e.target.checked,
                                  );
                                }}
                              />
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                position={'relative'}
                                sx={{
                                  border: multiLanguageAnalysisSupport
                                    ? '1px solid #d3d3d3'
                                    : 'none',
                                  width: 1,
                                  borderRadius: 1,
                                  padding: multiLanguageAnalysisSupport ? 2 : 0,
                                  minHeight: 20,
                                }}
                              >
                                <Typography
                                  sx={{
                                    position: 'absolute',
                                    top: -12,
                                    left: 10,
                                    background: '#fff',
                                  }}
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Multi Language Analysis
                                </Typography>
                                {multiLanguageAnalysisSupport ? (
                                  <Box display={'flex'} gap={2}>
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        width={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          sx={{ flex: 1 }}
                                        >
                                          Supported Languages
                                        </Typography>
                                        <FormControl size="small">
                                          <Select
                                            size="small"
                                            multiple
                                            labelId="analysis-lang-support-select-label"
                                            id="analysis-lang-support-select"
                                            name="supportedAnalysisLanguages"
                                            MenuProps={{
                                              disableScrollLock: true,
                                            }}
                                            onChange={(e) => {
                                              setSupportedAnalysisLanguages(
                                                e.target.value,
                                              );
                                            }}
                                            value={supportedAnalysisLanguages}
                                          >
                                            {supportedLanguages.map((lang) => (
                                              <MenuItem key={lang} value={lang}>
                                                <Typography variant="caption">
                                                  {capitalizeText(lang)}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                        width={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          // sx={{ marginBottom: 1 }}
                                          sx={{ flex: 1 }}
                                        >
                                          Default Languages
                                        </Typography>
                                        <FormControl size="small">
                                          <Select
                                            size="small"
                                            multiple
                                            labelId="analysis-default-language-select-label"
                                            id="analysis-default-language-select"
                                            name="defaultAnalysisLanguages"
                                            MenuProps={{
                                              disableScrollLock: true,
                                            }}
                                            onChange={(e) => {
                                              setDefaultAnalysisLanguages(
                                                e.target.value,
                                              );
                                            }}
                                            value={defaultAnalysisLanguages}
                                          >
                                            {supportedLanguages.map((lang) => (
                                              <MenuItem key={lang} value={lang}>
                                                <Typography variant="caption">
                                                  {capitalizeText(lang)}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Box>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={transliterationInEnglish}
                                          name="transliterationInEnglish"
                                        />
                                      }
                                      label={
                                        <Typography variant="subtitle2">
                                          Transliteration in English
                                        </Typography>
                                      }
                                      onChange={(e) => {
                                        setTransliterationInEnglish(
                                          e.target.checked,
                                        );
                                      }}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                position={'relative'}
                                sx={{
                                  border: multiLanguageRoleplaySupport
                                    ? '1px solid #d3d3d3'
                                    : 'none',
                                  width: 1,
                                  borderRadius: 1,
                                  padding: multiLanguageRoleplaySupport ? 2 : 0,
                                  minHeight: 20,
                                  mt: multiLanguageAnalysisSupport ? 2 : 0,
                                }}
                              >
                                <Typography
                                  sx={{
                                    position: 'absolute',
                                    top: -12,
                                    left: 10,
                                    background: '#fff',
                                  }}
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Multi Language Roleplay
                                </Typography>
                                {multiLanguageRoleplaySupport ? (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      width={1}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={'text.secondary'}
                                        sx={{ flex: 1 }}
                                      >
                                        Supported Languages
                                      </Typography>
                                      <FormControl size="small">
                                        <Select
                                          size="small"
                                          multiple
                                          labelId="roleplay-lang-support-select-label"
                                          id="roleplay-lang-support-select"
                                          name="supportedRoleplayLanguages"
                                          MenuProps={{
                                            disableScrollLock: true,
                                          }}
                                          onChange={(e) => {
                                            setSupportedRoleplayLanguages(
                                              e.target.value,
                                            );
                                          }}
                                          value={supportedRoleplayLanguages}
                                        >
                                          {supportedLanguages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                              <Typography variant="caption">
                                                {capitalizeText(lang)}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      width={1}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={'text.secondary'}
                                        // sx={{ marginBottom: 1 }}
                                        sx={{ flex: 1 }}
                                      >
                                        Default Languages
                                      </Typography>
                                      <FormControl size="small">
                                        <Select
                                          size="small"
                                          multiple
                                          labelId="roleplay-default-language-select-label"
                                          id="roleplay-default-language-select"
                                          name="defaultRoleplayLanguages"
                                          MenuProps={{
                                            disableScrollLock: true,
                                          }}
                                          onChange={(e) => {
                                            setDefaultRoleplayLanguages(
                                              e.target.value,
                                            );
                                          }}
                                          value={defaultRoleplayLanguages}
                                        >
                                          {supportedLanguages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                              <Typography variant="caption">
                                                {capitalizeText(lang)}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                ) : null}
                              </Box>
                              <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    Default Call Type
                                  </Typography>
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={defaultCallType}
                                  onChange={(e) =>
                                    setDefaultCallType(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value={'outbound'}
                                    control={<Radio size="small" />}
                                    label={'Outbound'}
                                  />
                                  <FormControlLabel
                                    value={'inbound'}
                                    control={<Radio size="small" />}
                                    label={'Inbound'}
                                  />
                                </RadioGroup>
                              </FormControl>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={withContentScoringConfig}
                                      name="withContentScoringConfig"
                                    />
                                  }
                                  label={
                                    <Typography variant="subtitle2">
                                      Call Scoring Configurations
                                    </Typography>
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShowEditContentScoringConfig(true);
                                    } else {
                                      setShowEditContentScoringConfig(false);
                                    }
                                    setWithContentScoringConfig(
                                      e.target.checked,
                                    );
                                  }}
                                />
                                {withContentScoringConfig ? (
                                  <Button
                                    startIcon={<Settings />}
                                    onClick={() =>
                                      setShowEditContentScoringConfig(true)
                                    }
                                  >
                                    Edit Configurations
                                  </Button>
                                ) : null}
                              </Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={roleplayVisibilityControl}
                                    name="roleplayVisibilityControl"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Roleplay visibility control
                                  </Typography>
                                }
                                onChange={(e) => {
                                  setRoleplayVisibilityControl(
                                    e.target.checked,
                                  );
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={crmAutoSync}
                                    name="crmAutoSync"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    CRM Auto Sync(Currently Hubspot only)
                                  </Typography>
                                }
                                onChange={(e) => {
                                  setCrmAutoSync(e.target.checked);
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={withPlaybook}
                                    name="withPlaybook"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Playbook
                                  </Typography>
                                }
                                onChange={(e) => {
                                  setWithPlaybook(e.target.checked);
                                }}
                              />
                              {withPlaybook ? (
                                <Box>
                                  <form onSubmit={formikPlaybook.handleSubmit}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                        <Box>
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                          >
                                            Playbook Title
                                          </Typography>
                                        </Box>
                                        <AutoCompleteMUI
                                          id="tags"
                                          value={tags}
                                          onChange={(
                                            event,
                                            newValue,
                                            reason,
                                          ) => {
                                            setTags([...newValue]);
                                            // console.log(reason);
                                          }}
                                          options={[]}
                                          onTextChange={(e) =>
                                            setTags([e.target.value])
                                          }
                                          onKeyDown={() => {}}
                                        />
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            // marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          About Your Company
                                        </Typography>
                                        <TextField
                                          placeholder="Enter about your company"
                                          variant="outlined"
                                          name={'companyName'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values.companyName
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .companyName &&
                                            Boolean(
                                              formikPlaybook.errors.companyName,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .companyName &&
                                            formikPlaybook.errors.companyName
                                          }
                                          type={'text'}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          color={'text.secondary'}
                                          variant={'subtitle2'}
                                          sx={{ marginBottom: 1 }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Product Description
                                          </span>
                                          <br />
                                          (Please describe your product in
                                          detail. Please also add URLs to
                                          specific webpages and shared documents
                                          if any.)
                                        </Typography>
                                        <TextField
                                          placeholder="Enter product description"
                                          variant="outlined"
                                          name={'productDescription'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .productDescription
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .productDescription &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .productDescription,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .productDescription &&
                                            formikPlaybook.errors
                                              .productDescription
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          color={'text.secondary'}
                                          variant={'subtitle2'}
                                          sx={{ marginBottom: 1 }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Product Key Features
                                          </span>
                                          <br />
                                          (Highlight USPs)
                                        </Typography>
                                        <TextField
                                          placeholder="Enter product key features"
                                          variant="outlined"
                                          name={'keyFeatures'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values.keyFeatures
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .keyFeatures &&
                                            Boolean(
                                              formikPlaybook.errors.keyFeatures,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .keyFeatures &&
                                            formikPlaybook.errors.keyFeatures
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          Describe your Ideal Customer Profile
                                        </Typography>
                                        <TextField
                                          placeholder="Describe your ideal customer profile"
                                          variant="outlined"
                                          name={'idealCustomerProfile'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .idealCustomerProfile
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .idealCustomerProfile &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .idealCustomerProfile,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .idealCustomerProfile &&
                                            formikPlaybook.errors
                                              .idealCustomerProfile
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Customer Qualification Questions:{' '}
                                          </span>
                                          (1 or more questions that you use to
                                          qualify your customers)
                                        </Typography>
                                        <TextField
                                          placeholder="Customer Qualification Questions:"
                                          variant="outlined"
                                          name={
                                            'customerQualificationQuestions'
                                          }
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .customerQualificationQuestions
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .customerQualificationQuestions &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .customerQualificationQuestions,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .customerQualificationQuestions &&
                                            formikPlaybook.errors
                                              .customerQualificationQuestions
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Top customer challenges and
                                            recommended responses
                                          </span>
                                        </Typography>
                                        {formikPlaybook.values
                                          .challengesForCustomer &&
                                          Array.isArray(
                                            formikPlaybook.values
                                              .challengesForCustomer,
                                          ) &&
                                          formikPlaybook.values
                                            .challengesForCustomer.length > 0 &&
                                          formikPlaybook.values.challengesForCustomer?.map(
                                            (objAndAns, faqIndex) => {
                                              return (
                                                <Box
                                                  key={faqIndex}
                                                  sx={{
                                                    border: '1px solid #d3d3d3',
                                                    borderRadius: 2,
                                                    padding: 2,
                                                    marginTop: 1,
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <Button
                                                    variant="text"
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 0,
                                                      right: 10,
                                                      zIndex: 2,
                                                      background: '#fff',
                                                      '&:hover': {
                                                        background: '#fff',
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.filter(
                                                        (objAndAns, i) =>
                                                          i !== faqIndex,
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'challengesForCustomer',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </Button>
                                                  <TextField
                                                    label={`Challenge ${faqIndex +
                                                      1}`}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.question}
                                                    sx={{ marginTop: 1 }}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              question:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'challengesForCustomer',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    size="small"
                                                    label={`Recommended Response`}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.answer}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              answer:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'challengesForCustomer',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                  />
                                                </Box>
                                              );
                                            },
                                          )}
                                        <Button
                                          onClick={() => {
                                            formikPlaybook.setFieldValue(
                                              'challengesForCustomer',
                                              [
                                                ...formikPlaybook.values
                                                  .challengesForCustomer,
                                                {
                                                  question: '',
                                                  answer: '',
                                                },
                                              ],
                                            );
                                          }}
                                          sx={{ marginTop: 1 }}
                                          variant="contained"
                                        >
                                          Add Challenge and Recommended Response
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Typical customer objections and
                                            their answers
                                          </span>
                                        </Typography>
                                        {formikPlaybook.values
                                          .objectionsAndAnswers &&
                                          Array.isArray(
                                            formikPlaybook.values
                                              .objectionsAndAnswers,
                                          ) &&
                                          formikPlaybook.values
                                            .objectionsAndAnswers.length > 0 &&
                                          formikPlaybook.values.objectionsAndAnswers?.map(
                                            (objAndAns, faqIndex) => {
                                              return (
                                                <Box
                                                  key={faqIndex}
                                                  sx={{
                                                    border: '1px solid #d3d3d3',
                                                    borderRadius: 2,
                                                    padding: 2,
                                                    marginTop: 1,
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <Button
                                                    variant="text"
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 0,
                                                      right: 10,
                                                      zIndex: 2,
                                                      background: '#fff',
                                                      '&:hover': {
                                                        background: '#fff',
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.filter(
                                                        (objAndAns, i) =>
                                                          i !== faqIndex,
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'objectionsAndAnswers',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </Button>
                                                  <TextField
                                                    label={`Objection ${faqIndex +
                                                      1}`}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.question}
                                                    sx={{ marginTop: 1 }}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              question:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'objectionsAndAnswers',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    size="small"
                                                    label={`Answer`}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.answer}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              answer:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'objectionsAndAnswers',
                                                        updatedObjAndAns,
                                                      );
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                                    // helperText={formik.touched.email && formik.errors.email}
                                                  />
                                                </Box>
                                              );
                                            },
                                          )}
                                        <Button
                                          onClick={() => {
                                            formikPlaybook.setFieldValue(
                                              'objectionsAndAnswers',
                                              [
                                                ...formikPlaybook.values
                                                  .objectionsAndAnswers,
                                                {
                                                  question: '',
                                                  answer: '',
                                                },
                                              ],
                                            );
                                          }}
                                          sx={{ marginTop: 1 }}
                                          variant="contained"
                                        >
                                          Add Objection and Answer
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Expected Call to Action
                                          </span>
                                          [e.g. Close the deal, Book a demo,
                                          schedule next meeting, etc]
                                        </Typography>
                                        <TextField
                                          placeholder="Enter expected call to action"
                                          variant="outlined"
                                          name={'expectedCallToAction'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .expectedCallToAction
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .expectedCallToAction &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .expectedCallToAction,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .expectedCallToAction &&
                                            formikPlaybook.errors
                                              .expectedCallToAction
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Competing Products and Companies
                                          </span>
                                          [comma separated product or company
                                          names]
                                        </Typography>
                                        <TextField
                                          placeholder="Competing Products and Companies"
                                          variant="outlined"
                                          name={'competingProducts'}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .competingProducts
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .competingProducts &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .competingProducts,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .competingProducts &&
                                            formikPlaybook.errors
                                              .competingProducts
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant={'subtitle2'}
                                          color={'text.secondary'}
                                          sx={{ fontWeight: 'bold' }}
                                        >
                                          <span style={{ fontWeight: 'bold' }}>
                                            Highlights of Awards, Ratings, and
                                            Customer Reviews
                                          </span>
                                        </Typography>
                                        <TextField
                                          placeholder="Highlights of Awards, Ratings, and Customer Reviews"
                                          variant="outlined"
                                          name={
                                            'playbookStructure.awardsRatingAndReviews'
                                          }
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={
                                            formikPlaybook.values
                                              .playbookStructure
                                              ?.awardsRatingAndReviews
                                          }
                                          onChange={formikPlaybook.handleChange}
                                          error={
                                            formikPlaybook.touched
                                              .playbookStructure
                                              ?.awardsRatingAndReviews &&
                                            Boolean(
                                              formikPlaybook.errors
                                                .playbookStructure
                                                ?.awardsRatingAndReviews,
                                            )
                                          }
                                          helperText={
                                            formikPlaybook.touched
                                              .playbookStructure
                                              ?.awardsRatingAndReviews &&
                                            formikPlaybook.errors
                                              .playbookStructure
                                              ?.awardsRatingAndReviews
                                          }
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Box display="flex"></Box>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          disabled={false}
                                          type="submit"
                                        >
                                          {'Update'}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </form>
                                </Box>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={(e) => {
                                    dispatch(
                                      updateOrganization(
                                        selectedOrganization,
                                        {
                                          countries: [country],
                                          methodologies:
                                            formikPlaybook.values.methodologies,
                                          organizationName,
                                          showPlaybookToManagersOnly,
                                          languagesForAnalysis: supportedAnalysisLanguages,
                                          languagesForRolePlay: supportedRoleplayLanguages,
                                          defaultLanguagesForAnalysis: defaultAnalysisLanguages,
                                          rolePlayVisibilityControl: roleplayVisibilityControl,
                                          transliterationInEnglish: transliterationInEnglish,
                                          contentScoringConfig: withContentScoringConfig
                                            ? {
                                                inbound: sameConfig
                                                  ? contentScoringConfig.outbound
                                                  : contentScoringConfig.inbound,
                                                outbound:
                                                  contentScoringConfig.outbound,
                                              }
                                            : null,
                                          defaultLanguagesForRolePlay: defaultRoleplayLanguages,
                                          salesCallType: defaultCallType,
                                          generalAutoAnalysis: organizations?.find(
                                            (org) =>
                                              org?.id === selectedOrganization,
                                          )?.integrations?.generalAutoAnalysis,
                                          crmAutoSync: crmAutoSync,
                                          app: customerType,
                                        },
                                        () => {
                                          dispatch(
                                            getAllOrganizations(
                                              [],
                                              true,
                                              'n_org',
                                              15,
                                              null,
                                              version === 'v1' ? 'all' : 'v2',
                                              () => {
                                                setSelectedOrganization(null);
                                              },
                                            ),
                                          );
                                          alert('Information Updated!');
                                        },
                                      ),
                                    );
                                  }}
                                >
                                  Update
                                </Button>
                              )}
                            </Box>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            alignItems={'start'}
                            sx={{
                              flex: 1,
                              padding: 2,
                              border: '1px solid #d3d3d3',
                              borderRadius: 2,
                              position: 'relative',
                              paddingTop: 4,
                            }}
                          >
                            <Typography
                              variant={'body1'}
                              sx={{
                                position: 'absolute',
                                top: -20,
                                left: 10,
                                background: '#fff',
                                padding: 1,
                              }}
                              color={'text.secondary'}
                            >
                              Invite Team Member
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  First Name
                                </Typography>
                                <TextField
                                  placeholder="First Name"
                                  variant="outlined"
                                  name={'firstName'}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Last Name
                                </Typography>
                                <TextField
                                  placeholder="Last Name"
                                  variant="outlined"
                                  name={'lastName'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Email
                                </Typography>
                                <TextField
                                  placeholder="Email"
                                  variant="outlined"
                                  name={'email'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  // sx={{ marginBottom: 1 }}
                                >
                                  Select Role
                                </Typography>
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    labelId="role-select-label"
                                    id="role-select"
                                    name="teamRole"
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) =>
                                      setTeamRole(e.target.value)
                                    }
                                    value={teamRole}
                                  >
                                    <MenuItem key={1} value="ORG_ADMIN">
                                      ADMIN
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {error && (
                                <Grid item xs={12}>
                                  <Alert severity="error">{error}</Alert>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  // sx={{ marginLeft: 'auto' }}
                                  onClick={() => {
                                    if (
                                      email &&
                                      email?.trim() &&
                                      validateEmail(email?.trim())
                                    ) {
                                      setError('');
                                      setSendingInvite(true);
                                      dispatch(
                                        sendTeamInvite(
                                          selectedOrganization,
                                          {
                                            designations: ['account_admin'],
                                            email,
                                            emailVerified: true,
                                            firstName,
                                            lastName,
                                            teamRole: [teamRole],
                                          },
                                          (error) => {
                                            if (error) {
                                              setSendingInvite(false);
                                              alert('Failed to send invite');
                                            } else {
                                              dispatch(
                                                getAllOrganizations(
                                                  [],
                                                  true,
                                                  'n_org',
                                                  15,
                                                  null,
                                                  version === 'v1'
                                                    ? 'all'
                                                    : 'v2',
                                                  () => {},
                                                ),
                                              );
                                              alert('Invite Sent Successfully');
                                              setSendingInvite(false);
                                            }
                                          },
                                        ),
                                      );
                                    } else {
                                      setError('Please enter a valid email.');
                                    }
                                  }}
                                >
                                  Send Invite
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginTop: 1,
                        minHeight: isXs
                          ? 'calc(100vh - 140px)'
                          : settingsExpanded
                          ? 'calc(100vh - 400px)'
                          : 'calc(100vh - 160px)',
                        maxHeight: isXs
                          ? 'calc(100vh - 140px)'
                          : settingsExpanded
                          ? 'calc(100vh - 400px)'
                          : 'calc(100vh - 160px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                        },
                      }}
                      // onScroll={handleListScroll}
                    >
                      <Table
                        sx={{ minWidth: 750 }}
                        stickyHeader
                        aria-label="simple table"
                      >
                        <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                          <TableRow
                            sx={{
                              '& .MuiTableCell-root': {
                                border: '1px solid #e1e1e1',
                              },
                            }}
                          >
                            <TableCell rowSpan={2} align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              >
                                Member
                              </Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Roleplays
                              </Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Sales Calls
                              </Typography>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Joined On
                              </Typography>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Actions
                              </Typography>
                            </TableCell>
                            {/* <TableCell align="center" rowSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                              align="center"
                            >
                              Actions
                            </Typography>
                          </TableCell> */}
                          </TableRow>
                          <TableRow
                            sx={{
                              '& .MuiTableCell-root': {
                                border: '1px solid #e1e1e1',
                              },
                            }}
                          >
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Calls Analyzed
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Speaking Time(Minutes)
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Calls Analyzed
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                Speaking Time(Minutes)
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {organizations
                            ?.find((org) => org?.id === selectedOrganization)
                            ?.customFields?.users.map((member, i) => (
                              <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    // gap={1}
                                  >
                                    <Typography
                                      fontWeight={'bold'}
                                      color={'text.secondary'}
                                    >
                                      {member?.email}
                                    </Typography>
                                    <Typography variant="caption">
                                      {member?.fullName || ''}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        dispatch(
                                          refreshUsage(member?.id, () => {
                                            alert('Information Updated');
                                          }),
                                        );
                                      }}
                                    >
                                      Refresh Usage
                                    </Button>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {member?.custom?.counts
                                    ?.practiceCallAnalysed > 0 ? (
                                    // <Button
                                    //   variant="text"
                                    //   color="primary"
                                    //   onClick={() => {
                                    //     setViewType('practiceCalls');
                                    //     setCurrentMemberId(member?.id);
                                    //   }}
                                    // >
                                    <Typography
                                      variant="subtitle2"
                                      // fontWeight={'bold'}
                                      sx={
                                        {
                                          // textDecoration: 'underline',
                                        }
                                      }
                                    >
                                      {member?.custom?.counts
                                        ?.practiceCallAnalysed || 0}
                                    </Typography>
                                  ) : (
                                    // </Button>
                                    <Typography
                                      // color={'text.secondary'}
                                      variant={'subtitle2'}
                                    >
                                      {member?.custom?.counts
                                        ?.practiceCallAnalysed || 0}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {Math.floor(
                                      (member?.custom?.counts
                                        ?.practiceCallSpeakerTimeSumInSec ||
                                        0) / 60,
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {member?.custom?.counts?.salesCallAnalysed >
                                  0 ? (
                                    // <Button
                                    //   variant="text"
                                    //   color="primary"
                                    //   onClick={() => {
                                    //     setViewType('salesCalls');
                                    //     setCurrentMemberId(member?.id);
                                    //   }}
                                    // >
                                    <Typography
                                      variant="subtitle2"
                                      // fontWeight={'bold'}
                                      sx={
                                        {
                                          // textDecoration: 'underline',
                                        }
                                      }
                                    >
                                      {member?.custom?.counts
                                        ?.salesCallAnalysed || 0}
                                    </Typography>
                                  ) : (
                                    // </Button>
                                    <Typography
                                      // color={'text.secondary'}
                                      variant={'subtitle2'}
                                    >
                                      {member?.custom?.counts
                                        ?.salesCallAnalysed || 0}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {Math.floor(
                                      (member?.custom?.counts
                                        ?.salesCallSpeakerTimeSumInSec || 0) /
                                        60,
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {moment(member?.createdOn).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    <FormControl fullWidth size="small">
                                      <Select
                                        disabled={
                                          // member?.email === userInfo?.email ||
                                          updatingRole
                                        }
                                        size="small"
                                        labelId="role-select-label"
                                        id="role-select"
                                        name="teamRole"
                                        MenuProps={{ disableScrollLock: true }}
                                        onChange={(e) => {
                                          setUpdatingRole(true);
                                          dispatch(
                                            updateTeamMemberRole(
                                              selectedOrganization,
                                              [
                                                {
                                                  roles: [e.target.value],
                                                  userId: member?.id,
                                                },
                                              ],
                                              () => {
                                                setUpdatingRole(false);
                                                dispatch(
                                                  getAllOrganizations(
                                                    [],
                                                    true,
                                                    'n_org',
                                                    15,
                                                    null,
                                                    version === 'v1'
                                                      ? 'all'
                                                      : 'v2',
                                                    () => {},
                                                  ),
                                                );
                                                alert('Information Updated!');
                                              },
                                            ),
                                          );
                                        }}
                                        value={
                                          member?.orgRoles?.[0] ||
                                          'ORG_STANDARD'
                                        }
                                      >
                                        <MenuItem key={1} value="ORG_ADMIN">
                                          ADMIN
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {/* <FormControl fullWidth size="small">
                                    <Select
                                      size="small"
                                      labelId="plan-select-label"
                                      id="plan-select"
                                      name="selectedPlan"
                                      MenuProps={{ disableScrollLock: true }}
                                      onChange={(e) => {
                                        dispatch(
                                          updatePlanForOrgUser(
                                            selectedOrganization,
                                            e.target.value,
                                            member?.id,
                                            () => {
                                              dispatch(
                                                getAllOrganizations(
                                                  15,
                                                  null,
                                                  () => {},
                                                ),
                                              );
                                              // setSelectedPlan(e.target.value);
                                              alert('Information updated');
                                            },
                                          ),
                                        );
                                      }}
                                      value={member?.plan?.id}
                                    >
                                      {plans?.map((plan) => (
                                        <MenuItem key={0} value={plan?.id}>
                                          {capitalizeText(plan?.title)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl> */}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : currentTab === 'framework' ? (
                  <Box></Box>
                ) : null
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showUpdateTrialDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Update trial</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              minWidth: 300,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                Type
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="type-select-label"
                    id="type-select"
                    name="type"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    value={type}
                  >
                    <MenuItem value={'date'}>Valid Till</MenuItem>
                    <MenuItem value={'days'}>Valid For</MenuItem>
                    <MenuItem value={'end'}>End Trial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {type !== 'end' ? (
                <>
                  <Grid item xs={4}>
                    {type === 'date' ? 'Date' : 'Days'}
                  </Grid>
                  <Grid item xs={8}>
                    {type === 'date' ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={date ? dayjs(new Date(date)) : null}
                          label="Date"
                          format="MM-DD-YYYY"
                          onChange={(date) => {
                            let date_ = new Date(date); // some mock date
                            // console.log(date);
                            var milliseconds = date_.getTime();
                            setDate(milliseconds);
                          }}
                        />
                      </LocalizationProvider>
                    ) : null}
                    {type === 'days' ? (
                      <TextField
                        label="Days"
                        variant="outlined"
                        name={'days'}
                        size="small"
                        // fullWidth
                        value={days}
                        onChange={(e) => setDays(e.target.value)}
                      />
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowUpdateTrialDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Dismiss
            </Button>
            <Button
              onClick={(e) => {
                setShowUpdateTrialDialog(false);
                dispatch(
                  updatePlanTrialEndsOn(
                    selectedOrganization,
                    {
                      trailEndsOn:
                        type === 'end'
                          ? new Date().getTime() - 172800000
                          : type === 'date'
                          ? date
                          : new Date().getTime() +
                            Number(days) * 24 * 60 * 60 * 1000,
                    },
                    (err) => {
                      if (err) {
                        alert('Failed to update trial! Please try again later');
                      } else {
                        dispatch(
                          getAllOrganizations(
                            [],
                            true,
                            'n_org',
                            15,
                            null,
                            version === 'v1' ? 'all' : 'v2',
                            () => {},
                          ),
                        );
                        alert('Information Updated!');
                      }
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showEditContentScoringConfig}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '95vh',
            maxWidth: '70vw',
            minWidth: '70vw',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
            // paddingTop: 3,
          }}
        >
          <Typography
            variant="body1"
            padding={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            Call Scoring Configurations
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: '16px',
              minHeight: 'calc(95vh - 140px)',
              maxHeight: 'calc(95vh - 140px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
            }}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Configure with
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={configType}
                onChange={(e) => {
                  if (e.target.value === 'form') {
                    try {
                      const outboundObj = JSON.parse(
                        contentScoreConfigText?.outbound || '{}',
                      );
                      const inboundObj = JSON.parse(
                        contentScoreConfigText?.inbound || '{}',
                      );
                      setContentScoringConfig((prev) => {
                        return {
                          ...prev,
                          outbound: outboundObj,
                          inbound: inboundObj,
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to parse json text. please validate the json.',
                      );
                    }
                  } else {
                    try {
                      setContentScoreConfigText((prev) => {
                        return {
                          ...prev,
                          outbound: JSON.stringify(
                            contentScoringConfig?.outbound || '{}',
                            null,
                            2,
                          ),
                          inbound: JSON.stringify(
                            contentScoringConfig?.inbound || '{}',
                            null,
                            2,
                          ),
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to convert json to text. please try again.',
                      );
                    }
                  }
                }}
              >
                <FormControlLabel
                  value={'form'}
                  control={<Radio size="small" />}
                  label={'Form'}
                />
                <FormControlLabel
                  value={'json'}
                  control={<Radio size="small" />}
                  label={'json'}
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Call Type
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
              >
                <FormControlLabel
                  value={'outbound'}
                  control={<Radio size="small" />}
                  label={'Outbound'}
                />
                <FormControlLabel
                  value={'inbound'}
                  control={<Radio size="small" />}
                  label={'Inbound'}
                />
              </RadioGroup>
            </FormControl>
            {callType === 'inbound' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={sameConfig}
                    name="sameConfig"
                  />
                }
                label={
                  <Typography variant="subtitle2">Same as outbound</Typography>
                }
                onChange={(e) => {
                  setSameConfig(e.target.checked);
                }}
              />
            ) : null}
            {callType === 'outbound' || (callType === 'inbound' && !sameConfig)
              ? Object.keys(contentScoringConfig?.[callType] || {})?.map(
                  (configKey) => {
                    return configKey === 'criteriaList' ? (
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          position: 'relative',
                          // padding: 2,
                        }}
                        key={configKey}
                      >
                        {configType === 'form' ? (
                          <>
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              sx={{
                                position: 'absolute',
                                top: -12,
                                left: 5,
                                background: '#fff',
                                padding: '0 5px',
                                zIndex: 9999,
                              }}
                            >
                              Categories
                            </Typography>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                              // sx={{ background: '#f1f1f1' }}
                              // padding={2}
                            >
                              {contentScoringConfig[callType][configKey] ? (
                                contentScoringConfig[callType][configKey]
                                  ?.length > 0 ? (
                                  <Box sx={{}}>
                                    <Box sx={{ background: '#d3d3d3', p: 2 }}>
                                      {contentScoringConfig[callType][
                                        configKey
                                      ]?.map((criteriaKey, index) => (
                                        <Box
                                          key={
                                            Object.keys(criteriaKey || {})?.[0]
                                          }
                                          display={'flex'}
                                          flexDirection={'column'}
                                          // gap={2}
                                        >
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx={
                                              {
                                                // background: '#f1f1f1',
                                                // padding: 1,
                                              }
                                            }
                                          >
                                            <Typography variant="body1">
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {index + 1}.
                                              </span>{' '}
                                              {
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              }
                                            </Typography>
                                            <IconButton
                                              onClick={() => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]: prev[
                                                          callType
                                                        ][configKey]?.filter(
                                                          (criteriaMap) =>
                                                            Object.keys(
                                                              criteriaMap || {},
                                                            )?.[0] !==
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0],
                                                        ),
                                                        categoryWeight: Object.keys(
                                                          prev[callType][
                                                            'categoryWeight'
                                                          ] || {},
                                                        )
                                                          ?.filter(
                                                            (key) =>
                                                              key !==
                                                              Object.keys(
                                                                criteriaKey ||
                                                                  {},
                                                              )?.[0],
                                                          )
                                                          ?.reduce(
                                                            (acc, curr) => {
                                                              acc[curr] =
                                                                prev[callType][
                                                                  'categoryWeight'
                                                                ][curr];
                                                              return acc;
                                                            },
                                                            {},
                                                          ),
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                            >
                                              <Delete sx={{ fontSize: 18 }} />
                                            </IconButton>
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            sx={{
                                              padding: 1,
                                            }}
                                          >
                                            <Typography flex={1}>
                                              Category Weight
                                            </Typography>
                                            <TextField
                                              placeholder={'Category Weight'}
                                              variant="outlined"
                                              name={'factorKey'}
                                              size="small"
                                              sx={{
                                                flex: 2,
                                              }}
                                              value={
                                                contentScoringConfig[callType][
                                                  'categoryWeight'
                                                ][
                                                  Object.keys(
                                                    criteriaKey || {},
                                                  )?.[0]
                                                ]
                                              }
                                              onChange={(e) => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]:
                                                          prev[callType][
                                                            configKey
                                                          ],
                                                        categoryWeight: {
                                                          ...prev[callType][
                                                            'categoryWeight'
                                                          ],
                                                          [Object.keys(
                                                            criteriaKey || {},
                                                          )?.[0]]: e.target
                                                            .value,
                                                        },
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                              type={'number'}
                                            />
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            gap={2}
                                          >
                                            {contentScoringConfig[callType][
                                              configKey
                                            ][index][
                                              Object.keys(
                                                criteriaKey || {},
                                              )?.[0]
                                            ] ? (
                                              contentScoringConfig[callType][
                                                configKey
                                              ][index][
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              ]?.length > 0 ? (
                                                <>
                                                  {contentScoringConfig[
                                                    callType
                                                  ][configKey][index][
                                                    Object.keys(
                                                      criteriaKey || {},
                                                    )?.[0]
                                                  ]?.map(
                                                    (
                                                      criteriaKeyFactor,
                                                      factorIndex,
                                                    ) => {
                                                      return (
                                                        <Box
                                                          key={`${
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          }${factorIndex}`}
                                                          display={'flex'}
                                                          flexDirection={
                                                            'column'
                                                          }
                                                          backgroundColor={
                                                            '#fff'
                                                          }
                                                        >
                                                          <Box
                                                            display={'flex'}
                                                            gap={1}
                                                            alignItems={
                                                              'center'
                                                            }
                                                            justifyContent={
                                                              'space-between'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                              border:
                                                                '1px solid #d3d3d3',
                                                            }}
                                                          >
                                                            <Typography variant="body1">
                                                              {
                                                                Object.keys(
                                                                  criteriaKeyFactor ||
                                                                    {},
                                                                )?.[0]
                                                              }
                                                            </Typography>
                                                            <IconButton
                                                              onClick={() => {
                                                                setContentScoringConfig(
                                                                  (prev) => {
                                                                    return {
                                                                      ...prev,
                                                                      [callType]: {
                                                                        ...prev[
                                                                          callType
                                                                        ],
                                                                        [configKey]: prev[
                                                                          callType
                                                                        ][
                                                                          configKey
                                                                        ]?.map(
                                                                          (
                                                                            ck,
                                                                            cki,
                                                                          ) =>
                                                                            cki ===
                                                                            index
                                                                              ? {
                                                                                  [Object.keys(
                                                                                    ck ||
                                                                                      {},
                                                                                  )?.[0]]: prev[
                                                                                    callType
                                                                                  ][
                                                                                    configKey
                                                                                  ][
                                                                                    index
                                                                                  ][
                                                                                    Object.keys(
                                                                                      criteriaKey ||
                                                                                        {},
                                                                                    )?.[0]
                                                                                  ]?.filter(
                                                                                    (
                                                                                      key,
                                                                                    ) =>
                                                                                      Object.keys(
                                                                                        key ||
                                                                                          {},
                                                                                      )?.[0] !==
                                                                                      Object.keys(
                                                                                        criteriaKeyFactor ||
                                                                                          {},
                                                                                      )?.[0],
                                                                                  ),
                                                                                }
                                                                              : ck,
                                                                        ),
                                                                      },
                                                                    };
                                                                  },
                                                                );
                                                              }}
                                                            >
                                                              <Delete
                                                                sx={{
                                                                  fontSize: 18,
                                                                }}
                                                              />
                                                            </IconButton>
                                                          </Box>
                                                          {contentScoringConfig[
                                                            callType
                                                          ][configKey][index][
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0]
                                                          ][factorIndex][
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          ]
                                                            ? Object.keys(
                                                                contentScoringConfig[
                                                                  callType
                                                                ][configKey][
                                                                  index
                                                                ][
                                                                  Object.keys(
                                                                    criteriaKey ||
                                                                      {},
                                                                  )?.[0]
                                                                ][factorIndex][
                                                                  Object.keys(
                                                                    criteriaKeyFactor ||
                                                                      {},
                                                                  )?.[0]
                                                                ] || {},
                                                              )?.map(
                                                                (factorKey) => {
                                                                  return factorKey ===
                                                                    'description' ||
                                                                    factorKey ===
                                                                      'weight' ||
                                                                    factorKey ===
                                                                      'question' ? (
                                                                    <Box
                                                                      display={
                                                                        'flex'
                                                                      }
                                                                      flexDirection={
                                                                        'column'
                                                                      }
                                                                    >
                                                                      <Box
                                                                        display={
                                                                          'flex'
                                                                        }
                                                                        gap={1}
                                                                        // alignItems={'center'}
                                                                        sx={{
                                                                          padding: 1,
                                                                        }}
                                                                      >
                                                                        <Typography
                                                                          flex={
                                                                            1
                                                                          }
                                                                        >
                                                                          {
                                                                            factorKey
                                                                          }
                                                                        </Typography>
                                                                        <TextField
                                                                          placeholder={
                                                                            factorKey
                                                                          }
                                                                          variant="outlined"
                                                                          name={
                                                                            'factorKey'
                                                                          }
                                                                          size="small"
                                                                          multiline={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          rows={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 3
                                                                              : 1
                                                                          }
                                                                          sx={{
                                                                            flex: 2,
                                                                          }}
                                                                          value={
                                                                            contentScoringConfig[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorIndex
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKeyFactor ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorKey
                                                                            ]
                                                                          }
                                                                          onChange={(
                                                                            e,
                                                                          ) => {
                                                                            setContentScoringConfig(
                                                                              (
                                                                                prev,
                                                                              ) => {
                                                                                return {
                                                                                  ...prev,
                                                                                  [callType]: {
                                                                                    ...prev[
                                                                                      callType
                                                                                    ],
                                                                                    [configKey]: prev[
                                                                                      callType
                                                                                    ][
                                                                                      configKey
                                                                                    ]?.map(
                                                                                      (
                                                                                        ck,
                                                                                        cki,
                                                                                      ) =>
                                                                                        cki ===
                                                                                        index
                                                                                          ? {
                                                                                              [Object.keys(
                                                                                                ck ||
                                                                                                  {},
                                                                                              )?.[0]]: prev[
                                                                                                callType
                                                                                              ][
                                                                                                configKey
                                                                                              ][
                                                                                                index
                                                                                              ][
                                                                                                Object.keys(
                                                                                                  criteriaKey ||
                                                                                                    {},
                                                                                                )?.[0]
                                                                                              ]?.map(
                                                                                                (
                                                                                                  key,
                                                                                                  keyIndex,
                                                                                                ) =>
                                                                                                  keyIndex ===
                                                                                                  factorIndex
                                                                                                    ? {
                                                                                                        ...prev[
                                                                                                          callType
                                                                                                        ][
                                                                                                          configKey
                                                                                                        ][
                                                                                                          index
                                                                                                        ][
                                                                                                          Object.keys(
                                                                                                            criteriaKey ||
                                                                                                              {},
                                                                                                          )?.[0]
                                                                                                        ][
                                                                                                          factorIndex
                                                                                                        ],
                                                                                                        [Object.keys(
                                                                                                          criteriaKeyFactor ||
                                                                                                            {},
                                                                                                        )?.[0]]: {
                                                                                                          ...prev[
                                                                                                            callType
                                                                                                          ][
                                                                                                            configKey
                                                                                                          ][
                                                                                                            index
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKey ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ][
                                                                                                            factorIndex
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKeyFactor ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ],
                                                                                                          [factorKey]:
                                                                                                            e
                                                                                                              .target
                                                                                                              .value,
                                                                                                        },
                                                                                                      }
                                                                                                    : key,
                                                                                              ),
                                                                                            }
                                                                                          : ck,
                                                                                    ),
                                                                                  },
                                                                                };
                                                                              },
                                                                            );
                                                                          }}
                                                                          type={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 'text'
                                                                              : 'number'
                                                                          }
                                                                        />
                                                                      </Box>
                                                                    </Box>
                                                                  ) : null;
                                                                },
                                                              )
                                                            : null}
                                                        </Box>
                                                      );
                                                    },
                                                  )}
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                  >
                                                    <TextField
                                                      placeholder="Metric Name"
                                                      variant="outlined"
                                                      name={
                                                        'criteriaFactorName'
                                                      }
                                                      size="small"
                                                      sx={{
                                                        width: '100%',
                                                      }}
                                                      value={criteriaFactorName}
                                                      onChange={(e) =>
                                                        setCriteriaFactorName(
                                                          e.target.value,
                                                        )
                                                      }
                                                      type="text"
                                                    />
                                                    <Button
                                                      variant="contained"
                                                      size="small"
                                                      startIcon={<Add />}
                                                      disabled={
                                                        !criteriaFactorName?.trim()
                                                      }
                                                      onClick={() => {
                                                        setContentScoringConfig(
                                                          (prev) => {
                                                            return {
                                                              ...prev,
                                                              [callType]: {
                                                                ...prev[
                                                                  callType
                                                                ],
                                                                [configKey]: prev[
                                                                  callType
                                                                ][
                                                                  configKey
                                                                ]?.map(
                                                                  (ck, cki) =>
                                                                    cki ===
                                                                    index
                                                                      ? {
                                                                          [Object.keys(
                                                                            criteriaKey,
                                                                          )[0]]: [
                                                                            ...prev[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey,
                                                                              )[0]
                                                                            ],
                                                                            {
                                                                              [criteriaFactorName]: {
                                                                                description:
                                                                                  '',
                                                                                weight: 1,
                                                                                question:
                                                                                  '',
                                                                              },
                                                                            },
                                                                          ],
                                                                        }
                                                                      : ck,
                                                                ),
                                                              },
                                                            };
                                                          },
                                                        );
                                                        setCriteriaFactorName(
                                                          '',
                                                        );
                                                      }}
                                                      fullWidth
                                                    >
                                                      Add new metric
                                                    </Button>
                                                  </Box>
                                                </>
                                              ) : (
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  gap={1}
                                                >
                                                  <TextField
                                                    placeholder="Metric Name"
                                                    variant="outlined"
                                                    name={'criteriaFactorName'}
                                                    size="small"
                                                    sx={{
                                                      width: '100%',
                                                    }}
                                                    value={criteriaFactorName}
                                                    onChange={(e) =>
                                                      setCriteriaFactorName(
                                                        e.target.value,
                                                      )
                                                    }
                                                    type="text"
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<Add />}
                                                    disabled={
                                                      !criteriaFactorName?.trim()
                                                    }
                                                    onClick={() => {
                                                      setContentScoringConfig(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [callType]: {
                                                              ...prev[callType],
                                                              [configKey]: prev[
                                                                callType
                                                              ][configKey]?.map(
                                                                (ck, cki) =>
                                                                  cki === index
                                                                    ? {
                                                                        [Object.keys(
                                                                          criteriaKey,
                                                                        )[0]]: [
                                                                          {
                                                                            [criteriaFactorName]: {
                                                                              description:
                                                                                '',
                                                                              weight: 1,
                                                                              question:
                                                                                '',
                                                                            },
                                                                          },
                                                                        ],
                                                                      }
                                                                    : ck,
                                                              ),
                                                            },
                                                          };
                                                        },
                                                      );
                                                      setCriteriaFactorName('');
                                                    }}
                                                    fullWidth
                                                  >
                                                    Add new metric
                                                  </Button>
                                                </Box>
                                              )
                                            ) : null}
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      p={2}
                                    >
                                      <TextField
                                        placeholder="Category Name"
                                        variant="outlined"
                                        name={'criteriaName'}
                                        size="small"
                                        sx={{
                                          width: '100%',
                                        }}
                                        value={criteriaName}
                                        onChange={(e) =>
                                          setCriteriaName(e.target.value)
                                        }
                                        type="text"
                                      />
                                      <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Add />}
                                        disabled={!criteriaName?.trim()}
                                        onClick={() => {
                                          setContentScoringConfig((prev) => {
                                            return {
                                              ...prev,
                                              [callType]: {
                                                ...prev[callType],
                                                [configKey]: [
                                                  ...prev[callType][configKey],
                                                  {
                                                    [criteriaName]: [],
                                                  },
                                                ],
                                                categoryWeight: {
                                                  ...prev[callType][
                                                    'categoryWeight'
                                                  ],
                                                  [criteriaName]: 1,
                                                },
                                              },
                                            };
                                          });
                                          setCriteriaName('');
                                        }}
                                        fullWidth
                                      >
                                        Add new category
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    p={2}
                                  >
                                    <TextField
                                      placeholder="Category Name"
                                      variant="outlined"
                                      name={'criteriaName'}
                                      size="small"
                                      sx={{
                                        width: '100%',
                                      }}
                                      value={criteriaName}
                                      onChange={(e) =>
                                        setCriteriaName(e.target.value)
                                      }
                                      type="text"
                                    />
                                    <Button
                                      variant="contained"
                                      size="small"
                                      startIcon={<Add />}
                                      disabled={!criteriaName?.trim()}
                                      onClick={() => {
                                        setContentScoringConfig((prev) => {
                                          return {
                                            ...prev,
                                            [callType]: {
                                              ...prev[callType],
                                              [configKey]: [
                                                ...prev[callType][configKey],
                                                {
                                                  [criteriaName]: [],
                                                },
                                              ],
                                              categoryWeight: {
                                                ...prev[callType][
                                                  'categoryWeight'
                                                ],
                                                [criteriaName]: 1,
                                              },
                                            },
                                          };
                                        });
                                        setCriteriaName('');
                                      }}
                                      fullWidth
                                    >
                                      Add new category
                                    </Button>
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </>
                        ) : (
                          <TextField
                            placeholder="JSON CONFIG"
                            variant="outlined"
                            name={'contentScoreConfig'}
                            fullWidth
                            size="small"
                            multiline
                            rows={17}
                            value={contentScoreConfigText[callType]}
                            onChange={(e) =>
                              setContentScoreConfigText((prev) => {
                                return {
                                  ...prev,
                                  [callType]: e.target.value,
                                };
                              })
                            }
                            type={'text'}
                          />
                        )}
                      </Box>
                    ) : null;
                  },
                )
              : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: 1,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                setShowEditContentScoringConfig(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setShowEditContentScoringConfig(false);
                if (configType === 'form') {
                  try {
                    setContentScoreConfigText((prev) => {
                      return {
                        ...prev,
                        outbound: JSON.stringify(
                          contentScoringConfig?.outbound || '{}',
                          null,
                          2,
                        ),
                        inbound: JSON.stringify(
                          contentScoringConfig?.inbound || '{}',
                          null,
                          2,
                        ),
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert('Failed to convert json to text. please try again.');
                  }
                } else {
                  try {
                    const outboundObj = JSON.parse(
                      contentScoreConfigText?.outbound || '{}',
                    );
                    const inboundObj = JSON.parse(
                      contentScoreConfigText?.inbound || '{}',
                    );
                    setContentScoringConfig((prev) => {
                      return {
                        ...prev,
                        outbound: outboundObj,
                        inbound: inboundObj,
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert(
                      'Failed to parse json text. please validate the json.',
                    );
                  }
                }
              }}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAssignedBots)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowAssignedBots('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Assigned Bots</Typography>
          </Box>
          <Box
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2,
              }}
            >
              {assignedBots?.map((bot) => (
                <Box
                  // flex={1}
                  key={bot?.id}
                  sx={{
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                  }}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                  >
                    {bot?.title}{' '}
                    <Chip
                      size="small"
                      label={
                        bot?.visibility === 'published_for_all'
                          ? 'Public'
                          : 'Private'
                      }
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                  >
                    {bot?.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                  >
                    {bot?.botAudio}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddCreditsDialog)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowAddCreditsDialog('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Add Credits</Typography>
          </Box>
          <Box
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2,
              }}
            >
              <TextField
                placeholder="Credits"
                variant="outlined"
                name={'Credits'}
                fullWidth
                size="small"
                type="number"
                value={credits}
                onChange={(e) => {
                  setCredits(e.target.value);
                }}
              />
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <TextField
                  placeholder="CreditExpiryTimeValue"
                  variant="outlined"
                  name={'CreditExpiryTimeValue'}
                  fullWidth
                  size="small"
                  type="number"
                  value={creditExpiryTimeValue}
                  onChange={(e) => {
                    setCreditExpiryTimeValue(e.target.value);
                  }}
                />
                <FormControl sx={{ minWidth: 100 }}>
                  <InputLabel id="unit-select-label">Unit</InputLabel>
                  <Select
                    size="small"
                    labelId="unit-select-label"
                    id="unit-select"
                    name="creditExpiryTimeUnit"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setCreditsExpiryTimeUnit(e.target.value)}
                    value={creditExpiryTimeUnit}
                    input={<OutlinedInput label="Select Country" />}
                  >
                    <MenuItem key={0} value="milli_sec">
                      Millisecond
                    </MenuItem>
                    <MenuItem key={0} value="sec">
                      Second
                    </MenuItem>
                    <MenuItem key={0} value="min">
                      Minute
                    </MenuItem>
                    <MenuItem key={1} value="hour">
                      Hour
                    </MenuItem>
                    <MenuItem key={0} value="month">
                      Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <FormControl sx={{ minWidth: 100 }}>
                <InputLabel id="admin-select-label">Select Admin</InputLabel>
                <Select
                  size="small"
                  labelId="admin-select-label"
                  id="admin-select"
                  name="creditAdmin"
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => setCreditAdmin(e.target.value)}
                  value={creditAdmin}
                  input={<OutlinedInput label="Select Admin" />}
                >
                  <MenuItem key={0} value="deepak">
                    Deepak
                  </MenuItem>
                  <MenuItem key={0} value="gaurav">
                    Gaurav
                  </MenuItem>
                  <MenuItem key={0} value="shubham">
                    Shubham
                  </MenuItem>
                  <MenuItem key={1} value="vijay">
                    Vijay
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                placeholder="Credit Description"
                variant="outlined"
                name={'creditDescription'}
                fullWidth
                multiline
                rows={4}
                size="small"
                type="number"
                value={purpose}
                onChange={(e) => {
                  setPurpose(e.target.value);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(
                    addCreditsForOrg(
                      {
                        orgId: showAddCreditsDialog,
                        credits,
                        creditExpiryTimeUnit,
                        creditExpiryTimeValue,
                        adminName: creditAdmin,
                        purpose,
                      },
                      () => {
                        setShowAddCreditsDialog('');
                      },
                    ),
                  );
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmResetSubscriptionDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to reset the subscritpion?
              <br />
              This will reset the subscription to the sampe plan with
              subscription starting from now.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmResetSubscriptionDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                updatePlanForOrganization(
                  selectedOrganization,
                  selectedPlan,
                  () => {
                    alert('Information updated');
                    setShowConfirmResetSubscriptionDialog(null);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this conversation?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteConversation(showConfirmDeleteDialog, () => {
                  setConversations((prev) =>
                    prev?.filter((cs) => cs.id !== showConfirmDeleteDialog),
                  );
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fixed>
  );
};

export default OrganizationsV2;
