import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Tabs,
  Tab,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Article,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  CopyAll,
  Delete,
  Edit,
  ExpandMore,
  Group,
  Link,
  List,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Search,
  Send,
  Settings,
  StopCircle,
  Upload,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import updateOrganization from 'redux/actions/Common/updateOrganization';
import { capitalizeText, validateEmail } from 'utils';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getPlans from 'redux/actions/Common/getPlans';
import updatePlanForOrganization from 'redux/actions/Common/updatePlanForOrganization';
import refreshUsage from 'redux/actions/Common/refreshUsage';
import refreshUsageForOrg from 'redux/actions/Common/refreshUsageForOrg';
import updatePlanForOrgUser from 'redux/actions/Common/updatePlanForOrgUser';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import updatePlanTrialEndsOn from 'redux/actions/Common/updatePlanTrialEndsOn';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';
import deepEqual from 'deep-equal';
import getBotsByUserId from 'redux/actions/Common/getBotsByUserId';
import addCreditsForOrg from 'redux/actions/Common/addCreditsForOrg';
import { v4 as uuidv4 } from 'uuid';
import getAllDiscoveryTree from 'redux/actions/Common/getAllDiscoveryTree';
import createDiscoveryTree from 'redux/actions/Common/createDiscoveryTree';
import QuestionFrameworkTree from 'components/@2024/QuestionFrameworkTree';
import createDealForV2Customer from 'redux/actions/Common/createDealForV2Customer';
import getDealsForV2Customer from 'redux/actions/Common/getDealsForV2Customer';
import generateValueProp from 'redux/actions/Common/generateValueProp';
import exportDiscoveryTree from 'redux/actions/Common/exportDiscoveryTree';
import deleteDealForV2Customer from 'redux/actions/Common/deleteDealForV2Customer';
import addArtifactForDeal from 'redux/actions/Common/addArtifactForDeal';
import getArtifactsForDeal from 'redux/actions/Common/getArtifactsForDeal';
import getAllFineTuningJobs from 'redux/actions/Common/getAllFineTuningJobs';
import udpateArtifactForDeal from 'redux/actions/Common/updateArtifactForDeal';
import deleteArtifactForDeal from 'redux/actions/Common/deleteArtifactForDeal';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sellingStyles = [
  {
    id: 1,
    value: 'persuasive_or_traditional_selling',
    style: 'Persuasive (Traditional) Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 2,
    value: 'consultative_or_solution_selling',
    style: 'Consultative or Solution Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 3,
    value: 'relationship_or_trust_based_selling',
    style: 'Relationship (Trust-Based) Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 4,
    value: 'value_based_selling',
    style: 'Value-Based Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 5,
    value: 'insight_or_challenger_selling',
    style: 'Insider or Challenger Selling(e.g., Challenger Sales Method)',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 6,
    value: 'spin_or_question_based_selling',
    style: 'SPIN or Question-Based Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 7,
    value: 'collaborative_or_partnering_selling',
    style: 'Collaborative or Partnering Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 8,
    value: 'inbound_educational_selling',
    style: 'Inbound / Educational Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 9,
    value: 'social_selling',
    style: 'Social Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
];

const OrganizationsQuestionFramework = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const { version } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    tree,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.discoveryTree);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [currentTab, setCurrentTab] = useState('framework');
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [creatingDiscoverytree, setCreatingDiscoverytree] = useState(false);
  const [generatingValueProp, setGeneratingValueProp] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [valueProp, setValueProp] = useState('');
  const [valuePropJson, setValuePropJson] = useState('');
  const [valuePropHTML, setValuePropHTML] = useState('');
  const [valuePropJsonError, setValuePropJsonError] = useState(false);
  const [metaData, setMetaData] = useState('');
  const [sellingStyle, setSellingStyle] = useState('');

  const [promptOrder, setPromptOrder] = useState(null);
  const [selectedTree, setSelectedTree] = useState(null);
  const [selectedMetadata, setSelectedMetadata] = useState(null);
  const [promptType, setPromptType] = useState('after_state');
  const [currentOrgMenu, setCurrentOrgMenu] = useState('setup');

  const [showAddDeal, setShowAddDeal] = useState(null);
  const [dealTitle, setDealTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');

  const [fetchingDeals, setFetchingDeals] = useState(false);
  const [deals, setDeals] = useState([]);
  const [exportingTree, setExportingTree] = useState(false);
  const [showConfirmDeleteDeal, setShowConfirmDeleteDeal] = useState(false);

  const [addingArtifactForDeal, setAddingArtifactForDeal] = useState(false);
  const [showUploadArtifact, setShowUploadArtifact] = useState(false);
  const [artifactType, setArtifactType] = useState('transcript');
  const [buyerParticipants, setBuyerParticipants] = useState('');
  const [sellerParticipants, setSellerParticipants] = useState('');
  const [artifactContent, setArtifactContent] = useState('');
  const [artifactTime, setArtifactTime] = useState(new Date().getTime());

  const [viewType, setViewType] = useState('deals');
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [fetchingArtifacts, setFetchingArtifacts] = useState(false);
  const [artifacts, setArtifacts] = useState([]);

  const [showArtifactContent, setShowArtifactContent] = useState(null);
  const [currentDealTab, setCurrentDealTab] = useState('details');
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [currentArtifactTab, setCurrentArtifactTab] = useState(
    'artifactContent',
  );
  const [modelName, setModelName] = useState('gpt-4o-mini-2024-07-18');
  const [models, setModels] = useState([]);
  const [artifactTitle, setArtifactTitle] = useState('');

  const [artifactEditable, setArtifactEditable] = useState(null);
  const [updatingArtifactTitle, setUpdatingArtifactTitle] = useState(false);

  const [artifactPrompts, setArtifactPrompts] = useState([]);
  const [selectedArtifactPrompt, setSelectedArtifactPrompt] = useState('');

  const [showConfirmDeleteArtifact, setShowConfirmDeleteArtifact] = useState(
    false,
  );

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        tree &&
        tree.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllDiscoveryTree(10, lastTime, () => {}));
    }
  };

  useEffect(() => {
    if (showAddOrganization) {
      dispatch(
        getAllFineTuningJobs(null, 'succeeded', (data) => {
          setModels(data);
          console.log(data);
        }),
      );
    }
  }, [showAddOrganization]);

  useEffect(() => {
    if (selectedOrg && tree && tree.length > 0) {
      setSelectedTree(
        tree.find((item) => item.id === selectedOrg)?.topics || [],
      );
      setPromptOrder(
        tree.find((item) => item.id === selectedOrg)?.rootTopics || [],
      );
      setSelectedMetadata(
        tree.find((item) => item.id === selectedOrg)?.metadata || {},
      );
    }
  }, [selectedOrg, tree]);

  useEffect(() => {
    if (selectedOrg && currentOrgMenu === 'deals') {
      setFetchingDeals(true);
      dispatch(
        getDealsForV2Customer(selectedOrg, (data) => {
          setFetchingDeals(false);
          setDeals(data);
        }),
      );
    }
  }, [currentOrgMenu, selectedOrg]);

  useEffect(() => {
    if (
      selectedOrg &&
      viewType === 'dealdetails' &&
      currentDealTab === 'artifacts' &&
      selectedDeal
    ) {
      setFetchingArtifacts(true);
      dispatch(
        getArtifactsForDeal(selectedOrg, selectedDeal?.id, (data) => {
          setFetchingArtifacts(false);
          setArtifacts(data);
        }),
      );
    }
  }, [selectedOrg, viewType, selectedDeal, currentDealTab]);

  useEffect(() => {
    if (selectedArtifact) {
      setArtifactPrompts(selectedArtifact?.metadataListOrder || []);
    }
  }, [selectedArtifact]);

  useEffect(() => {
    if (valueProp) {
      try {
        setValuePropJson('valueProp', JSON.parse(valueProp));
        setValuePropJsonError(false);
      } catch (e) {
        setValuePropJsonError(true);
        console.log(e);
      }
    }
  }, [valueProp]);

  useEffect(() => {
    dispatch(getAllDiscoveryTree(10, null, () => {}));
  }, []);

  // console.log('tree', selectedTree);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          sx={{ position: 'relative' }}
        >
          {/* <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 2,
              zIndex: 12,
            }}
          >
            <form onSubmit={formikSearch.handleSubmit}>
              <TextField
                label="Search"
                variant="outlined"
                name={'searchKeyword'}
                size="small"
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={formikSearch.values.searchKeyword}
                onChange={(e) => {
                  formikSearch.setFieldValue('searchKeyword', e.target.value);
                }}
              />
            </form>
          </Box> */}
          <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 1,
              zIndex: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowAddOrganization(true)}
            >
              Add Organization
            </Button>
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={1}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 1,
                    paddingTop: 8,
                  }
            }
          >
            {fetching ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <CircularProgress size={16} />
                <Typography>Loading tree ...</Typography>
              </Box>
            ) : (
              tree?.map((tree) => (
                <Box
                  // flex={1}
                  key={tree?.id}
                  sx={{
                    // flex: 1,
                    // width: 1,
                    padding: '8px 16px',
                    // border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    cursor: 'pointer',
                    // background:
                    //   selectedOrg === tree?.id
                    //     ? theme.palette.primary.main
                    //     : 'default',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setSelectedOrg(tree?.id)}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrg === tree?.id
                          ? theme.palette.primary.main
                          : 'text.primary',
                    }}
                  >
                    {tree?.title}
                  </Typography>
                  {/* <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrg === tree?.id ? '#fff' : 'text.secondary',
                    }}
                  >
                    {tree?.url}
                  </Typography> */}
                  {selectedOrg === tree?.id ? (
                    <Box marginLeft={1}>
                      <Typography
                        // variant="subtitle2"
                        color={'text.secondary'}
                        // fontWeight={'bold'}
                        sx={{
                          color:
                            selectedOrg === tree?.id &&
                            currentOrgMenu === 'invite'
                              ? theme.palette.primary.main
                              : 'text.secondary',
                        }}
                        onClick={() => setCurrentOrgMenu('invite')}
                      >
                        Invite
                      </Typography>
                      <Typography
                        // variant="subtitle2"
                        color={'text.secondary'}
                        // fontWeight={'bold'}
                        sx={{
                          color:
                            selectedOrg === tree?.id &&
                            currentOrgMenu === 'setup'
                              ? theme.palette.primary.main
                              : 'text.secondary',
                        }}
                        onClick={() => setCurrentOrgMenu('setup')}
                      >
                        Setup
                      </Typography>
                      <Typography
                        // variant="subtitle2"
                        color={'text.secondary'}
                        // fontWeight={'bold'}
                        sx={{
                          color:
                            selectedOrg === tree?.id &&
                            currentOrgMenu === 'deals'
                              ? theme.palette.primary.main
                              : 'text.secondary',
                        }}
                        onClick={() => setCurrentOrgMenu('deals')}
                      >
                        Deals
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ))
            )}
            {fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more tree ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && tree?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 5 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              // justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? 0 : 0,
              }}
            >
              {currentOrgMenu === 'setup' ? (
                <>
                  {tree &&
                  tree.length > 0 &&
                  tree?.find((tree) => tree?.id === selectedOrg)?.status ===
                    'pending' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 300,
                        gap: 2,
                      }}
                    >
                      <CircularProgress size={20} />
                      <Typography>Tree generation is in progress</Typography>
                    </Box>
                  ) : (
                    <>
                      {selectedOrg ? (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={1}
                          justifyContent={'space-between'}
                          border={'1px solid #d3d3d3'}
                        >
                          <Tabs
                            value={currentTab}
                            onChange={(e, newValue) => {
                              setCurrentTab(newValue);
                            }}
                            aria-label="Tabs"
                            orientation={'horizontal'}
                            // indicatorColor={theme.palette.background.default}
                            variant="scrollable"
                            // scrollButtons={!isMd}
                            allowScrollButtonsMobile
                          >
                            <Tab
                              value={'framework'}
                              label={
                                <Typography
                                  variant="caption"
                                  fontWeight={'bold'}
                                >
                                  Question Framework
                                </Typography>
                              }
                              id={`simple-tab-${0}`}
                              aria-controls={`simple-tabpanel-${0}`}
                              sx={{}}
                            />
                            <Tab
                              value={'prompts'}
                              label={
                                <Typography
                                  variant="caption"
                                  fontWeight={'bold'}
                                >
                                  Prompts
                                </Typography>
                              }
                              id={`simple-tab-${5}`}
                              aria-controls={`simple-tabpanel-${5}`}
                              sx={{}}
                            />
                          </Tabs>
                          {currentTab === 'framework' && selectedOrg ? (
                            <Button
                              color="primary"
                              variant="contained"
                              startIcon={
                                exportingTree ? (
                                  <CircularProgress size={20} />
                                ) : null
                              }
                              disabled={exportingTree}
                              onClick={() => {
                                setExportingTree(true);
                                dispatch(
                                  exportDiscoveryTree(
                                    selectedOrg,
                                    false,
                                    null,
                                    (data) => {
                                      setExportingTree(false);
                                      console.log(data);
                                      window.open(data, '_blank');
                                    },
                                  ),
                                );
                              }}
                              sx={{
                                padding: '4px 8px',
                                marginRight: 2,
                              }}
                            >
                              Export Tree
                            </Button>
                          ) : null}
                        </Box>
                      ) : null}
                      {selectedOrg ? (
                        currentTab === 'prompts' ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            {promptOrder && promptOrder?.length > 0 ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ minWidth: 150 }}
                                >
                                  <InputLabel id="promptType">
                                    Prompt Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    size="small"
                                    labelId={'promptType'}
                                    label={'Words Limit'}
                                    id={'promptType'}
                                    name={'promptType'}
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) => {
                                      setPromptType(e.target.value);
                                    }}
                                    value={promptType}
                                  >
                                    {promptOrder.map((key) => (
                                      <MenuItem key={key} value={key}>
                                        <Typography variant="subtitle2">
                                          {capitalizeText(
                                            key?.split('_').join(' '),
                                          )}
                                        </Typography>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 2,
                                    gap: 2,
                                    border: '1px solid #d3d3d3',

                                    minHeight: 'calc(100vh - 240px)',
                                    maxHeight: 'calc(100vh - 240px)',
                                    overflowY: 'scroll',
                                    '&::-webkit-scrollbar': {
                                      width: '5px',
                                      height: '5px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      boxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                      webkitBoxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      borderRadius: 2,
                                      // outline: '1px solid slategrey',
                                    },
                                  }}
                                >
                                  {selectedMetadata?.[
                                    promptType
                                  ]?.messages?.map((msg, index) => (
                                    <>
                                      {msg ? (
                                        <Box
                                          key={msg?.content}
                                          sx={{
                                            borderTopLeftRadius:
                                              msg?.role === 'user' ? 16 : 0,
                                            borderTopRightRadius:
                                              msg?.role === 'user' ? 0 : 16,
                                            borderBottomLeftRadius:
                                              msg?.role === 'user' ? 8 : 16,
                                            borderBottomRightRadius:
                                              msg?.role === 'user' ? 16 : 8,
                                            maxWidth: '80%',
                                            alignSelf:
                                              msg?.role === 'user'
                                                ? 'end'
                                                : 'start',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // gap: 1,
                                            boxShadow: theme.shadows[8],
                                            background:
                                              msg?.role === 'user'
                                                ? theme.palette.primary.main
                                                : '#fff',
                                          }}
                                        >
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx={{
                                              padding: '8px 16px',
                                              gap: 4,
                                            }}
                                          >
                                            {msg?.role === 'user' ? (
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color:
                                                    msg?.role === 'user'
                                                      ? '#f1f1f1'
                                                      : theme.palette.text
                                                          .secondary,
                                                }}
                                              >
                                                {'Prompt'}
                                              </Typography>
                                            ) : (
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color:
                                                    msg?.role === 'user'
                                                      ? '#f1f1f1'
                                                      : theme.palette.text
                                                          .secondary,
                                                }}
                                              >
                                                {'GPT'}
                                              </Typography>
                                            )}
                                            {/* <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {moment(msg?.createdOn).format(
                                              'DD MMMM YYYY hh:mm:ss A',
                                            )}
                                          </Typography> */}
                                          </Box>
                                          <Typography
                                            sx={{
                                              whiteSpace: 'pre-wrap',
                                              color:
                                                msg?.role === 'user'
                                                  ? '#fff'
                                                  : theme.palette.text.primary,
                                              padding: '0px 16px 16px 16px',
                                            }}
                                          >
                                            {msg?.content}
                                          </Typography>
                                        </Box>
                                      ) : null}
                                    </>
                                  ))}
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  minHeight: 300,
                                  gap: 2,
                                }}
                              >
                                <Typography>No meta data found</Typography>
                              </Box>
                            )}
                          </Box>
                        ) : currentTab === 'framework' ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                            position={'relative'}
                          >
                            {selectedTree && selectedTree.length > 0 ? (
                              <QuestionFrameworkTree data={selectedTree} />
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  minHeight: 300,
                                  gap: 2,
                                }}
                              >
                                <Typography>No question tree found</Typography>
                              </Box>
                            )}
                          </Box>
                        ) : null
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={
                      viewType === 'dealdetails' ||
                      viewType === 'artifactDetails'
                        ? 'flex-start'
                        : 'flex-end'
                    }
                  >
                    {viewType === 'dealdetails' ||
                    viewType === 'artifactDetails' ? (
                      <Box
                        sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                      >
                        <Button
                          startIcon={<ArrowBack />}
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            if (viewType === 'artifactDetails') {
                              setViewType('dealdetails');
                            } else {
                              setViewType('deals');
                            }
                          }}
                          sx={{ padding: '4px 8px' }}
                        >
                          Go Back
                        </Button>
                        <Typography>{selectedDeal?.name}</Typography>
                        {viewType === 'artifactDetails' ? (
                          <Typography>
                            {'>'} {selectedArtifact?.title || '--'}
                          </Typography>
                        ) : null}
                      </Box>
                    ) : (
                      <Button
                        startIcon={<Add />}
                        color="primary"
                        variant="contained"
                        onClick={() => setShowAddDeal(selectedOrg)}
                      >
                        Add Deal
                      </Button>
                    )}
                  </Box>
                  {viewType === 'deals' ? (
                    fetchingDeals ? (
                      <Box
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 10,
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress size={16} />
                        <Typography>Loading deals ...</Typography>
                      </Box>
                    ) : (
                      <TableContainer
                        component={Paper}
                        sx={{
                          minHeight: 'calc(100vh - 120px)',
                          maxHeight: 'calc(100vh - 120px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },
                        }}
                        // onScroll={handleListScroll}
                      >
                        <Table
                          sx={{ minWidth: 750 }}
                          stickyHeader
                          aria-label="simple table"
                        >
                          <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Deal Title
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Company
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Website
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Date
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Artifacts
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Actions
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {deals.map((deal, i) => (
                              <TableRow
                                key={i}
                                // sx={{
                                //   '&:last-child td, &:last-child th': {
                                //     border: 0,
                                //   },
                                // }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography
                                    variant={'subtitle2'}
                                    // fontWeight={700}
                                  >
                                    {deal?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    variant={'subtitle2'}
                                    // fontWeight={700}
                                  >
                                    {deal?.company?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    variant={'subtitle2'}
                                    // fontWeight={700}
                                  >
                                    {deal?.company?.website}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant={'caption'}
                                    // fontWeight={700}
                                    // sx={{
                                    //   color: theme.palette.success.main,
                                    // }}
                                  >
                                    {moment(deal?.createdOn).format(
                                      'DD MMMM YYYY',
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    // fontWeight={700}
                                    // sx={{
                                    //   color: theme.palette.success.main,
                                    // }}
                                  >
                                    {deal?.counts?.interactions}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    gap={1}
                                  >
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Deal Details"
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setViewType('dealdetails');
                                          setCurrentDealTab('artifacts');
                                          setSelectedDeal(deal);
                                        }}
                                      >
                                        <Article sx={{ fontSize: 20 }} />
                                      </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip
                                    arrow
                                    placement="top"
                                    title="Uppload Artifact"
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setShowUploadArtifact(deal?.id);
                                      }}
                                    >
                                      <CloudUpload sx={{ fontSize: 20 }} />
                                    </IconButton>
                                  </Tooltip> */}
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Remove"
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setShowConfirmDeleteDeal(deal?.id);
                                        }}
                                      >
                                        <Delete sx={{ fontSize: 20 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                variant="footer"
                                colSpan={6}
                                sx={{
                                  padding: 0,
                                }}
                              >
                                {fetchingPagination ? (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      // type="submit"
                                      color="primary"
                                      onClick={handleListScroll}
                                      // className="searchSettings"
                                      disabled
                                      // fullWidth
                                    >
                                      <Typography color="textPrimary">
                                        Loading more bots ...{' '}
                                      </Typography>
                                      <CircularProgress size={20} />
                                    </Button>
                                  </Box>
                                ) : emptyList === false &&
                                  deals.length >= 10 ? (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      // type="submit"
                                      // fullWidth
                                      color="primary"
                                      // onClick={(e) => handleListScroll(e, true)}
                                      // className="searchSettings"
                                    >
                                      Load More
                                    </Button>
                                  </Box>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    )
                  ) : viewType === 'dealdetails' ||
                    viewType === 'artifactDetails' ? (
                    <Box>
                      {viewType === 'dealdetails' ? (
                        <Tabs
                          value={currentDealTab}
                          onChange={(e, newValue) =>
                            setCurrentDealTab(newValue)
                          }
                          aria-label="Current Deal Tabs"
                          orientation={'horizontal'}
                          // indicatorColor={theme.palette.background.default}
                          variant="scrollable"
                          // scrollButtons={!isMd}
                          allowScrollButtonsMobile
                          sx={{
                            border: '1px solid #d3d3d3',
                          }}
                        >
                          <Tab
                            value={'artifacts'}
                            label={
                              <Typography variant="body1">Artifacts</Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />

                          <Tab
                            value={'uploadArtifact'}
                            label={
                              <Typography variant="body1">
                                Upload Artifact
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'details'}
                            label={
                              <Typography variant="body1">
                                Deal Details
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'tree'}
                            label={
                              <Typography variant="body1">Tree</Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          {/* <Tab
                          value={'prompts'}
                          label={
                            <Typography variant="body1">Prompts</Typography>
                          }
                          id={`simple-tab-${0}`}
                          aria-controls={`simple-tabpanel-${0}`}
                          sx={{}}
                        /> */}
                        </Tabs>
                      ) : (
                        <Tabs
                          value={currentArtifactTab}
                          onChange={(e, newValue) =>
                            setCurrentArtifactTab(newValue)
                          }
                          aria-label="Current Artifact Tabs"
                          orientation={'horizontal'}
                          // indicatorColor={theme.palette.background.default}
                          variant="scrollable"
                          // scrollButtons={!isMd}
                          allowScrollButtonsMobile
                          sx={{
                            border: '1px solid #d3d3d3',
                          }}
                        >
                          <Tab
                            value={'artifactContent'}
                            label={
                              <Typography variant="body1">
                                Original Artifact
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />

                          <Tab
                            value={'artifactOverview'}
                            label={
                              <Typography variant="body1">Overview</Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'artifactTree'}
                            label={
                              <Typography variant="body1">
                                Tree(Cummulative deltas)
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'prompt'}
                            label={
                              <Typography variant="body1">Prompt</Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          {/* <Tab
                        value={'prompts'}
                        label={
                          <Typography variant="body1">Prompts</Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      /> */}
                        </Tabs>
                      )}
                      <Box
                        sx={{
                          minHeight: 'calc(100vh - 220px)',
                          maxHeight: 'calc(100vh - 220px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },
                          border: '1px solid #d3d3d3',
                          padding: 2,
                        }}
                      >
                        {viewType === 'dealdetails' ? (
                          currentDealTab === 'artifacts' ? (
                            fetchingArtifacts ? (
                              <Box
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 10,
                                  textAlign: 'center',
                                }}
                              >
                                <CircularProgress size={16} />
                                <Typography>Loading artifacts ...</Typography>
                              </Box>
                            ) : (
                              <TableContainer
                                component={Paper}
                                sx={{
                                  minHeight: 'calc(100vh - 120px)',
                                  maxHeight: 'calc(100vh - 120px)',
                                  overflowY: 'scroll',
                                  '&::-webkit-scrollbar': {
                                    width: '5px',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow:
                                      'inset 0 0 6px rgba(0,0,0,0.00)',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: 2,
                                  },
                                }}
                                // onScroll={handleListScroll}
                              >
                                <Table
                                  sx={{ minWidth: 750 }}
                                  stickyHeader
                                  aria-label="simple table"
                                >
                                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Artifact Title
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Artifact Type
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Buyers
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Sellers
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Date
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Actions
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {artifacts.map((artifact, i) => (
                                      <TableRow
                                        key={i}
                                        // sx={{
                                        //   '&:last-child td, &:last-child th': {
                                        //     border: 0,
                                        //   },
                                        // }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {artifactEditable?.id ===
                                          artifact?.id ? (
                                            <form
                                              onSubmit={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: 1,
                                                }}
                                              >
                                                <TextField
                                                  autoFocus
                                                  placeholder="Artifact Title"
                                                  variant="outlined"
                                                  color="primary"
                                                  size="small"
                                                  name="artifactTitle"
                                                  value={artifactTitle}
                                                  onChange={(e) =>
                                                    setArtifactTitle(
                                                      e.target.value,
                                                    )
                                                  }
                                                />
                                                <Box
                                                  display={'flex'}
                                                  flexDirection={'column'}
                                                  gap={0.5}
                                                >
                                                  <Tooltip
                                                    title="Close"
                                                    arrow
                                                    placement="right"
                                                  >
                                                    <IconButton
                                                      sx={{
                                                        borderRadius: 1,
                                                        color: '#fff',
                                                        width: 16,
                                                        height: 16,
                                                        background:
                                                          theme.palette.text
                                                            .secondary,
                                                        ':hover': {
                                                          backgroundColor:
                                                            theme.palette.text
                                                              .secondary,
                                                        },
                                                      }}
                                                      onClick={() => {
                                                        setArtifactEditable(
                                                          null,
                                                        );
                                                      }}
                                                    >
                                                      <Close
                                                        sx={{ fontSize: 15 }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title="Done"
                                                    arrow
                                                    placement="right"
                                                  >
                                                    {updatingArtifactTitle ? (
                                                      <CircularProgress
                                                        size={14}
                                                        sx={{
                                                          color:
                                                            theme.palette
                                                              .primary.main,
                                                        }}
                                                      />
                                                    ) : (
                                                      <IconButton
                                                        disabled={
                                                          !artifactTitle?.trim() ||
                                                          updatingArtifactTitle
                                                        }
                                                        sx={{
                                                          borderRadius: 1,
                                                          color: '#fff',
                                                          width: 16,
                                                          height: 16,
                                                          background:
                                                            theme.palette
                                                              .primary.main,
                                                          ':hover': {
                                                            backgroundColor:
                                                              theme.palette
                                                                .primary.main,
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          setUpdatingArtifactTitle(
                                                            true,
                                                          );
                                                          dispatch(
                                                            udpateArtifactForDeal(
                                                              selectedOrg,
                                                              selectedDeal?.id,
                                                              artifactEditable?.id,
                                                              {
                                                                title: artifactTitle,
                                                              },
                                                              (data) => {
                                                                setUpdatingArtifactTitle(
                                                                  false,
                                                                );
                                                                setArtifactEditable(
                                                                  null,
                                                                );
                                                                setArtifacts(
                                                                  (prev) => {
                                                                    return prev.map(
                                                                      (
                                                                        artifact,
                                                                      ) => {
                                                                        if (
                                                                          artifact?.id ===
                                                                          data?.id
                                                                        ) {
                                                                          return data;
                                                                        }
                                                                        return artifact;
                                                                      },
                                                                    );
                                                                  },
                                                                );
                                                              },
                                                            ),
                                                          );
                                                        }}
                                                      >
                                                        <Check
                                                          sx={{ fontSize: 15 }}
                                                        />
                                                      </IconButton>
                                                    )}
                                                  </Tooltip>
                                                </Box>
                                              </Box>
                                            </form>
                                          ) : (
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                'Click to update artifact title'
                                              }
                                            >
                                              <Typography
                                                variant={'subtitle2'}
                                                // fontWeight={700}
                                                sx={{
                                                  cursor: 'pointer',
                                                  maxWidth: 200,
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  display: '-webkit-box',
                                                  WebkitLineClamp: '1',
                                                  WebkitBoxOrient: 'vertical',
                                                }}
                                                onClick={() => {
                                                  setArtifactEditable(artifact);
                                                }}
                                              >
                                                {artifact?.title || '--'}
                                              </Typography>
                                            </Tooltip>
                                          )}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                          >
                                            {artifact?.interactionType}
                                          </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                          >
                                            {artifact?.buyers?.join(', ')}
                                          </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                          >
                                            {artifact?.sellers?.join(', ')}
                                          </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Typography
                                            variant={'caption'}
                                            // fontWeight={700}
                                          >
                                            {artifact?.interactionTime
                                              ? moment(
                                                  artifact?.interactionTime,
                                                ).format(
                                                  'DD MMMM YYYY hh:mm:ss A',
                                                )
                                              : '--'}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <Box
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            gap={1}
                                          >
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title="Artifact Details"
                                            >
                                              <IconButton
                                                size="small"
                                                onClick={() => {
                                                  setViewType(
                                                    'artifactDetails',
                                                  );
                                                  setCurrentArtifactTab(
                                                    'artifactContent',
                                                  );
                                                  setSelectedArtifact(artifact);
                                                }}
                                              >
                                                <Article
                                                  sx={{ fontSize: 20 }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title="Remove"
                                            >
                                              <IconButton
                                                size="small"
                                                onClick={() => {
                                                  setShowConfirmDeleteArtifact(
                                                    artifact?.id,
                                                  );
                                                }}
                                              >
                                                <Delete sx={{ fontSize: 20 }} />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                  <TableFooter>
                                    <TableRow>
                                      <TableCell
                                        variant="footer"
                                        colSpan={6}
                                        sx={{
                                          padding: 0,
                                        }}
                                      >
                                        {fetchingPagination ? (
                                          <Box
                                            sx={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              padding: 1,
                                              textAlign: 'center',
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              // type="submit"
                                              color="primary"
                                              onClick={handleListScroll}
                                              // className="searchSettings"
                                              disabled
                                              // fullWidth
                                            >
                                              <Typography color="textPrimary">
                                                Loading more bots ...{' '}
                                              </Typography>
                                              <CircularProgress size={20} />
                                            </Button>
                                          </Box>
                                        ) : emptyList === false &&
                                          deals.length >= 10 ? (
                                          <Box
                                            sx={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              padding: 1,
                                              textAlign: 'center',
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              // type="submit"
                                              // fullWidth
                                              color="primary"
                                              // onClick={(e) => handleListScroll(e, true)}
                                              // className="searchSettings"
                                            >
                                              Load More
                                            </Button>
                                          </Box>
                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  </TableFooter>
                                </Table>
                              </TableContainer>
                            )
                          ) : currentDealTab === 'uploadArtifact' ? (
                            <Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                }}
                              >
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  // gap={2}
                                >
                                  <TextField
                                    label="Artifact Title"
                                    variant="outlined"
                                    name={'artifactTitle'}
                                    size="small"
                                    fullWidth
                                    value={artifactTitle}
                                    onChange={(e) =>
                                      setArtifactTitle(e.target.value)
                                    }
                                  />
                                  <FormControl size="small">
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                      <Typography variant="caption">
                                        Artifact Type
                                      </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      value={artifactType || ''}
                                      onChange={(e) =>
                                        setArtifactType(e.target.value)
                                      }
                                    >
                                      <FormControlLabel
                                        value={'transcript'}
                                        control={<Radio size="small" />}
                                        label={'Transcript'}
                                      />
                                      <FormControlLabel
                                        value={'email'}
                                        control={<Radio size="small" />}
                                        label={'Email'}
                                      />
                                      <FormControlLabel
                                        value={'note'}
                                        control={<Radio size="small" />}
                                        label={'Note'}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                  <Box>
                                    <Typography
                                      variant="caption"
                                      color={'text.secondary'}
                                    >
                                      Artifact Time
                                    </Typography>
                                    <br />
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DateTimePicker
                                        // label="Meeting Time"
                                        size="small"
                                        value={dayjs(artifactTime)}
                                        onChange={(newValue) => {
                                          console.log(
                                            new Date(newValue).getTime(),
                                          );
                                          setArtifactTime(
                                            new Date(newValue).getTime(),
                                          );
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </Box>
                                </Box>
                                <TextField
                                  label="Buyer's Participants(Comma Seperated)"
                                  variant="outlined"
                                  name={'buyerParticipants'}
                                  size="small"
                                  fullWidth
                                  value={buyerParticipants}
                                  onChange={(e) =>
                                    setBuyerParticipants(e.target.value)
                                  }
                                />
                                <TextField
                                  label="Seller's Participants(Comma Seperated)"
                                  variant="outlined"
                                  name={'sellerParticipants'}
                                  size="small"
                                  fullWidth
                                  value={sellerParticipants}
                                  onChange={(e) =>
                                    setSellerParticipants(e.target.value)
                                  }
                                />
                                <TextField
                                  label="Content"
                                  variant="outlined"
                                  name={'artifactContent'}
                                  size="small"
                                  fullWidth
                                  multiline
                                  rows={8}
                                  value={artifactContent}
                                  onChange={(e) =>
                                    setArtifactContent(e.target.value)
                                  }
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    disabled={
                                      generatingValueProp ||
                                      creatingDiscoverytree
                                    }
                                    startIcon={
                                      creatingDiscoverytree ? (
                                        <CircularProgress size={20} />
                                      ) : null
                                    }
                                    onClick={(e) => {
                                      setAddingArtifactForDeal(true);
                                      dispatch(
                                        addArtifactForDeal(
                                          selectedOrg,
                                          selectedDeal?.id,
                                          {
                                            buyers:
                                              buyerParticipants?.split(',') ||
                                              [],
                                            sellers:
                                              sellerParticipants?.split(',') ||
                                              [],
                                            content: artifactContent,
                                            interactionType: artifactType,
                                            interactionTime: artifactTime,
                                            title: artifactTitle,
                                          },
                                          (data) => {
                                            setArtifactType('transcript');
                                            setArtifactContent('');
                                            setBuyerParticipants('');
                                            setSellerParticipants('');
                                            setAddingArtifactForDeal(false);
                                            setShowUploadArtifact(false);
                                            setCurrentDealTab('artifacts');
                                          },
                                        ),
                                      );
                                    }}
                                    color="primary"
                                    autoFocus
                                  >
                                    Upload
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          ) : currentDealTab === 'tree' ? (
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                              position={'relative'}
                            >
                              {selectedDeal?.topics &&
                              selectedDeal?.topics.length > 0 ? (
                                <QuestionFrameworkTree
                                  data={selectedDeal.topics}
                                  dealTree={true}
                                  showExportButton={true}
                                  treeId={selectedOrg}
                                  dealId={selectedDeal?.id}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: 300,
                                    gap: 2,
                                  }}
                                >
                                  <Typography>
                                    No question tree found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          ) : null
                        ) : currentArtifactTab === 'artifactContent' ? (
                          <Typography
                            sx={{
                              whiteSpace: 'pre-wrap',
                              padding: 2,
                            }}
                          >
                            {selectedArtifact?.content}
                          </Typography>
                        ) : currentArtifactTab === 'artifactOverview' ? (
                          selectedArtifact?.discoveryTreeStatus === null ||
                          selectedArtifact?.discoveryTreeStatus ===
                            'in_process' ? (
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 10,
                                textAlign: 'center',
                              }}
                            >
                              <CircularProgress size={16} />
                              <Typography>
                                Overview generation is in progress ...
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}
                            >
                              <Typography color={'textSecondary'}>
                                Artifact Summary
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                }}
                              >
                                {selectedArtifact?.interactionSummary?.map(
                                  (summary) => {
                                    return (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 1,
                                        }}
                                      >
                                        <Typography color={'textSecondary'}>
                                          {summary?.title}
                                        </Typography>
                                        <Typography>
                                          {summary?.summary}
                                        </Typography>
                                      </Box>
                                    );
                                  },
                                )}
                              </Box>
                              <Typography color={'textSecondary'}>
                                Answers from Artifact
                              </Typography>
                              <TableContainer
                                component={Paper}
                                sx={
                                  {
                                    // minHeight: 'calc(100vh - 120px)',
                                    // maxHeight: 'calc(100vh - 120px)',
                                    // overflowY: 'scroll',
                                    // '&::-webkit-scrollbar': {
                                    //   width: '5px',
                                    // },
                                    // '&::-webkit-scrollbar-track': {
                                    //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    //   webkitBoxShadow:
                                    //     'inset 0 0 6px rgba(0,0,0,0.00)',
                                    // },
                                    // '&::-webkit-scrollbar-thumb': {
                                    //   backgroundColor: theme.palette.primary.main,
                                    //   borderRadius: 2,
                                    // },
                                  }
                                }
                                // onScroll={handleListScroll}
                              >
                                <Table
                                  sx={{ minWidth: 750 }}
                                  stickyHeader
                                  aria-label="simple table"
                                >
                                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Topic
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Subtopic
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Question
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Answer
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Reasoning
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Weighted Score
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'uppercase' }}
                                        >
                                          Confidence Score
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Object.values(
                                      selectedArtifact?.interactionAnswers ||
                                        {},
                                    ) &&
                                    Object.values(
                                      selectedArtifact?.interactionAnswers ||
                                        {},
                                    )?.length > 0
                                      ? Object.values(
                                          selectedArtifact?.interactionAnswers ||
                                            {},
                                        )?.map((ia, i) => (
                                          <TableRow
                                            key={i}
                                            // sx={{
                                            //   '&:last-child td, &:last-child th': {
                                            //     border: 0,
                                            //   },
                                            // }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography
                                                variant={'subtitle2'}
                                                // fontWeight={700}
                                              >
                                                {ia?.treeDetails?.topic?.topic}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography
                                                variant={'subtitle2'}
                                                // fontWeight={700}
                                              >
                                                {
                                                  ia?.treeDetails?.subTopic
                                                    ?.topic
                                                }
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography
                                                variant={'subtitle2'}
                                                // fontWeight={700}
                                              >
                                                {ia?.treeDetails?.question?.q}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography
                                                variant={'caption'}
                                                // fontWeight={700}
                                              >
                                                {ia?.sa === 'y'
                                                  ? 'Yes'
                                                  : ia?.sa === 'n'
                                                  ? 'No'
                                                  : '--'}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <Typography
                                                variant={'caption'}
                                                // fontWeight={700}
                                              >
                                                {ia?.rsa}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            >
                                              <Typography
                                                variant={'caption'}
                                                // fontWeight={700}
                                              >
                                                {ia?.treeDetails?.question?.wus?.toFixed(
                                                  2,
                                                )}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            >
                                              <Typography
                                                variant={'caption'}
                                                // fontWeight={700}
                                              >
                                                {ia?.treeDetails?.question?.cus
                                                  ? ia?.treeDetails?.question?.cus?.toFixed(
                                                      2,
                                                    )
                                                  : '--'}
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      : null}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Typography color={'textSecondary'}>
                                Buyers Reference
                              </Typography>
                              <Box sx={{ padding: 1 }}>
                                {selectedArtifact?.buyersReference?.length > 0
                                  ? selectedArtifact?.buyersReference?.map(
                                      (br) => (
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              padding: 1,
                                            }}
                                          >
                                            <Typography variant="subtitle2">
                                              Buyer Name
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              padding: 1,
                                            }}
                                          >
                                            <Typography variant="subtitle2">
                                              Mention
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              padding: 1,
                                            }}
                                          >
                                            <Typography variant="subtitle2">
                                              {br?.buyer}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              padding: 1,
                                            }}
                                          >
                                            <Typography variant="subtitle2">
                                              {br?.mentions}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ),
                                    )
                                  : null}
                              </Box>
                            </Box>
                          )
                        ) : currentArtifactTab === 'artifactTree' ? (
                          selectedArtifact?.discoveryTreeStatus === null ||
                          selectedArtifact?.discoveryTreeStatus ===
                            'in_process' ? (
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 10,
                                textAlign: 'center',
                              }}
                            >
                              <CircularProgress size={16} />
                              <Typography>
                                Discovery tree delta generation is in progress
                                ...
                              </Typography>
                            </Box>
                          ) : (
                            <Box position={'relative'}>
                              {selectedDeal?.topics &&
                              selectedDeal?.topics.length > 0 ? (
                                <QuestionFrameworkTree
                                  data={selectedDeal.topics}
                                  dealTree={true}
                                  interactionAnswers={
                                    selectedArtifact?.interactionAnswers
                                  }
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: 300,
                                    gap: 2,
                                  }}
                                >
                                  <Typography>
                                    No question tree found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          )
                        ) : currentArtifactTab === 'prompt' ? (
                          <>
                            {artifactPrompts && artifactPrompts?.length > 0 ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ minWidth: 150 }}
                                >
                                  <InputLabel id="promptType">
                                    Prompt Type
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    size="small"
                                    labelId={'promptType'}
                                    label={'Artifact Prompt'}
                                    id={'promptType'}
                                    name={'promptType'}
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) => {
                                      setSelectedArtifactPrompt(e.target.value);
                                    }}
                                    value={selectedArtifactPrompt}
                                  >
                                    {artifactPrompts.map((key) => (
                                      <MenuItem key={key} value={key}>
                                        <Typography variant="subtitle2">
                                          {capitalizeText(
                                            key?.split('_').join(' '),
                                          )}
                                        </Typography>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: 2,
                                    gap: 2,
                                    border: '1px solid #d3d3d3',

                                    minHeight: 'calc(100vh - 310px)',
                                    maxHeight: 'calc(100vh - 310px)',
                                    overflowY: 'scroll',
                                    '&::-webkit-scrollbar': {
                                      width: '5px',
                                      height: '5px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      boxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                      webkitBoxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      borderRadius: 2,
                                      // outline: '1px solid slategrey',
                                    },
                                  }}
                                >
                                  {selectedArtifact?.metadata?.[
                                    selectedArtifactPrompt
                                  ]?.messages?.map((msg, index) => (
                                    <>
                                      {msg ? (
                                        <Box
                                          key={msg?.content}
                                          sx={{
                                            borderTopLeftRadius:
                                              msg?.role === 'user' ? 16 : 0,
                                            borderTopRightRadius:
                                              msg?.role === 'user' ? 0 : 16,
                                            borderBottomLeftRadius:
                                              msg?.role === 'user' ? 8 : 16,
                                            borderBottomRightRadius:
                                              msg?.role === 'user' ? 16 : 8,
                                            maxWidth: '80%',
                                            alignSelf:
                                              msg?.role === 'user'
                                                ? 'end'
                                                : 'start',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // gap: 1,
                                            boxShadow: theme.shadows[8],
                                            background:
                                              msg?.role === 'user'
                                                ? theme.palette.primary.main
                                                : '#fff',
                                          }}
                                        >
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx={{
                                              padding: '8px 16px',
                                              gap: 4,
                                            }}
                                          >
                                            {msg?.role === 'user' ? (
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color:
                                                    msg?.role === 'user'
                                                      ? '#f1f1f1'
                                                      : theme.palette.text
                                                          .secondary,
                                                }}
                                              >
                                                {'Prompt'}
                                              </Typography>
                                            ) : (
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color:
                                                    msg?.role === 'user'
                                                      ? '#f1f1f1'
                                                      : theme.palette.text
                                                          .secondary,
                                                }}
                                              >
                                                {'GPT'}
                                              </Typography>
                                            )}
                                            {/* <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {moment(msg?.createdOn).format(
                                              'DD MMMM YYYY hh:mm:ss A',
                                            )}
                                          </Typography> */}
                                          </Box>
                                          <Typography
                                            sx={{
                                              whiteSpace: 'pre-wrap',
                                              color:
                                                msg?.role === 'user'
                                                  ? '#fff'
                                                  : theme.palette.text.primary,
                                              padding: '0px 16px 16px 16px',
                                            }}
                                          >
                                            {msg?.content}
                                          </Typography>
                                        </Box>
                                      ) : null}
                                    </>
                                  ))}
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  minHeight: 300,
                                  gap: 2,
                                }}
                              >
                                <Typography>No meta data found</Typography>
                              </Box>
                            )}
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showAddOrganization}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '100%',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Organization</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                Model Name*
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="model-select-label">Select Model</InputLabel>
                  <Select
                    labelId="model-select-label"
                    label="Select Model"
                    value={modelName || ''}
                    onChange={(e) => {
                      setModelName(e.target.value);
                    }}
                  >
                    <MenuItem value={'gpt-4o-mini-2024-07-18'}>
                      gpt-4o-mini-2024-07-18
                    </MenuItem>
                    {models?.map((m) => (
                      <MenuItem value={m?.modelName}>{m?.modelName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                Organization Name*
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Organization Name"
                  variant="outlined"
                  name={'organizationName'}
                  size="small"
                  fullWidth
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                Website URL*
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Website URL"
                  variant="outlined"
                  name={'websiteURL'}
                  size="small"
                  fullWidth
                  value={websiteURL}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                About Company
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  placeholder="About Company"
                  variant="outlined"
                  color="primary"
                  size="small"
                  multiline
                  rows={4}
                  name="aboutCompany"
                  value={aboutCompany}
                  onChange={(e) => setAboutCompany(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>
                    Discovery Expectation* (what do you expect to uncover from
                    the discovery call)
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ padding: '4px 8px' }}
                    disabled={generatingValueProp}
                    startIcon={
                      generatingValueProp ? (
                        <CircularProgress size={20} />
                      ) : null
                    }
                    onClick={(e) => {
                      setGeneratingValueProp(true);
                      dispatch(
                        generateValueProp(
                          {
                            url: websiteURL,
                            model: modelName,
                            aboutCompany: aboutCompany,
                          },
                          (data) => {
                            setGeneratingValueProp(false);
                            setValueProp(data?.valuePropHtml);
                            setMetaData(data?.metadata);
                            setValuePropHTML(data?.valuePropHtml);
                          },
                        ),
                      );
                    }}
                  >
                    Generate
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                  value={valueProp || ''}
                  onChange={setValueProp}
                  placeholder="Enter Value Proposition"
                  height="300px"
                />
              </Grid>
              <Grid item xs={12} mt={7}>
                {valuePropJsonError ? (
                  <Typography color={theme.palette.error.main}>
                    Invalid JSON
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  // sx={{ marginBottom: 2 }}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Selling Style
                  </FormLabel>
                  <Select
                    // ref={promptSelectRef}
                    labelId="prompt-select-label"
                    // label="Select Prompt"
                    name={'sellingStyle'}
                    value={sellingStyle || ''}
                    onChange={(e) => setSellingStyle(e.target.value)}
                  >
                    {sellingStyles?.map((ss) => (
                      <MenuItem value={ss?.value}>{ss?.style}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="promptType">Prompt Type</InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    labelId={'promptType'}
                    label={'Words Limit'}
                    id={'promptType'}
                    name={'promptType'}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setPromptType(e.target.value);
                    }}
                    value={promptType}
                  >
                    {promptOrder.map((key) => (
                      <MenuItem key={key} value={key}>
                        <Typography variant="subtitle2">
                          {capitalizeText(key?.split('_').join(' '))}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowAddOrganization(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={generatingValueProp || creatingDiscoverytree}
              startIcon={
                creatingDiscoverytree ? <CircularProgress size={20} /> : null
              }
              onClick={(e) => {
                setCreatingDiscoverytree(true);
                dispatch(
                  createDiscoveryTree(
                    {
                      title: organizationName,
                      url: websiteURL,
                      // valueProp: JSON.stringify(valuePropJson, null, 2),
                      valueProp: valueProp,
                      metaData: metaData,
                      model: modelName,
                      sellingStyle: sellingStyle,
                      aboutCompany: aboutCompany,
                    },
                    (data) => {
                      setCreatingDiscoverytree(false);
                      dispatch(getAllDiscoveryTree(10, null, () => {}));
                      setShowAddOrganization(false);
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showUploadArtifact}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '100%',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Upload Artifact</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                Type
              </Grid>
              <Grid item xs={12}>
                <Select
                  // ref={promptSelectRef}
                  labelId="artifact-type-select-label"
                  // label="Select Prompt"
                  value={artifactType || ''}
                  onChange={(e) => {
                    setArtifactType(e.target.value);
                  }}
                >
                  <MenuItem value={'transcript'}>Transcript</MenuItem>
                  <MenuItem value={'email'}>Email</MenuItem>
                  <MenuItem value={'note'}>Note</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                Buyer's Participants(Comma Seperated)
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Buyer's Participants(Comma Seperated)"
                  variant="outlined"
                  name={'buyerParticipants'}
                  size="small"
                  fullWidth
                  value={buyerParticipants}
                  onChange={(e) => setBuyerParticipants(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                Seller's Participants(Comma Seperated)
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Seller's Participants(Comma Seperated)"
                  variant="outlined"
                  name={'sellerParticipants'}
                  size="small"
                  fullWidth
                  value={sellerParticipants}
                  onChange={(e) => setSellerParticipants(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>Content</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Content"
                  variant="outlined"
                  name={'artifactContent'}
                  size="small"
                  fullWidth
                  multiline
                  rows={8}
                  value={artifactContent}
                  onChange={(e) => setArtifactContent(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowUploadArtifact(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={generatingValueProp || creatingDiscoverytree}
              startIcon={
                creatingDiscoverytree ? <CircularProgress size={20} /> : null
              }
              onClick={(e) => {
                setAddingArtifactForDeal(true);
                dispatch(
                  addArtifactForDeal(
                    selectedOrg,
                    showUploadArtifact,
                    {
                      buyers: buyerParticipants?.split(',') || [],
                      sellers: sellerParticipants?.split(',') || [],
                      content: artifactContent,
                      interactionType: artifactType,
                    },
                    (data) => {
                      setAddingArtifactForDeal(false);
                      setShowUploadArtifact(false);
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Upload
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showAddDeal}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Deal</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              minWidth: 300,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                Deal Title
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ minWidth: 300 }}
                  label="Deal Title"
                  variant="outlined"
                  name={'dealTitle'}
                  size="small"
                  // fullWidth
                  value={dealTitle}
                  onChange={(e) => setDealTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                Company Name
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ minWidth: 300 }}
                  label="Company Name"
                  variant="outlined"
                  name={'companyName'}
                  size="small"
                  // fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                Website URL
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ minWidth: 300 }}
                  label="Website URL"
                  variant="outlined"
                  name={'companyWebsite'}
                  size="small"
                  // fullWidth
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowAddDeal(null);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                dispatch(
                  createDealForV2Customer(
                    showAddDeal,
                    {
                      company: {
                        name: companyName,
                        website: companyWebsite,
                      },
                      name: dealTitle,
                    },
                    (data) => {
                      setFetchingDeals(true);
                      dispatch(
                        getDealsForV2Customer(selectedOrg, (data) => {
                          setFetchingDeals(false);
                          setDeals(data);
                        }),
                      );
                      setShowAddDeal(null);
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Add
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteDeal)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this deal?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteDeal(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                deleteDealForV2Customer(
                  selectedOrg,
                  showConfirmDeleteDeal,
                  () => {
                    setFetchingDeals(true);
                    dispatch(
                      getDealsForV2Customer(selectedOrg, (data) => {
                        setFetchingDeals(false);
                        setDeals(data);
                      }),
                    );
                    setShowConfirmDeleteDeal(null);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteArtifact)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this artifact?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteArtifact(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                deleteArtifactForDeal(
                  selectedOrg,
                  selectedDeal?.id,
                  showConfirmDeleteArtifact,
                  () => {
                    setFetchingArtifacts(true);
                    dispatch(
                      getArtifactsForDeal(
                        selectedOrg,
                        selectedDeal?.id,
                        (data) => {
                          setFetchingArtifacts(false);
                          setArtifacts(data);
                        },
                      ),
                    );
                    setShowConfirmDeleteArtifact(null);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showArtifactContent)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Artifact</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography>{showArtifactContent?.content}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              setShowArtifactContent(null);
            }}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default OrganizationsQuestionFramework;
