import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import inviteUser from 'redux/actions/Common/inviteUser';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Autocomplete,
  Avatar,
  Card,
  Chip,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import generateSignupLink from 'redux/actions/Common/generateSignupLink';
import RichTextEditor from 'components/RichTextEditor';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import { Add, Close, CloudUpload, Delete, Settings } from '@mui/icons-material';
import getUploadUrlForFile from 'redux/actions/Common/getUploadUrlForFile';
import confirmFileUpload from 'redux/actions/Common/confirmFileUpload';
import uploadVideoAction from 'redux/actions/Common/uploadVideoAction';
import getAllInvitations from 'redux/actions/Common/getAllInvitations';
import moment from 'moment';
import PlaybookGeneration from '../PlaybookGeneration';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import getPlans from 'redux/actions/Common/getPlans';
import { capitalizeText } from 'utils';
import getAddOns from 'redux/actions/Common/getAddOns';
import { title } from 'process';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';
import extractContentScoringConfig from 'redux/actions/Common/extractContentScoringConfig';
import generateValueProp from 'redux/actions/Common/generateValueProp';

const sellingStyles = [
  {
    id: 1,
    value: 'persuasive_or_traditional_selling',
    style: 'Persuasive (Traditional) Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 2,
    value: 'consultative_or_solution_selling',
    style: 'Consultative or Solution Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 3,
    value: 'relationship_or_trust_based_selling',
    style: 'Relationship (Trust-Based) Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 4,
    value: 'value_based_selling',
    style: 'Value-Based Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 5,
    value: 'insight_or_challenger_selling',
    style: 'Insider or Challenger Selling(e.g., Challenger Sales Method)',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 6,
    value: 'spin_or_question_based_selling',
    style: 'SPIN or Question-Based Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 7,
    value: 'collaborative_or_partnering_selling',
    style: 'Collaborative or Partnering Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 8,
    value: 'inbound_educational_selling',
    style: 'Inbound / Educational Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
  {
    id: 9,
    value: 'social_selling',
    style: 'Social Selling',
    focus:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    approach:
      'Convincing the buyer to purchase by emphasizing product features, benefits, and urgency.',
    goal:
      'Close the sale quickly, often through well-honed scripts, special offers, and push strategies.',
    sampleQuestion1:
      'Our solution is designed to tackle the most pressing challenges businesses like yours face. Would you say the biggest obstacle holding you back right now is [Problem A] or [Problem B]? And if we could eliminate that, how quickly would you be ready to move forward?',
    sampleQuestion2:
      'Given the current market trends and competitive pressures, it’s crucial to act quickly. Are there any deadlines, budget cycles, or leadership priorities driving the urgency to implement a solution now?',
    sampleQuestion3:
      'When challenges like this go unresolved, they often lead to inefficiencies, lost revenue, and operational headaches. How have these issues been affecting your business, and what would solving them mean for your bottom line?',
  },
];

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('Email is required'),
  companyName: yup.string().trim(),
  websiteURL: yup.string().trim(),
  aboutCompany: yup.string().trim(),
  valueProp: yup.object().nullable(),
  sellingStyle: yup.string().trim(),
});

const InviteV2Customer = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const inputImage = useRef(null);
  const [appLogo, setAppLogo] = useState(null);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [invalidImageType, setInvalidImageType] = useState(false);
  const [appLogoId, setAppLogoId] = useState('');

  const inputImage1 = useRef(null);
  const [favicon, setFavicon] = useState(null);
  const [faviconSizeError, setFaviconSizeError] = useState(false);
  const [invalidFaviconType, setInvalidFaviconType] = useState(false);
  const [faviconId, setFaviconId] = useState('');

  const inputImage2 = useRef(null);
  const [appLogoWithText, setAppLogoWithText] = useState(null);
  const [appLogoWithTextSizeError, setAppLogoWithTextSizeError] = useState(
    false,
  );
  const [invalidAppLogoWithTextType, setInvalidAppLogoWithTextType] = useState(
    false,
  );
  const [withTextLogoId, setWithTextLogoId] = useState('');

  const [invitingUser, setInvitingUser] = useState(false);
  const [currentTab, setCurrentTab] = useState('invite');

  const [showGeneratePlaybookWithAI, setShowGeneratePlaybookWithAI] = useState(
    false,
  );

  const [showAddCustomMethodForm, setShowAddCustomMethodForm] = useState(false);
  const [showWarningBox, setShowWarningBox] = useState(false);

  const [generatingValueProp, setGeneratingValueProp] = useState(false);

  const {
    invitations,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.allInvitations);

  const [tags, setTags] = useState([]);

  const [placeholder, setPlaceholder] = useState('');
  const [promptRequest, setPromptRequest] = useState({
    description: '',
    methodName: '',
    responseType: 'string',
    promptSections: [
      {
        defaultText: '',
        optionalSection: false,
        placeHolders: {},
        text: '',
        type: 'sales_call_analysis_method',
      },
    ],
    promptType: 'custom_method_prompt',
    title: 'Prompt for custom method',
  });

  const [finalPromptRequest, setFinalPromptRequest] = useState(null);

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('select');
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedWlAddOns, setSelectedWlAddOns] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [fetchingAddons, setFetchingAddons] = useState(false);
  const [addons, setAddons] = useState([]);
  const [wlAddons, setWlAddons] = useState([]);
  const [planPrice, setPlanPrice] = useState(0);

  const [showUpdateFeatureMapping, setShowUpdateFeatureMapping] = useState(
    false,
  );

  const [features, setFeatures] = useState({
    'Sales Call Analysis': ['Analysis History', 'Start Analysis'],
    'Private Roleplays': ['All Roleplays', 'Start Roleplay'],
    'Clients Dashboard': ['Ask Donna'],
    'Account Settings': ['Ask Donna'],
  });

  const [featureMapping, setFeatureMapping] = useState({});

  const [planCategory, setPlanCategory] = useState('seat_based');
  const [planType, setPlanType] = useState('regular');
  const [trailPeriodDays, setTrailPeriodDays] = useState(14);
  const [roleplayVisibilityControl, setRoleplayVisibilityControl] = useState(
    true,
  );

  const [credits, setCredits] = useState(0);
  const [creditExpiryTimeUnit, setCreditExpiryTimeUnit] = useState('month');
  const [creditExpiryTimeValue, setCreditExpiryTimeValue] = useState(1);

  const [withContentScoringConfig, setWithContentScoringConfig] = useState(
    false,
  );
  const [
    showEditContentScoringConfig,
    setShowEditContentScoringConfig,
  ] = useState('');

  const [contentScoringConfig, setContentScoringConfig] = useState({
    inbound: null,
    outbound: null,
  });

  const [callType, setCallType] = useState('outbound');
  const [sameConfig, setSameConfig] = useState(true);

  const [criteriaName, setCriteriaName] = useState('');
  const [criteriaFactorName, setCriteriaFactorName] = useState('');

  const [configType, setConfigType] = useState('form');

  const [contentScoreConfigText, setContentScoreConfigText] = useState({
    inbound: '',
    outbound: '',
  });

  const [sheetLink, setSheetLink] = useState({
    inbound: '',
    outbound: '',
  });

  const [extractingConfig, setExtractingConfig] = useState(false);

  const [valueProposition, setValueProposition] = useState('');
  const [valuePropJsonError, setValuePropJsonError] = useState(false);

  const onImageChange = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError(true);
        alert('Please upload an image upto 2 MB');
      } else {
        setAppLogo(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      inputImage.current.value = null;
      setInvalidImageType(true);
      alert(
        'Please upload a valid image file.Supported formats are jpeg, png.',
      );
    }
  };

  const onImageChange1 = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setFaviconSizeError(true);
        alert('Please upload an image upto 2 MB');
      } else {
        setFavicon(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      inputImage1.current.value = null;
      setInvalidFaviconType(true);
      alert(
        'Please upload a valid image file.Supported formats are jpeg, png.',
      );
    }
  };

  const onImageChange2 = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setAppLogoWithTextSizeError(true);
        alert('Please upload an image upto 2 MB');
      } else {
        setAppLogoWithText(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      inputImage.current.value = null;
      setInvalidAppLogoWithTextType(true);
      alert(
        'Please upload a valid image file.Supported formats are jpeg, png.',
      );
    }
  };

  const initialValues = {
    email: '',
    companyName: '',
    websiteURL: '',
    aboutCompany: '',
    valueProp: null,
    sellingStyle: 'persuasive_or_traditional_selling',
  };

  const onSubmit = (values) => {
    setInvitingUser(true);
    dispatch(
      inviteUser(
        {
          aboutCompany: formik.values.aboutCompany,
          companyWebsite: formik.values.websiteURL,
          email: formik.values.email,
          organizationName: formik.values.companyName,
          // valueProp: JSON.stringify(formik.values.valueProp?.pov, null, 2),
          valueProp: valueProposition,
          sellingStyle: formik.values.sellingStyle,
          qaiAppVersion: 'QAI-V2',
        },
        () => {
          formik.resetForm({
            email: '',
            companyName: '',
            websiteURL: '',
            aboutCompany: '',
            valueProp: null,
            sellingStyle: 'persuasive_or_traditional_selling',
          });
          setInvitingUser(false);
          alert(' User invited');
        },
        () => {
          setInvitingUser(false);
          alert('Failed to invite user. Please try again!');
        },
      ),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleInvitationsListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        invitations &&
        invitations.length % 25 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllInvitations(25, lastSeen, () => {}));
    }
  };

  const handlePrefillFromGeneratedPlaybook = (structure) => {
    setTags(
      structure?.company?.split(':')?.[1]?.trim()
        ? [structure?.company?.split(':')?.[1]?.trim()]
        : [],
    );
    // console.log(structure);
    formik.setFieldValue('playbookStructure', {
      companyName: structure?.companyName || '',
      productDescription: structure?.productDescription || '',
      keyFeatures: structure?.productKeyFeatures || '',
      idealCustomerProfile: structure?.idealCustomerProfile || '',
      customerQualificationQuestions:
        structure?.customerQualificationQuestions || '',
      objectionsAndAnswers:
        structure?.customerQuestions && structure?.customerQuestions?.length > 0
          ? structure?.customerQuestions
          : [
              {
                question: '',
                answer: '',
              },
            ],
      challengesForCustomer:
        structure?.challengesForCustomer &&
        structure?.challengesForCustomer?.length > 0
          ? structure?.challengesForCustomer
          : [
              {
                question: '',
                answer: '',
              },
            ],
      expectedCallToAction: structure?.expectedCallToAction || '',
      competingProducts: structure?.competingProducts || '',
      awardsRatingAndReviews: structure?.awardsRatingAndReviews || '',
    });
    setShowGeneratePlaybookWithAI(false);
  };

  const extractConfig = () => {
    console.log(sheetLink);
    setExtractingConfig(true);
    dispatch(
      extractContentScoringConfig(
        sameConfig
          ? { sheetLink: sheetLink?.['outbound'] }
          : {
              inboundSheetLink: sheetLink?.['inbound'],
              outboundSheetLink: sheetLink?.['outbound'],
            },
        (data) => {
          console.log(data);
          setExtractingConfig(false);
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              outbound: sameConfig ? data?.config : data?.outbound,
              inbound: sameConfig ? data?.config : data?.inbound,
            };
          });
          setConfigType('form');
        },
        () => {
          setExtractingConfig(false);
          alert(
            'failed to extract config. please try again or fill out manually.',
          );
        },
      ),
    );
  };

  useEffect(() => {
    if (showEditContentScoringConfig === 'new') {
      dispatch(
        getContentScoringConfigTemplate((data) => {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              inbound: data?.callType?.inbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              inbound: JSON.stringify(data?.callType?.inbound, null, 2),
            };
          });
        }),
      );
      dispatch(
        getContentScoringConfigTemplate((data) => {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              outbound: data?.callType?.outbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              outbound: JSON.stringify(data?.callType?.outbound, null, 2),
            };
          });
        }),
      );
    }
  }, [showEditContentScoringConfig]);

  useEffect(() => {
    if (formik.values.withPlaybook) {
      formik.setFieldValue('playbookStructure', {
        companyName: '',
        productDescription: '',
        keyFeatures: '',
        idealCustomerProfile: '',
        customerQualificationQuestions: '',
        objectionsAndAnswers: [
          {
            question: '',
            answer: '',
          },
        ],
        challengesForCustomer: [
          {
            question: '',
            answer: '',
          },
        ],
        expectedCallToAction: '',
        competingProducts: '',
        awardsRatingAndReviews: '',
      });
    } else {
      formik.setFieldValue('playbookStructure', null);
    }
  }, [formik.values.withPlaybook]);

  useEffect(() => {
    if (formik.values.withProspects) {
      formik.setFieldValue('prospectDetails', {
        vertical: [],
        designation: [],
      });
    } else {
      formik.setFieldValue('prospectDetails', null);
    }
  }, [formik.values.withProspects]);

  useEffect(() => {
    if (currentTab === 'invitations') {
      dispatch(getAllInvitations(25, null, () => {}));
    }
  }, [currentTab]);

  useEffect(() => {
    if (
      selectedPlans &&
      selectedPlans.length > 0 &&
      plans &&
      plans.length > 0
    ) {
      setFetchingAddons(true);
      dispatch(
        getAddOns(
          formik.values.designation === 'whitelabel_user' ? true : false,
          selectedPlans,
          (addons) => {
            setWlAddons(addons);
            setFetchingAddons(false);
            // setSelectedWlAddOns(addons?.map((addon) => addon?.id));
          },
        ),
      );
    }
  }, [selectedPlans, formik.values.designation]);

  useEffect(() => {
    if (
      selectedPlan &&
      selectedPlan !== 'select' &&
      plans &&
      plans.length > 0
    ) {
      setPlanPrice(
        plans.find((plan) => plan?.id === selectedPlan)?.price?.amount || 0,
      );
      setTrailPeriodDays(
        plans.find((plan) => plan?.id === selectedPlan)?.trailPeriodDays || 14,
      );
      setFetchingAddons(true);
      dispatch(
        getAddOns(
          formik.values.designation === 'whitelabel_user' ? true : false,
          [selectedPlan],
          (addons) => {
            setAddons(addons);
            setFetchingAddons(false);
            // setSelectedWlAddOns(addons?.map((addon) => addon?.id));
          },
        ),
      );
      setCredits(
        plans.find((plan) => plan?.id === selectedPlan)?.price
          ?.seatsOrCredits || 0,
      );
    }
  }, [selectedPlan, plans, formik.values.designation]);

  useEffect(() => {
    if (
      selectedPlan &&
      selectedPlan !== 'select' &&
      plans &&
      plans.length > 0
    ) {
      if (planType !== 'trial') {
        setTrailPeriodDays(
          plans.find((plan) => plan?.id === selectedPlan)?.trailPeriodDays ||
            14,
        );
      } else {
        setTrailPeriodDays(14);
      }
    }
  }, [selectedPlan, plans, planType]);

  useEffect(() => {
    if (valueProposition) {
      try {
        formik.setFieldValue('valueProp', JSON.parse(valueProposition));
        setValuePropJsonError(false);
      } catch (e) {
        setValuePropJsonError(true);
        console.log(e);
      }
    }
  }, [valueProposition]);

  useEffect(() => {
    setFetchingPlans(true);
    dispatch(
      getPlans((plans) => {
        setPlans(plans);
        setFetchingPlans(false);
        setSelectedPlan(
          plans?.find((plan) => plan?.title === 'deal health test plan')?.id,
        );
        setSelectedPlans(
          plans?.filter((plan) => plan?.forWlOrg)?.map((plan) => plan?.id),
        );
      }),
    );
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setVerticalOptions(data?.verticals);
        setDesignationOptions(data?.designations);
      }),
    );
    setFeatureMapping(
      Object.keys(features || {})?.reduce((acc, curr) => {
        if (acc[curr] === undefined) {
          acc[curr] = {
            emptyText: '',
            loadingText: '',
            subTitles: features?.[curr]?.reduce((acc, curr) => {
              if (acc[curr] === undefined) {
                acc[curr] = {
                  title: '',
                };
              }
              return acc;
            }, {}),
            title: '',
          };
        }
        return acc;
      }, {}),
    );
  }, []);

  // console.log(planPrice);
  // console.log(plans);
  // console.log(contentScoringConfig);
  // console.log(contentScoreConfigText);
  console.log(
    formik.values?.whitelabelDomain
      ?.replace('https://', '')
      ?.replace('http://', '')
      ?.replace('www.', '')
      ?.replace('/', ''),
  );

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflowY: 'scroll',
          // padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Box
          sx={{
            // border: '1px solid #d3d3d3',
            borderRadius: 2,
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            aria-label="Invitations Tabs"
            orientation={'horizontal'}
            // indicatorColor={theme.palette.background.default}
            variant="scrollable"
            // scrollButtons={!isMd}
            allowScrollButtonsMobile
            sx={{
              borderBottom: '1px solid #d3d3d3',
            }}
          >
            <Tab
              value={'invite'}
              label={<Typography variant="body1">Invite User</Typography>}
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
              sx={{}}
            />
            <Tab
              value={'invitations'}
              label={<Typography variant="body1">Invitations</Typography>}
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
              sx={{}}
            />
          </Tabs>
          {currentTab === 'invite' ? (
            <Box>
              <Box display={'flex'} flexDirection={'row'} gap={2} p={2}>
                {/* <Box
                  flex={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                > */}
                {/* <Stepper activeStep={activeStep} orientation="vertical">
                    {['Details', 'Playbook', 'Prospects'].map((step, index) => (
                      <Step
                        key={step}
                        expanded={true}
                        onClick={() => setActiveStep(index)}
                      >
                        <StepButton>
                          <StepLabel
                            optional={
                              index === 2 ? (
                                <Typography variant="caption">
                                  Last step
                                </Typography>
                              ) : null
                            }
                          >
                            {step}
                          </StepLabel>
                        </StepButton>
                        <StepContent>
                          <Box sx={{ mb: 2 }}>
                            {index === 0
                              ? formik.errors &&
                                Object.keys(formik.errors || {}) &&
                                Array.isArray(
                                  Object.keys(formik.errors || {}),
                                ) &&
                                Object.keys(formik.errors || {})?.filter(
                                  (errName) =>
                                    errName !== 'playbookStructure' &&
                                    errName !== 'prospectDetails',
                                )?.length > 0
                                ? Object.keys(formik.errors || {})
                                    ?.filter(
                                      (errName) =>
                                        errName !== 'playbookStructure' &&
                                        errName !== 'prospectDetails',
                                    )
                                    .map((errorKey) => (
                                      <Typography
                                        key={errorKey}
                                        variant="subtitle2"
                                        color={'error'}
                                      >
                                        {index + 1}. {formik.errors[errorKey]}
                                      </Typography>
                                    ))
                                : null
                              : null}

                            {index === 1 ? (
                              (!tags || (tags && tags?.length === 0)) &&
                              formik.values.withPlaybook ? (
                                <Typography variant="subtitle2" color={'error'}>
                                  Please enter playbook title
                                </Typography>
                              ) : null
                            ) : null}
                            {index === 2 && activeStep === 2
                              ? formik.errors.prospectDetails &&
                                Object.keys(
                                  formik.errors.prospectDetails || {},
                                ) &&
                                Array.isArray(
                                  Object.keys(
                                    formik.errors.prospectDetails || {},
                                  ),
                                ) &&
                                Object.keys(formik.errors.prospectDetails || {})
                                  ?.length > 0
                                ? Object.keys(
                                    formik.errors.prospectDetails,
                                  )?.map((errorKey, index) => (
                                    <Typography
                                      key={errorKey}
                                      variant="subtitle2"
                                      color={'error'}
                                    >
                                      {index + 1}.{' '}
                                      {formik.errors.prospectDetails[errorKey]}
                                    </Typography>
                                  ))
                                : null
                              : null}
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper> */}
                {/* </Box> */}
                <Box
                  flex={1}
                  position={'relative'}
                  sx={{
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Box
                      sx={{
                        minHeight: 'calc(100vh - 160px)',
                        maxHeight: 'calc(100vh - 160px)',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        padding: 2,
                        paddingBottom: 8,
                      }}
                    >
                      {activeStep === 0 ? (
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          {/* <FormControl
                            component="fieldset"
                            variant="standard"
                            size="small"
                            error={!!formik.errors.designation}
                          >
                            <FormLabel component="legend">
                              <Typography
                                variant="subtitle2"
                                fontWeight={'bold'}
                              >
                                Customer Type
                              </Typography>
                            </FormLabel>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    checked={
                                      formik.values.designation ===
                                      'account_admin'
                                    }
                                    value={'account_admin'}
                                    name="designation"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Account Admin Customer
                                  </Typography>
                                }
                                onChange={formik.handleChange}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    checked={
                                      formik.values.designation ===
                                      'whitelabel_user'
                                    }
                                    value={'whitelabel_user'}
                                    name="designation"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Whitelabel Customer
                                  </Typography>
                                }
                                onChange={formik.handleChange}
                              />
                            </FormGroup>
                            {formik.errors.designation ? (
                              <FormHelperText>
                                {formik.errors.designation ?? ' '}
                              </FormHelperText>
                            ) : null}
                          </FormControl> */}
                          {/* <Box display={'flex'} gap={1}>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                              error={!!formik.errors.emailVerified}
                            >
                              <FormLabel component="legend">
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                >
                                  Email Verified
                                </Typography>
                              </FormLabel>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={formik.values.emailVerified}
                                      name="emailVerified"
                                    />
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Email Verified
                                    </Typography>
                                  }
                                  onChange={formik.handleChange}
                                />
                              </FormGroup>
                              {formik.errors.emailVerified ? (
                                <FormHelperText>
                                  {formik.errors.emailVerified ?? ' '}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                            <FormControl>
                              <FormLabel component="legend">
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                >
                                  Country
                                </Typography>
                              </FormLabel>
                              <Select
                                size="small"
                                labelId="country-select-label"
                                id="country-select"
                                name="country"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={formik.handleChange}
                                value={formik.values.country}
                              >
                                <MenuItem key={0} value="india">
                                  India
                                </MenuItem>
                                <MenuItem key={1} value="united states">
                                  United States
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                              // error={!!formik.errors.emailVerified}
                            >
                              <FormLabel component="legend">
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                >
                                  Roleplay visibility control
                                </Typography>
                              </FormLabel>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={roleplayVisibilityControl}
                                      name="roleplayVisibilityControl"
                                    />
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Roleplay visibility control
                                    </Typography>
                                  }
                                  onChange={(e) =>
                                    setRoleplayVisibilityControl(
                                      e.target.checked,
                                    )
                                  }
                                />
                              </FormGroup>
                            </FormControl>
                            {formik.values.designation === 'whitelabel_user' ? (
                              <Button
                                variant="outlined"
                                startIcon={<Settings />}
                                size="small"
                                sx={{
                                  padding: '4px 8px',
                                }}
                                onClick={() =>
                                  setShowUpdateFeatureMapping(true)
                                }
                              >
                                Configure Feature Mapping
                              </Button>
                            ) : null}
                          </Box> */}
                          {/* <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={withContentScoringConfig}
                                  name="withContentScoringConfig"
                                />
                              }
                              label={
                                <Typography variant="subtitle2">
                                  Call Scoring Configurations
                                </Typography>
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setShowEditContentScoringConfig('new');
                                } else {
                                  setShowEditContentScoringConfig('');
                                }
                                setWithContentScoringConfig(e.target.checked);
                              }}
                            />
                            {withContentScoringConfig ? (
                              <Button
                                startIcon={<Settings />}
                                onClick={() =>
                                  setShowEditContentScoringConfig('edit')
                                }
                              >
                                Edit Configurations
                              </Button>
                            ) : null}
                          </Box> */}
                          {/* {formik.values.designation === 'whitelabel_user' ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                // sx={{ marginBottom: 1 }}
                              >
                                Select Available Plans (WL Customers will be
                                using these to invite their customer)
                              </Typography>
                              <FormControl
                                sx={{
                                  width: '60%',
                                }}
                              >
                                <Select
                                  size="small"
                                  labelId="plan-select-label"
                                  id="plan-select"
                                  name="selectedPlans"
                                  multiple
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) => {
                                    setSelectedPlans(e.target.value);
                                  }}
                                  value={selectedPlans}
                                >
                                  {plans
                                    ?.filter((plan) => plan?.forWlOrg)
                                    ?.map((plan) => (
                                      <MenuItem key={plan?.id} value={plan?.id}>
                                        {capitalizeText(plan?.title)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              {wlAddons && wlAddons.length > 0 ? (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                    // sx={{ marginBottom: 1 }}
                                  >
                                    Select Available Addons (WL Customers will
                                    be using these to invite their customer)
                                  </Typography>
                                  <FormControl
                                    sx={{
                                      width: '60%',
                                    }}
                                  >
                                    <Select
                                      size="small"
                                      labelId="wl-addons-select-label"
                                      id="wl-addons-select"
                                      name="selectedWlAddOns"
                                      multiple
                                      MenuProps={{ disableScrollLock: true }}
                                      onChange={(e) => {
                                        setSelectedWlAddOns(e.target.value);
                                      }}
                                      value={selectedWlAddOns}
                                    >
                                      {wlAddons?.map((addon) => (
                                        <MenuItem
                                          key={addon?.id}
                                          value={addon?.id}
                                        >
                                          {capitalizeText(
                                            addon?.feature?.feature
                                              ?.split('_')
                                              ?.join(' '),
                                          )}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </>
                              ) : null}
                            </>
                          ) : null} */}
                          {/* <FormControl
                            component="fieldset"
                            variant="standard"
                            size="small"
                          >
                            <Typography
                              variant="subtitle2"
                              fontWeight={'bold'}
                              color={'text.secondary'}
                            >
                              Plan Category
                            </Typography>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    checked={planCategory === 'seat_based'}
                                    value={'seat_based'}
                                    name="planCategory"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Seat Based
                                  </Typography>
                                }
                                onChange={() => setPlanCategory('seat_based')}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    checked={planCategory === 'usage_based'}
                                    value={'usage_based'}
                                    name="planCategory"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Usage Based
                                  </Typography>
                                }
                                onChange={() => setPlanCategory('usage_based')}
                              />
                            </FormGroup>
                          </FormControl> */}
                          <Box display={'flex'} alignItems={'end'} gap={1}>
                            {/* <FormControl
                              sx={{
                                width: '50%',
                              }}
                            >
                              <FormLabel component="legend">
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  // sx={{ marginBottom: 1 }}
                                >
                                  Select Plan(This plan will be customer's
                                  default plan)
                                </Typography>
                              </FormLabel>
                              <Select
                                size="small"
                                labelId="plan-select-label"
                                id="plan-select"
                                name="selectedPlan"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  setSelectedPlan(e.target.value);
                                }}
                                value={selectedPlan}
                              >
                                <MenuItem key={0} value={'select'}>
                                  Select Plan
                                </MenuItem>
                                {plans
                                  ?.filter(
                                    (plan) =>
                                      !plan?.forWlOrg &&
                                      plan?.price?.pricingScheme ===
                                        planCategory,
                                  )
                                  ?.map((plan) => (
                                    <MenuItem key={plan?.id} value={plan?.id}>
                                      {capitalizeText(plan?.title)} Plan
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl> */}
                            {/* <FormControl
                              component="fieldset"
                              variant="standard"
                              size="small"
                            >
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Radio
                                      size="small"
                                      checked={planType === 'regular'}
                                      value={'regular'}
                                      name="planType"
                                    />
                                  }
                                  label={
                                    <Typography variant="subtitle2">
                                      Regular
                                    </Typography>
                                  }
                                  onChange={() => setPlanType('regular')}
                                />
                                <FormControlLabel
                                  control={
                                    <Radio
                                      size="small"
                                      checked={planType === 'trial'}
                                      value={'trial'}
                                      name="planType"
                                    />
                                  }
                                  label={
                                    <Typography variant="subtitle2">
                                      Trial Plan
                                    </Typography>
                                  }
                                  onChange={() => setPlanType('trial')}
                                />
                              </FormGroup>
                            </FormControl> */}
                            {/* {planType === 'trial' ? ( */}
                            {/* <TextField
                              label="Validity (Number of Days)"
                              variant="outlined"
                              name={'trailPeriodDays'}
                              size="small"
                              // fullWidth
                              // sx={{
                              //   width: '50%',
                              // }}
                              value={trailPeriodDays}
                              onChange={(e) =>
                                setTrailPeriodDays(Number(e.target.value))
                              }
                              type="number"
                            /> */}
                            {/* ) : null} */}
                          </Box>
                          {/* <Box display={'flex'} alignItems={'center'} gap={1}>
                            <TextField
                              label="Plan Price ($)*"
                              variant="outlined"
                              name={'planPrice'}
                              size="small"
                              // fullWidth
                              sx={{
                                width: '50%',
                              }}
                              value={planPrice}
                              onChange={(e) =>
                                setPlanPrice(Number(e.target.value))
                              }
                              type="number"
                            />
                            {planCategory === 'usage_based' ? (
                              <>
                                <TextField
                                  label="Plan Credits *"
                                  variant="outlined"
                                  name={'planCredits'}
                                  size="small"
                                  // fullWidth
                                  sx={{
                                    width: '50%',
                                  }}
                                  value={credits}
                                  onChange={(e) =>
                                    setCredits(Number(e.target.value))
                                  }
                                  type="number"
                                />
                                <TextField
                                  label="Credit Expiry Time *"
                                  variant="outlined"
                                  name={'creditExpiryTimeValue'}
                                  size="small"
                                  // fullWidth
                                  sx={{
                                    width: '50%',
                                  }}
                                  value={creditExpiryTimeValue}
                                  onChange={(e) =>
                                    setCreditExpiryTimeValue(
                                      Number(e.target.value),
                                    )
                                  }
                                  type="number"
                                />
                                <Select
                                  size="small"
                                  labelId="credit-expiry-time-unit-select-label"
                                  id="credit-expiry-time-unit-select"
                                  name="creditExpiryTimeUnit"
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) => {
                                    setCreditExpiryTimeUnit(e.target.value);
                                  }}
                                  value={creditExpiryTimeUnit}
                                >
                                  <MenuItem key={0} value={'select'}>
                                    Select Credit Expiry Time Unit
                                  </MenuItem>
                                  <MenuItem value={'month'}>Month</MenuItem>
                                  <MenuItem value={'hour'}>Hour</MenuItem>
                                  <MenuItem value={'min'}>Minute</MenuItem>
                                  <MenuItem value={'sec'}>Sec</MenuItem>
                                </Select>
                              </>
                            ) : null}
                          </Box> */}
                          {/* {addons && addons.length > 0 ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                // sx={{ marginBottom: 1 }}
                              >
                                Select Available Addons (This plan will be
                                customer's default addons)
                              </Typography>
                              <FormControl
                                sx={{
                                  width: '60%',
                                }}
                              >
                                <Select
                                  size="small"
                                  labelId="addon-select-label"
                                  id="addon-select"
                                  name="selectedAddOns"
                                  multiple
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) => {
                                    setSelectedAddOns(e.target.value);
                                  }}
                                  value={selectedAddOns}
                                >
                                  {addons?.map((addon) => (
                                    <MenuItem key={addon?.id} value={addon?.id}>
                                      {capitalizeText(
                                        addon?.feature?.feature
                                          ?.split('_')
                                          ?.join(' '),
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          ) : null} */}
                          <FormControl
                            sx={{
                              width: '50%',
                            }}
                          >
                            <FormLabel component="legend">
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                // sx={{ marginBottom: 1 }}
                              >
                                Select Plan(This plan will be customer's default
                                plan)
                              </Typography>
                            </FormLabel>
                            <Select
                              size="small"
                              labelId="plan-select-label"
                              id="plan-select"
                              name="selectedPlan"
                              MenuProps={{ disableScrollLock: true }}
                              onChange={(e) => {
                                setSelectedPlan(e.target.value);
                              }}
                              value={selectedPlan}
                            >
                              <MenuItem key={0} value={'select'}>
                                Select Plan
                              </MenuItem>
                              {plans
                                ?.filter(
                                  (plan) =>
                                    plan?.title === 'deal health test plan',
                                )
                                ?.map((plan) => (
                                  <MenuItem key={plan?.id} value={plan?.id}>
                                    {capitalizeText(plan?.title)} Plan
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Email *"
                            variant="outlined"
                            name={'email'}
                            size="small"
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                          <TextField
                            label="Company Name"
                            variant="outlined"
                            name={'companyName'}
                            size="small"
                            fullWidth
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.companyName &&
                              Boolean(formik.errors.companyName)
                            }
                            helperText={
                              formik.touched.companyName &&
                              formik.errors.companyName
                            }
                          />
                          <TextField
                            label="Website URL"
                            variant="outlined"
                            name={'websiteURL'}
                            size="small"
                            fullWidth
                            value={formik.values.websiteURL}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.websiteURL &&
                              Boolean(formik.errors.websiteURL)
                            }
                            helperText={
                              formik.touched.websiteURL &&
                              formik.errors.websiteURL
                            }
                          />
                          <TextField
                            label="About Company"
                            variant="outlined"
                            name={'aboutCompany'}
                            size="small"
                            fullWidth
                            multiline
                            rows={4}
                            value={formik.values.aboutCompany}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.aboutCompany &&
                              Boolean(formik.errors.aboutCompany)
                            }
                            helperText={
                              formik.touched.aboutCompany &&
                              formik.errors.aboutCompany
                            }
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{ padding: '4px 8px' }}
                            disabled={generatingValueProp}
                            startIcon={
                              generatingValueProp ? (
                                <CircularProgress size={20} />
                              ) : null
                            }
                            onClick={(e) => {
                              setGeneratingValueProp(true);
                              dispatch(
                                generateValueProp(
                                  {
                                    url: formik.values.websiteURL,
                                    aboutCompany: formik.values.aboutCompany,
                                    model: 'gpt-4o-mini-2024-07-18',
                                  },
                                  (data) => {
                                    setGeneratingValueProp(false);
                                    setValueProposition(
                                      data?.valuePropHtml || '',
                                    );
                                  },
                                ),
                              );
                            }}
                          >
                            Generate Value Proposition
                          </Button>
                          <RichTextEditor
                            value={valueProposition || ''}
                            onChange={setValueProposition}
                            placeholder="Enter Value Proposition"
                            height="300px"
                          />
                          {valuePropJsonError ? (
                            <Typography color={theme.palette.error.main}>
                              Invalid JSON
                            </Typography>
                          ) : null}
                          <FormControl
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: 2 }}
                          >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Selling Style
                            </FormLabel>
                            <Select
                              // ref={promptSelectRef}
                              labelId="prompt-select-label"
                              // label="Select Prompt"
                              name={'sellingStyle'}
                              value={formik.values?.sellingStyle || ''}
                              onChange={formik.handleChange}
                            >
                              {sellingStyles?.map((ss) => (
                                <MenuItem value={ss?.value}>
                                  {ss?.style}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* {formik.values.designation === 'whitelabel_user' ? (
                            <>
                              <TextField
                                label="Whitelabel Domain(e.g. app.qualification.ai)"
                                variant="outlined"
                                name={'whitelabelDomain'}
                                size="small"
                                fullWidth
                                // multiline
                                // rows={4}
                                value={formik.values.whitelabelDomain}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.whitelabelDomain &&
                                  Boolean(formik.errors.whitelabelDomain)
                                }
                                helperText={
                                  formik.touched.whitelabelDomain &&
                                  formik.errors.whitelabelDomain
                                }
                              />
                              <TextField
                                label="Comapny Landing Page Website(e.g. qualification.ai)"
                                variant="outlined"
                                name={'organizationWebsite'}
                                size="small"
                                fullWidth
                                value={formik.values.organizationWebsite}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.organizationWebsite &&
                                  Boolean(formik.errors.organizationWebsite)
                                }
                                helperText={
                                  formik.touched.organizationWebsite &&
                                  formik.errors.organizationWebsite
                                }
                              />
                              <TextField
                                label="Public Facing App Name"
                                variant="outlined"
                                name={'appTitle'}
                                size="small"
                                fullWidth
                                value={formik.values.appTitle}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.appTitle &&
                                  Boolean(formik.errors.appTitle)
                                }
                                helperText={
                                  formik.touched.appTitle &&
                                  formik.errors.appTitle
                                }
                              />
                              <TextField
                                label="Secondary Text(Visible along with App logo)"
                                variant="outlined"
                                name={'appSubTitle'}
                                size="small"
                                fullWidth
                                value={formik.values.appSubTitle}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.appSubTitle &&
                                  Boolean(formik.errors.appSubTitle)
                                }
                                helperText={
                                  formik.touched.appSubTitle &&
                                  formik.errors.appSubTitle
                                }
                              />
                            </>
                          ) : null}
                          <Box display={'flex'} flexDirection={'row'} gap={2}>
                            <Box
                              flex={1}
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                            >
                              {formik.values.designation ===
                              'whitelabel_user' ? (
                                <>
                                  <Box display={'flex'} gap={1}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={formik.values.textInLogo}
                                          name="textInLogo"
                                        />
                                      }
                                      label={
                                        <Typography variant="caption">
                                          Include public facing app name and
                                          secondary text in logo
                                        </Typography>
                                      }
                                      onChange={formik.handleChange}
                                    />
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    alignItems={'end'}
                                    gap={2}
                                  >
                                    <Box
                                      position={'relative'}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant="caption"
                                        color={'text.secondary'}
                                      >
                                        Logo without text
                                      </Typography>
                                      <Avatar
                                        variant="square"
                                        alt={'Logo without text'}
                                        src={
                                          appLogo
                                            ? URL.createObjectURL(appLogo)
                                            : ''
                                        }
                                        sx={{
                                          width: '100%',
                                          height: 'auto',
                                          background: '#fff',
                                          border: '1px solid #d3d3d3',
                                          boxShadow: '0 0 5px #d3d3d3',
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight={'bold'}
                                          color={'text.secondary'}
                                        >
                                          {'Logo without text'}
                                        </Typography>
                                      </Avatar>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          sx={{
                                            padding: '4px 8px',
                                          }}
                                          onClick={() => {
                                            setAppLogo(null);
                                            inputImage.current.value = '';
                                          }}
                                        >
                                          Remove
                                        </Button>
                                        <InputLabel
                                          htmlFor="profileImg"
                                          sx={{
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: 1,
                                            padding: '4px 8px',
                                            cursor: 'pointer',
                                            // width: 1,
                                          }}
                                        >
                                          <CloudUpload
                                            sx={{
                                              fontSize: 14,
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                          <Typography
                                            variant="caption"
                                            color={theme.palette.primary.main}
                                          >
                                            {appLogo ? 'Change' : 'Select'}
                                          </Typography>
                                        </InputLabel>
                                      </Box>
                                      <input
                                        style={{ display: 'none' }}
                                        id="profileImg"
                                        ref={inputImage}
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange}
                                      />
                                    </Box>
                                    {!formik.values.textInLogo ? (
                                      <Box
                                        position={'relative'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Typography
                                          variant="caption"
                                          color={'text.secondary'}
                                        >
                                          Logo with text
                                        </Typography>
                                        <Avatar
                                          variant="square"
                                          alt={'Logo with text'}
                                          src={
                                            appLogoWithText
                                              ? URL.createObjectURL(
                                                  appLogoWithText,
                                                )
                                              : ''
                                          }
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Logo with text'}
                                          </Typography>
                                        </Avatar>
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={2}
                                        >
                                          <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                              padding: '4px 8px',
                                            }}
                                            onClick={() => {
                                              setAppLogoWithText(null);
                                              inputImage2.current.value = '';
                                            }}
                                          >
                                            Remove
                                          </Button>
                                          <InputLabel
                                            htmlFor="profileImg2"
                                            sx={{
                                              zIndex: 1,
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              gap: 0.5,
                                              border: `1px solid ${theme.palette.primary.main}`,
                                              borderRadius: 1,
                                              padding: '4px 8px',
                                              cursor: 'pointer',
                                              // width: 1,
                                            }}
                                          >
                                            <CloudUpload
                                              sx={{
                                                fontSize: 14,
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                            />
                                            <Typography
                                              variant="caption"
                                              color={theme.palette.primary.main}
                                            >
                                              {appLogoWithText
                                                ? 'Change'
                                                : 'Select'}
                                            </Typography>
                                          </InputLabel>
                                        </Box>
                                        <input
                                          style={{ display: 'none' }}
                                          id="profileImg2"
                                          ref={inputImage2}
                                          type="file"
                                          accept="image/*"
                                          onChange={onImageChange2}
                                        />
                                      </Box>
                                    ) : null}
                                    <Box
                                      position={'relative'}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant="caption"
                                        color={'text.secondary'}
                                      >
                                        Favicon
                                      </Typography>
                                      <Avatar
                                        variant="square"
                                        alt={'Favicon'}
                                        src={
                                          favicon
                                            ? URL.createObjectURL(favicon)
                                            : ''
                                        }
                                        sx={{
                                          width: '100%',
                                          height: 'auto',
                                          background: '#fff',
                                          border: '1px solid #d3d3d3',
                                          boxShadow: '0 0 5px #d3d3d3',
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight={'bold'}
                                          color={'text.secondary'}
                                        >
                                          {'Favicon'}
                                        </Typography>
                                      </Avatar>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          sx={{
                                            padding: '4px 8px',
                                          }}
                                          onClick={() => {
                                            setFavicon(null);
                                            inputImage1.current.value = '';
                                          }}
                                        >
                                          Remove
                                        </Button>
                                        <InputLabel
                                          htmlFor="profileImg1"
                                          sx={{
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: 1,
                                            padding: '4px 8px',
                                            cursor: 'pointer',
                                            // width: 1,
                                          }}
                                        >
                                          <CloudUpload
                                            sx={{
                                              fontSize: 14,
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                          <Typography
                                            variant="caption"
                                            color={theme.palette.primary.main}
                                          >
                                            {favicon ? 'Change' : 'Select'}
                                          </Typography>
                                        </InputLabel>
                                      </Box>
                                      <input
                                        style={{ display: 'none' }}
                                        id="profileImg1"
                                        ref={inputImage1}
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange1}
                                      />
                                    </Box>
                                  </Box>
                                </>
                              ) : null}
                            </Box>
                            {formik.values.designation === 'whitelabel_user' ? (
                              <Box
                                flex={1}
                                display={'flex'}
                                flexDirection={'column'}
                                // justifyContent={'center'}
                                // alignItems={'center'}
                                gap={1}
                                border={'1px solid #d3d3d3'}
                                // p={1}
                                position={'relative'}
                              >
                                <Typography
                                  sx={{
                                    background: '#d3d3d3',
                                    padding: 1,
                                  }}
                                  variant="subtitle2"
                                  fontWeight={'bold'}
                                  color={'text.secondary'}
                                  textAlign={'center'}
                                >
                                  Logo Preview
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    alignItems: 'center',
                                    padding: 1,
                                  }}
                                >
                                  {formik.values.textInLogo ? (
                                    <Box
                                      display={'flex'}
                                      flex={2}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={'text.secondary'}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                      >
                                        Logo with text
                                      </Typography>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                      >
                                        <Box
                                          display={'flex'}
                                          component="a"
                                          title={
                                            formik.values?.appTitle ||
                                            'Qualification AI'
                                          }
                                          width={{ xs: 50, md: 50 }}
                                          height={{ xs: 50, md: 50 }}
                                        >
                                          {appLogo ? (
                                            <Box
                                              component={'img'}
                                              src={
                                                theme.palette.mode === 'dark'
                                                  ? '/dark_theme_logo.svg'
                                                  : appLogo
                                                  ? URL.createObjectURL(appLogo)
                                                  : '/light_theme_logo.svg'
                                              }
                                              height={1}
                                              width={1}
                                            />
                                          ) : (
                                            <Box
                                              component={'div'}
                                              height={1}
                                              width={1}
                                              display={'flex'}
                                              justifyContent={'center'}
                                              alignItems={'center'}
                                              sx={{
                                                background: '#d3d3d3',
                                                borderRadius: '4px',
                                                padding: 1,
                                              }}
                                            >
                                              <Typography
                                                variant="caption"
                                                fontWeight={'bold'}
                                                color={'text.secoondary'}
                                                textAlign={'center'}
                                              >
                                                App Logo
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                        <Box ml={2}>
                                          <Typography
                                            color={
                                              formik.values?.appTitle
                                                ? 'text.primary'
                                                : 'text.secondary'
                                            }
                                            variant={
                                              formik.values?.appTitle
                                                ? 'h6'
                                                : 'body1'
                                            }
                                            fontWeight={'600'}
                                          >
                                            {formik.values?.appTitle ||
                                              'Public Facing App Name'}
                                          </Typography>
                                          <Divider
                                            sx={{
                                              border: `0.5px solid ${theme.palette.text.primary}`,
                                            }}
                                          />
                                          <Typography
                                            variant="caption"
                                            color="text.primary"
                                          >
                                            {formik.values?.appSubTitle ||
                                              'App Secondary Text or Tagline'}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      display={'flex'}
                                      flex={2}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={'text.secondary'}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                      >
                                        Logo with text
                                      </Typography>
                                      <Box
                                        display={'flex'}
                                        component="a"
                                        // href={
                                        //   `https://${formik.values.organizationWebsite}` ||
                                        //   'https://qualification.ai'
                                        // }
                                        // title={
                                        //   formik.values?.appTitle ||
                                        //   'Qualification AI'
                                        // }
                                        height={{ xs: 50, md: 50 }}
                                        // width={{ xs: 50, md: 50 }}
                                      >
                                        {appLogoWithText ? (
                                          <Box
                                            component={'img'}
                                            src={
                                              theme.palette.mode === 'dark'
                                                ? '/dark_theme_logo.svg'
                                                : appLogoWithText
                                                ? URL.createObjectURL(
                                                    appLogoWithText,
                                                  )
                                                : '/light_theme_logo.svg'
                                            }
                                            height={1}
                                            width={1}
                                          />
                                        ) : (
                                          <Box
                                            component={'div'}
                                            height={1}
                                            width={1}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            sx={{
                                              background: '#d3d3d3',
                                              borderRadius: '4px',
                                              padding: 1,
                                            }}
                                          >
                                            <Typography
                                              variant="caption"
                                              fontWeight={'bold'}
                                              color={'text.secoondary'}
                                              textAlign={'center'}
                                            >
                                              App Logo with Text
                                            </Typography>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    display={'flex'}
                                    flex={1}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.secondary'}
                                      fontWeight={'bold'}
                                      textAlign={'center'}
                                    >
                                      Without text
                                    </Typography>
                                    <Box
                                      display={'flex'}
                                      component="a"
                                      // href={
                                      //   `https://${formik.values.organizationWebsite}` ||
                                      //   'https://qualification.ai'
                                      // }
                                      // title={
                                      //   formik.values?.appTitle ||
                                      //   'Qualification AI'
                                      // }
                                      height={{ xs: 50, md: 50 }}
                                      width={{ xs: 50, md: 50 }}
                                    >
                                      {appLogo ? (
                                        <Box
                                          component={'img'}
                                          src={
                                            theme.palette.mode === 'dark'
                                              ? '/dark_theme_logo.svg'
                                              : appLogo
                                              ? URL.createObjectURL(appLogo)
                                              : '/light_theme_logo.svg'
                                          }
                                          height={1}
                                          width={1}
                                        />
                                      ) : (
                                        <Box
                                          component={'div'}
                                          height={1}
                                          width={1}
                                          display={'flex'}
                                          justifyContent={'center'}
                                          alignItems={'center'}
                                          sx={{
                                            background: '#d3d3d3',
                                            borderRadius: '4px',
                                            padding: 1,
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secoondary'}
                                            textAlign={'center'}
                                          >
                                            App Logo
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                          <Typography
                            variant={'subtitle2'}
                            // sx={{  }}
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            <span>
                              Pick all the sales methodologies that the sales
                              team is expected to follow.
                            </span>
                          </Typography>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup row>
                              <FormControlLabel
                                checked={formik.values.methodologies?.includes(
                                  'meddpic',
                                )}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={'meddpic'}
                                    name="methods"
                                  />
                                }
                                label="MEDDICC"
                                onChange={(e) => {
                                  if (
                                    formik.values.methodologies?.includes(
                                      'meddpic',
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'methodologies',
                                      formik.values.methodologies.filter(
                                        (mthd) => mthd !== e.target.value,
                                      ),
                                    );
                                  } else {
                                    formik.setFieldValue('methodologies', [
                                      ...formik.values.methodologies,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                              />
                              <FormControlLabel
                                checked={formik.values.methodologies?.includes(
                                  'spin',
                                )}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={'spin'}
                                    name="methods"
                                  />
                                }
                                label="SPIN"
                                onChange={(e) => {
                                  if (
                                    formik.values.methodologies?.includes(
                                      'spin',
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'methodologies',
                                      formik.values.methodologies.filter(
                                        (mthd) => mthd !== e.target.value,
                                      ),
                                    );
                                  } else {
                                    formik.setFieldValue('methodologies', [
                                      ...formik.values.methodologies,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                              />
                              <FormControlLabel
                                checked={formik.values.methodologies?.includes(
                                  'spiced',
                                )}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={'spiced'}
                                    name="methods"
                                  />
                                }
                                label="SPICED"
                                onChange={(e) => {
                                  if (
                                    formik.values.methodologies?.includes(
                                      'spiced',
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'methodologies',
                                      formik.values.methodologies.filter(
                                        (mthd) => mthd !== e.target.value,
                                      ),
                                    );
                                  } else {
                                    formik.setFieldValue('methodologies', [
                                      ...formik.values.methodologies,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                              />
                              <FormControlLabel
                                checked={formik.values.methodologies?.includes(
                                  'bant',
                                )}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={'bant'}
                                    name="methods"
                                  />
                                }
                                label="BANT"
                                onChange={(e) => {
                                  if (
                                    formik.values.methodologies?.includes(
                                      'bant',
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'methodologies',
                                      formik.values.methodologies.filter(
                                        (mthd) => mthd !== e.target.value,
                                      ),
                                    );
                                  } else {
                                    formik.setFieldValue('methodologies', [
                                      ...formik.values.methodologies,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                              />
                              <FormControlLabel
                                checked={formik.values.methodologies?.includes(
                                  'challenger',
                                )}
                                control={
                                  <Checkbox
                                    size="small"
                                    value={'challenger'}
                                    name="methods"
                                  />
                                }
                                label="CHALLENGER"
                                onChange={(e) => {
                                  if (
                                    formik.values.methodologies?.includes(
                                      'challenger',
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      'methodologies',
                                      formik.values.methodologies.filter(
                                        (mthd) => mthd !== e.target.value,
                                      ),
                                    );
                                  } else {
                                    formik.setFieldValue('methodologies', [
                                      ...formik.values.methodologies,
                                      e.target.value,
                                    ]);
                                  }
                                }}
                              />
                            </FormGroup>
                          </FormControl>
                          {formik.values.designation === 'whitelabel_user' ? (
                            <>
                              <Typography
                                variant={'subtitle2'}
                                color={'text.secondary'}
                                fontWeight={'bold'}
                              >
                                Custom Methodology
                              </Typography>
                              {finalPromptRequest ? (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={2}
                                >
                                  <FormControl
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup row>
                                      <FormControlLabel
                                        checked={true}
                                        control={
                                          <Checkbox
                                            size="small"
                                            value={true}
                                            name="customMethod"
                                          />
                                        }
                                        label={finalPromptRequest?.methodName?.toUpperCase()}
                                        onChange={(e) => {}}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                  <Button
                                    variant="text"
                                    onClick={() => setFinalPromptRequest(null)}
                                    size="small"
                                    sx={{ padding: '4px 8px' }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              ) : (
                                <Button
                                  variant="outlined"
                                  startIcon={<Add />}
                                  size="small"
                                  sx={{
                                    padding: '4px 8px',
                                  }}
                                  onClick={() =>
                                    setShowAddCustomMethodForm(true)
                                  }
                                >
                                  Add custom methodologies
                                </Button>
                              )}
                            </>
                          ) : null} */}
                        </Box>
                      ) : activeStep === 1 ? (
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={formik.values.withPlaybook}
                                  name="withPlaybook"
                                />
                              }
                              label={
                                <Typography variant="subtitle2">
                                  With Playbook
                                </Typography>
                              }
                              onChange={formik.handleChange}
                            />
                            {formik.values.withPlaybook ? (
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  setShowGeneratePlaybookWithAI(true)
                                }
                              >
                                Generate playbook with AI
                              </Button>
                            ) : null}
                          </Box>
                          {formik.values.withPlaybook ? (
                            <>
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  fontWeight={'bold'}
                                >
                                  Playbook Title
                                </Typography>
                              </Box>
                              <AutoCompleteMUI
                                id="tags"
                                value={tags}
                                onChange={(event, newValue, reason) => {
                                  setTags([...(newValue || [])]);
                                  // console.log(reason);
                                }}
                                options={[]}
                                onTextChange={(e) => {
                                  if (e.target.value?.trim()) {
                                    setTags([e.target.value]);
                                  } else {
                                    setTags([]);
                                  }
                                }}
                                onKeyDown={() => {}}
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                About your company
                              </Typography>
                              <TextField
                                placeholder="Enter about your company"
                                variant="outlined"
                                name={'playbookStructure.companyName'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure?.companyName
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.companyName &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.companyName,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.companyName &&
                                  formik.errors.playbookStructure?.companyName
                                }
                                type={'text'}
                              />
                              <Typography variant={'subtitle2'} sx={{}}>
                                <span style={{ fontWeight: 'bold' }}>
                                  Product Description
                                </span>
                                <br />
                                (Please describe your product in detail. Please
                                also add URLs to specific webpages and shared
                                documents if any.)
                              </Typography>
                              <TextField
                                placeholder="Enter product description"
                                variant="outlined"
                                name={'playbookStructure.productDescription'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.productDescription
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.productDescription &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.productDescription,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.productDescription &&
                                  formik.errors.playbookStructure
                                    ?.productDescription
                                }
                                type="text"
                              />
                              <Typography variant={'subtitle2'} sx={{}}>
                                <span style={{ fontWeight: 'bold' }}>
                                  Product Key Features
                                </span>
                                <br />
                                (Highlight USPs)
                              </Typography>
                              <TextField
                                placeholder="Enter product key features"
                                variant="outlined"
                                name={'playbookStructure.keyFeatures'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure?.keyFeatures
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.keyFeatures &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.keyFeatures,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.keyFeatures &&
                                  formik.errors.playbookStructure?.keyFeatures
                                }
                                type="text"
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                Describe your Ideal Customer Profile
                              </Typography>
                              <TextField
                                placeholder="Describe your ideal customer profile"
                                variant="outlined"
                                name={'playbookStructure.idealCustomerProfile'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.idealCustomerProfile
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.idealCustomerProfile &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.idealCustomerProfile,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.idealCustomerProfile &&
                                  formik.errors.playbookStructure
                                    ?.idealCustomerProfile
                                }
                                type="text"
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Customer Qualification Questions:{' '}
                                </span>
                                (1 or more questions that you use to qualify
                                your customers)
                              </Typography>
                              <TextField
                                placeholder="Customer Qualification Questions:"
                                variant="outlined"
                                name={
                                  'playbookStructure.customerQualificationQuestions'
                                }
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.customerQualificationQuestions
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.customerQualificationQuestions &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.customerQualificationQuestions,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.customerQualificationQuestions &&
                                  formik.errors.playbookStructure
                                    ?.customerQualificationQuestions
                                }
                                type="text"
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Top customer challenges and recommended
                                  responses
                                </span>
                              </Typography>
                              {formik.values.playbookStructure
                                ?.challengesForCustomer &&
                                Array.isArray(
                                  formik.values.playbookStructure
                                    ?.challengesForCustomer,
                                ) &&
                                formik.values.playbookStructure
                                  ?.challengesForCustomer.length > 0 &&
                                formik.values.playbookStructure?.challengesForCustomer?.map(
                                  (objAndAns, faqIndex) => {
                                    return (
                                      <Box
                                        key={faqIndex}
                                        sx={{
                                          border: '1px solid #d3d3d3',
                                          borderRadius: 2,
                                          padding: 2,
                                          marginTop: 1,
                                          position: 'relative',
                                        }}
                                      >
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 10,
                                            zIndex: 2,
                                            background: '#fff',
                                            '&:hover': {
                                              background: '#fff',
                                            },
                                          }}
                                          onClick={() => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.challengesForCustomer.filter(
                                              (objAndAns, i) => i !== faqIndex,
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                challengesForCustomer: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                        >
                                          Remove
                                        </Button>
                                        <TextField
                                          label={`Challenge ${faqIndex + 1}`}
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          value={objAndAns?.question}
                                          sx={{ marginTop: 1 }}
                                          onChange={(e) => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.challengesForCustomer.map(
                                              (objAndAns, i) => {
                                                if (i === faqIndex) {
                                                  return {
                                                    ...objAndAns,
                                                    question: e.target.value,
                                                  };
                                                } else {
                                                  return objAndAns;
                                                }
                                              },
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                challengesForCustomer: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                        />
                                        <TextField
                                          size="small"
                                          label={`Recommended Response`}
                                          variant="outlined"
                                          fullWidth
                                          value={objAndAns?.answer}
                                          onChange={(e) => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.challengesForCustomer.map(
                                              (objAndAns, i) => {
                                                if (i === faqIndex) {
                                                  return {
                                                    ...objAndAns,
                                                    answer: e.target.value,
                                                  };
                                                } else {
                                                  return objAndAns;
                                                }
                                              },
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                challengesForCustomer: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                          sx={{ marginTop: 1 }}
                                          // error={formik.touched.playbookStructure?.email && Boolean(formik.errors.playbookStructure?.email)}
                                          // helperText={formik.touched.playbookStructure?.email && formik.errors.playbookStructure?.email}
                                        />
                                      </Box>
                                    );
                                  },
                                )}
                              <Button
                                onClick={() => {
                                  formik.setFieldValue('playbookStructure', {
                                    ...(formik.values.playbookStructure || {}),
                                    challengesForCustomer: [
                                      ...(formik.values.playbookStructure
                                        ?.challengesForCustomer || []),
                                      {
                                        question: '',
                                        answer: '',
                                      },
                                    ],
                                  });
                                }}
                                sx={{ marginTop: 1 }}
                                variant="contained"
                              >
                                Add Challenge and Recommended Response
                              </Button>
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Typical customer objections and their answers
                                </span>
                              </Typography>
                              {formik.values.playbookStructure
                                ?.objectionsAndAnswers &&
                                Array.isArray(
                                  formik.values.playbookStructure
                                    ?.objectionsAndAnswers,
                                ) &&
                                formik.values.playbookStructure
                                  ?.objectionsAndAnswers.length > 0 &&
                                formik.values.playbookStructure?.objectionsAndAnswers?.map(
                                  (objAndAns, faqIndex) => {
                                    return (
                                      <Box
                                        key={faqIndex}
                                        sx={{
                                          border: '1px solid #d3d3d3',
                                          borderRadius: 2,
                                          padding: 2,
                                          marginTop: 1,
                                          position: 'relative',
                                        }}
                                      >
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 10,
                                            zIndex: 2,
                                            background: '#fff',
                                            '&:hover': {
                                              background: '#fff',
                                            },
                                          }}
                                          onClick={() => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.objectionsAndAnswers.filter(
                                              (objAndAns, i) => i !== faqIndex,
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                objectionsAndAnswers: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                        >
                                          Remove
                                        </Button>
                                        <TextField
                                          label={`Objection ${faqIndex + 1}`}
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          value={objAndAns?.question}
                                          sx={{ marginTop: 1 }}
                                          onChange={(e) => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.objectionsAndAnswers.map(
                                              (objAndAns, i) => {
                                                if (i === faqIndex) {
                                                  return {
                                                    ...objAndAns,
                                                    question: e.target.value,
                                                  };
                                                } else {
                                                  return objAndAns;
                                                }
                                              },
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                objectionsAndAnswers: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                        />
                                        <TextField
                                          size="small"
                                          label={`Answer`}
                                          variant="outlined"
                                          fullWidth
                                          value={objAndAns?.answer}
                                          onChange={(e) => {
                                            const updatedObjAndAns = formik.values.playbookStructure?.objectionsAndAnswers.map(
                                              (objAndAns, i) => {
                                                if (i === faqIndex) {
                                                  return {
                                                    ...objAndAns,
                                                    answer: e.target.value,
                                                  };
                                                } else {
                                                  return objAndAns;
                                                }
                                              },
                                            );
                                            formik.setFieldValue(
                                              'playbookStructure',
                                              {
                                                ...(formik.values
                                                  .playbookStructure || {}),
                                                objectionsAndAnswers: updatedObjAndAns,
                                              },
                                            );
                                          }}
                                          sx={{ marginTop: 1 }}
                                          // error={formik.touched.playbookStructure?.email && Boolean(formik.errors.playbookStructure?.email)}
                                          // helperText={formik.touched.playbookStructure?.email && formik.errors.playbookStructure?.email}
                                        />
                                      </Box>
                                    );
                                  },
                                )}
                              <Button
                                onClick={() => {
                                  formik.setFieldValue('playbookStructure', {
                                    ...(formik.values.playbookStructure || {}),
                                    objectionsAndAnswers: [
                                      ...(formik.values.playbookStructure
                                        ?.objectionsAndAnswers || []),
                                      {
                                        question: '',
                                        answer: '',
                                      },
                                    ],
                                  });
                                }}
                                sx={{ marginTop: 1 }}
                                variant="contained"
                              >
                                Add Objection and Answer
                              </Button>
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Expected Call to Action
                                </span>
                                [e.g. Close the deal, Book a demo, schedule next
                                meeting, etc]
                              </Typography>
                              <TextField
                                placeholder="Enter expected call to action"
                                variant="outlined"
                                name={'playbookStructure.expectedCallToAction'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.expectedCallToAction
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.expectedCallToAction &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.expectedCallToAction,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.expectedCallToAction &&
                                  formik.errors.playbookStructure
                                    ?.expectedCallToAction
                                }
                                type="text"
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Competing Products and Companies
                                </span>
                                [comma separated product or company names]
                              </Typography>
                              <TextField
                                placeholder="Competing Products and Companies"
                                variant="outlined"
                                name={'playbookStructure.competingProducts'}
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.competingProducts
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.competingProducts &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.competingProducts,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.competingProducts &&
                                  formik.errors.playbookStructure
                                    ?.competingProducts
                                }
                                type="text"
                              />
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Highlights of Awards, Ratings, and Customer
                                  Reviews
                                </span>
                              </Typography>
                              <TextField
                                placeholder="Highlights of Awards, Ratings, and Customer Reviews"
                                variant="outlined"
                                name={
                                  'playbookStructure.awardsRatingAndReviews'
                                }
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                value={
                                  formik.values.playbookStructure
                                    ?.awardsRatingAndReviews
                                }
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.playbookStructure
                                    ?.awardsRatingAndReviews &&
                                  Boolean(
                                    formik.errors.playbookStructure
                                      ?.awardsRatingAndReviews,
                                  )
                                }
                                helperText={
                                  formik.touched.playbookStructure
                                    ?.awardsRatingAndReviews &&
                                  formik.errors.playbookStructure
                                    ?.awardsRatingAndReviews
                                }
                                type="text"
                              />
                            </>
                          ) : null}
                        </Box>
                      ) : activeStep === 2 ? (
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={formik.values.withProspects}
                                name="withProspects"
                              />
                            }
                            label={
                              <Typography variant="subtitle2">
                                With Prospects
                              </Typography>
                            }
                            onChange={formik.handleChange}
                          />
                          {formik.values.withProspects ? (
                            <>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                alignItems={'start'}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    Prospect Verticals
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    If you sell to specific verticals, select
                                    them from the list. You can also add your
                                    own.
                                  </Typography>
                                </Box>
                                <AutoCompleteMUI
                                  id="vertical"
                                  multiple
                                  value={
                                    formik.values.prospectDetails?.vertical ||
                                    []
                                  }
                                  onKeyDown={(event) => {
                                    if (event.target.value.trim()) {
                                      formik.setFieldValue('prospectDetails', {
                                        ...(formik.values.prospectDetails ||
                                          {}),
                                        vertical: formik.values.prospectDetails
                                          ?.vertical
                                          ? [
                                              ...(formik.values.prospectDetails
                                                ?.vertical || []),
                                              event.target.value,
                                            ]
                                          : [event.target.value],
                                      });
                                    }
                                  }}
                                  onChange={(event, newValue, reason) => {
                                    formik.setFieldValue('prospectDetails', {
                                      ...(formik.values.prospectDetails || {}),
                                      vertical: [...newValue],
                                    });
                                  }}
                                  options={verticalOptions}
                                />
                              </Box>
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                alignItems={'start'}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    Prospect Designations
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    If you sell to specific designation, select
                                    them from the list. You can also add your
                                    own.
                                  </Typography>
                                </Box>
                                <AutoCompleteMUI
                                  id="designation"
                                  multiple
                                  value={
                                    formik.values.prospectDetails
                                      ?.designation || []
                                  }
                                  onKeyDown={(event) => {
                                    if (event.target.value.trim()) {
                                      formik.setFieldValue('prospectDetails', {
                                        ...(formik.values.prospectDetails ||
                                          {}),
                                        designation: formik.values
                                          .prospectDetails?.designation
                                          ? [
                                              ...(formik.values.prospectDetails
                                                ?.designation || []),
                                              event.target.value,
                                            ]
                                          : [event.target.value],
                                      });
                                    }
                                  }}
                                  onChange={(event, newValue, reason) => {
                                    formik.setFieldValue('prospectDetails', {
                                      ...(formik.values.prospectDetails || {}),
                                      designation: [...newValue],
                                    });
                                  }}
                                  options={designationOptions}
                                />
                              </Box>
                            </>
                          ) : null}
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: 1,
                        borderBottomLeftRadius: 3,
                        background: '#fff',
                        borderTop: '1px solid #d3d3d3',
                        zIndex: 2,
                      }}
                    >
                      {/* <Button
                        size="small"
                        disabled={activeStep === 0}
                        onClick={() => {
                          setActiveStep((prev) => prev - 1);
                        }}
                        sx={{ mt: 1, mr: 1, padding: '5px 10px' }}
                      >
                        Back
                      </Button> */}
                      <Button
                        variant={'contained'}
                        size="small"
                        type="submit"
                        // type={activeStep === 2 ? 'submit' : 'button'}
                        disabled={
                          processing ||
                          invitingUser ||
                          (formik.errors &&
                            Object.keys(formik.errors || {}) &&
                            Array.isArray(Object.keys(formik.errors || {})) &&
                            Object.keys(formik.errors || {}).length > 0)
                        }
                        startIcon={
                          processing || invitingUser ? (
                            <CircularProgress size={18} />
                          ) : null
                        }
                      >
                        {activeStep === 0 ? 'Invite' : 'Next'}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
              <>
                {/* <Box display={'flex'} alignItems={'center'} gap={2} mt={6}>
                <Divider sx={{ flex: 1 }} />
                <Typography>OR</Typography>
                <Divider sx={{ flex: 1 }} />
              </Box>
              <Box
                mt={3}
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                alignItems={'start'}
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      generateSignupLink(async (data) => {
                        try {
                          await navigator.clipboard.writeText(data);
                          alert('Link Copied.');
                        } catch (err) {
                          alert('Failed to copy link.');
                          console.error('Failed to copy: ', err);
                        }
                      }),
                    );
                  }}
                >
                  Copy Signup Link
                </Button>
                <Typography variant="caption" color={'text.secondary'}>
                  Valid for 15 days only
                </Typography>
              </Box> */}
              </>
            </Box>
          ) : (
            <>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading all invitations
                  </Typography>
                </Box>
              ) : invitations && invitations?.length > 0 ? (
                <Box
                  sx={{
                    minHeight: isXs
                      ? 'calc(100vh - 140px)'
                      : 'calc(100vh - 340px)',
                    maxHeight: isXs
                      ? 'calc(100vh - 140px)'
                      : 'calc(100vh - 340px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    paddingBottom: 2,
                  }}
                  onScroll={handleInvitationsListScroll}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    {invitations?.map((invitation) => (
                      <Card key={invitation?.id} sx={{ padding: 2 }}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            // gap={1}
                          >
                            <Typography
                              fontWeight={'bold'}
                              color={'text.secondary'}
                            >
                              {invitation?.receiverEmail}
                            </Typography>
                            <Typography variant="caption">{`${invitation?.firstName ||
                              ''} ${invitation?.lastName || ''}`}</Typography>
                          </Box>
                          <Typography variant="caption">
                            {invitation?.joined
                              ? moment(invitation.joinedOn).format('DD MMMM YY')
                              : 'Invitation Sent'}
                          </Typography>
                          <Box
                            variant="contained"
                            size="small"
                            sx={{
                              padding: '8px 16px',
                              background: '#d3d3d3',
                              color: '#343a40',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              textTransform={'uppercase'}
                            >
                              {invitation?.teamRoles?.[0] || 'ORG_STANDARD'}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </Box>
                  {fetchingPagination ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 1,
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // type="submit"
                        color="primary"
                        onClick={handleInvitationsListScroll}
                        // className="searchSettings"
                        disabled
                        // fullWidth
                      >
                        <Typography color="textPrimary">
                          Loading more invitations ...
                        </Typography>
                        <CircularProgress size={20} />
                      </Button>
                    </Box>
                  ) : emptyList === false && invitations.length >= 25 ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 1,
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // type="submit"
                        // fullWidth
                        color="primary"
                        onClick={(e) => handleInvitationsListScroll(e, true)}
                        // className="searchSettings"
                      >
                        Load More
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No invites sent yet.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showWarningBox)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '40vw',
            height: 'auto',
            // maxHeight: '40vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.secondary.dark,
              }}
            >
              You have not filled the following fields.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowWarningBox(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2}>
            {!formik.values?.playbookStructure?.productDescription?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Product Description
              </Typography>
            ) : null}
            {!formik.values?.playbookStructure?.keyFeatures?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Product Key Features
              </Typography>
            ) : null}
            {!formik.values?.playbookStructure?.customerQualificationQuestions?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Customer Qualification Questions
              </Typography>
            ) : null}
            {!(
              formik.values?.playbookStructure?.objectionsAndAnswers &&
              formik.values?.playbookStructure?.objectionsAndAnswers?.length >
                0 &&
              formik.values?.playbookStructure?.objectionsAndAnswers?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Typical customer objections and their answers
              </Typography>
            ) : null}
            {!(
              formik.values?.playbookStructure?.challengesForCustomer &&
              formik.values?.playbookStructure?.challengesForCustomer?.length >
                0 &&
              formik.values?.playbookStructure?.challengesForCustomer?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Top customer challenges and recommended responses
              </Typography>
            ) : null}
            {!formik.values?.playbookStructure?.competingProducts?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.secondary.dark,
                }}
              >
                Competing Products and Companies
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderTop={'1px solid #d3d3d3'}
          >
            <Button
              variant="outlined"
              size="small"
              // startIcon={<Close />}
              onClick={() => {
                setActiveStep((prev) => prev + 1);
                setShowWarningBox(false);
              }}
            >
              Continue Anyway
            </Button>
            <Button
              variant="contained"
              size="small"
              // startIcon={<Close />}
              onClick={() => setShowWarningBox(false)}
            >
              Continue Editing
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddCustomMethodForm)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '70vw',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            p={2}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowAddCustomMethodForm(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Methodology Name
                </Typography>
                <TextField
                  autoFocus
                  placeholder="Title"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="title"
                  value={promptRequest?.methodName}
                  onChange={(e) =>
                    setPromptRequest((prev) => {
                      return {
                        ...prev,
                        methodName: e.target.value,
                      };
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  // sx={{ marginBottom: 2 }}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Response Type
                  </FormLabel>
                  <Select
                    // ref={promptSelectRef}
                    labelId="prompt-select-label"
                    // label="Select Prompt"
                    value={promptRequest?.responseType || ''}
                    onChange={(e) => {
                      setPromptRequest((prev) => {
                        return {
                          ...prev,
                          responseType: e.target.value,
                        };
                      });
                    }}
                  >
                    <MenuItem value={'string'}>String</MenuItem>
                    <MenuItem value={'json'}>JSON</MenuItem>
                    <MenuItem value={'html'}>HTML</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    // justifyContent={'space-between'}
                    gap={1}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Placeholders
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    // justifyContent={'space-between'}
                    gap={1}
                  >
                    <TextField
                      autoFocus
                      placeholder="placeholder"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="placeholder"
                      value={placeholder}
                      onChange={(e) => setPlaceholder(e.target.value)}
                      // fullWidth
                    />
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<Add />}
                      onClick={() => {
                        if (placeholder?.trim()) {
                          setPromptRequest((prev) => {
                            return {
                              ...prev,
                              promptSections: [
                                {
                                  ...prev?.promptSections?.[0],
                                  placeHolders: {
                                    ...prev?.promptSections?.[0]?.placeHolders,
                                    [placeholder]: '',
                                  },
                                },
                              ],
                            };
                          });
                          setPlaceholder('');
                        }
                      }}
                    >
                      Add placeholder
                    </Button>
                  </Box>
                  {promptRequest?.promptSections?.[0]?.placeHolders &&
                  Object.keys(
                    promptRequest?.promptSections?.[0]?.placeHolders,
                  ) &&
                  Array.isArray(
                    Object.keys(
                      promptRequest?.promptSections?.[0]?.placeHolders,
                    ),
                  ) &&
                  Object.keys(promptRequest?.promptSections?.[0]?.placeHolders)
                    ?.length > 0 ? (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      flexWrap={'wrap'}
                    >
                      {Object.keys(
                        promptRequest?.promptSections?.[0]?.placeHolders,
                      )?.map((ph, index) => (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                        >
                          <Chip
                            key={ph}
                            label={ph}
                            size="medium"
                            color="primary"
                          />
                          <Tooltip arrow placement="top" title="Remove">
                            <IconButton
                              size="small"
                              onClick={() => {
                                const placeholdersArr = [
                                  ...Object.keys(promptRequest?.placeHolders),
                                ];
                                const updatedPlaceholdersArr = placeholdersArr.filter(
                                  (placeholder) => placeholder !== ph,
                                );
                                setPromptRequest((prev) => {
                                  return {
                                    ...prev,
                                    promptSections: [
                                      {
                                        ...prev?.promptSections?.[0],
                                        placeHolders: updatedPlaceholdersArr?.reduce(
                                          (acc, curr) => ({
                                            ...acc,
                                            [curr]: '',
                                          }),
                                          {},
                                        ),
                                      },
                                    ],
                                  };
                                });
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                      background: '#f1f1f1',
                      padding: '8px 16px',
                    }}
                  >
                    <Typography color={'text.secondary'}>
                      {promptRequest?.promptSections?.[0]?.type}
                    </Typography>
                    <FormControl>
                      <Select
                        size="small"
                        labelId="country-select-label"
                        id="country-select"
                        name="country"
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => {
                          setPromptRequest((prev) => {
                            return {
                              ...prev,
                              promptSections: [
                                {
                                  ...promptRequest?.promptSections?.[0],
                                  optionalSection: e.target.value,
                                },
                              ],
                            };
                          });
                        }}
                        value={
                          promptRequest?.promptSections?.[0]?.optionalSection
                        }
                      >
                        <MenuItem value={true}>Optional</MenuItem>
                        <MenuItem value={false}>Mandatory</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant="body2" color={'text.secondary'}>
                      Section Prompt
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Section Prompt"
                      variant="outlined"
                      color="primary"
                      size="small"
                      multiline
                      rows={7}
                      name="sectionPrompt"
                      value={promptRequest?.promptSections?.[0]?.text}
                      onChange={(e) => {
                        setPromptRequest((prev) => {
                          return {
                            ...prev,
                            promptSections: [
                              {
                                ...promptRequest?.promptSections?.[0],
                                text: e.target.value,
                              },
                            ],
                          };
                        });
                      }}
                      fullWidth
                    />
                    <Typography variant="body2" color={'text.secondary'}>
                      Section Default Prompt (will be used in case any of the
                      placeholder value is not coming in request)
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Section Default Prompt"
                      variant="outlined"
                      color="primary"
                      size="small"
                      multiline
                      rows={4}
                      sx={{ mt: 1 }}
                      name="sectionDefaultPrompt"
                      value={
                        promptRequest?.promptSections?.[0]?.defaultText || ''
                      }
                      onChange={(e) => {
                        setPromptRequest((prev) => {
                          return {
                            ...prev,
                            promptSections: [
                              {
                                ...promptRequest?.promptSections?.[0],
                                defaultText: e.target.value,
                              },
                            ],
                          };
                        });
                      }}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Grid>
              {/* ))} */}
              <Grid item xs={12}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  gap={1}
                >
                  <Button
                    variant="contained"
                    size="small"
                    disabled={
                      promptRequest?.methodName?.trim() === '' ||
                      promptRequest?.promptSections?.[0]?.text === ''
                    }
                    onClick={() => {
                      setFinalPromptRequest(promptRequest);
                      setShowAddCustomMethodForm(false);
                    }}
                  >
                    Add new methodology
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showUpdateFeatureMapping)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '70vw',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={1}
            borderBottom={'1px solid #d3d3d3'}
          >
            <Typography>Configure Feature Mapping</Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowUpdateFeatureMapping(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2} display={'flex'} gap={2} flexDirection={'column'}>
            {Object.keys(featureMapping || {})?.map((feature) => {
              return (
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography variant="body1">{feature}</Typography>
                    <TextField
                      label="Feature Title"
                      variant="outlined"
                      name={'title'}
                      size="small"
                      // fullWidth
                      sx={{
                        width: '60%',
                      }}
                      value={featureMapping?.[feature]?.title}
                      onChange={(e) =>
                        setFeatureMapping((prev) => {
                          return {
                            ...prev,
                            [feature]: {
                              ...prev[feature],
                              title: e.target.value,
                            },
                          };
                        })
                      }
                    />
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setFeatureMapping((prev) => {
                          return Object.keys(prev || {})
                            ?.filter((ftr) => ftr !== feature)
                            ?.reduce((acc, curr) => {
                              if (acc[curr] === undefined) {
                                acc[curr] = prev[curr];
                              }
                              return acc;
                            }, {});
                        });
                      }}
                    >
                      Remove
                    </Button> */}
                  </Box>
                  <Box ml={4}>
                    {Object.keys(
                      featureMapping?.[feature]?.subTitles || {},
                    )?.map((subFeature) => {
                      return (
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography variant="body1">
                              {subFeature}
                            </Typography>
                            <TextField
                              label="Secondary Feature Title"
                              variant="outlined"
                              name={'title'}
                              size="small"
                              // fullWidth
                              sx={{
                                width: '60%',
                              }}
                              value={
                                featureMapping?.[feature]?.subTitles?.[
                                  subFeature
                                ]?.title
                              }
                              onChange={(e) =>
                                setFeatureMapping((prev) => {
                                  return {
                                    ...prev,
                                    [feature]: {
                                      ...prev[feature],
                                      subTitles: {
                                        ...prev[feature].subTitles,
                                        [subFeature]: {
                                          title: e.target.value,
                                        },
                                      },
                                    },
                                  };
                                })
                              }
                            />
                            {/* <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setFeatureMapping((prev) => {
                                  return {
                                    ...prev,
                                    [feature]: {
                                      ...prev[feature],
                                      subTitles: Object.keys(
                                        prev?.[feature]?.subTitles || {},
                                      )
                                        ?.filter(
                                          (sbftr) => sbftr !== subFeature,
                                        )
                                        ?.reduce((acc, curr) => {
                                          if (acc[curr] === undefined) {
                                            acc[curr] = prev[curr];
                                          }
                                          return acc;
                                        }, {}),
                                    },
                                  };
                                });
                              }}
                            >
                              Remove
                            </Button> */}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            p={1}
            borderTop={'1px solid #d3d3d3'}
          >
            <Button
              variant="outlined"
              size="small"
              // startIcon={<Close />}
              onClick={() => setShowUpdateFeatureMapping(false)}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showGeneratePlaybookWithAI)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '90vw',
            height: '95vh',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <PlaybookGeneration
            asComponent={true}
            setShowGeneratePlaybookWithAI={setShowGeneratePlaybookWithAI}
            handlePrefillFromGeneratedPlaybook={
              handlePrefillFromGeneratedPlaybook
            }
          />
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showEditContentScoringConfig)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '95vh',
            maxWidth: '70vw',
            minWidth: '70vw',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
            // paddingTop: 3,
          }}
        >
          <Typography
            variant="body1"
            padding={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            Call Scoring Configurations
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: '16px',
              minHeight: 'calc(95vh - 140px)',
              maxHeight: 'calc(95vh - 140px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
            }}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Configure with
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={configType}
                onChange={(e) => {
                  if (e.target.value === 'form') {
                    try {
                      const outboundObj = JSON.parse(
                        contentScoreConfigText?.outbound || '{}',
                      );
                      const inboundObj = JSON.parse(
                        contentScoreConfigText?.inbound || '{}',
                      );
                      setContentScoringConfig((prev) => {
                        return {
                          ...prev,
                          outbound: outboundObj,
                          inbound: inboundObj,
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to parse json text. please validate the json.',
                      );
                    }
                  } else {
                    try {
                      setContentScoreConfigText((prev) => {
                        return {
                          ...prev,
                          outbound: JSON.stringify(
                            contentScoringConfig?.outbound || '{}',
                            null,
                            2,
                          ),
                          inbound: JSON.stringify(
                            contentScoringConfig?.inbound || '{}',
                            null,
                            2,
                          ),
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to convert json to text. please try again.',
                      );
                    }
                  }
                }}
              >
                <FormControlLabel
                  value={'form'}
                  control={<Radio size="small" />}
                  label={'Form'}
                />
                <FormControlLabel
                  value={'json'}
                  control={<Radio size="small" />}
                  label={'json'}
                />
                <FormControlLabel
                  value={'google sheet'}
                  control={<Radio size="small" />}
                  label={'google sheet'}
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Call Type
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
              >
                <FormControlLabel
                  value={'outbound'}
                  control={<Radio size="small" />}
                  label={'Outbound'}
                />
                <FormControlLabel
                  value={'inbound'}
                  control={<Radio size="small" />}
                  label={'Inbound'}
                />
              </RadioGroup>
            </FormControl>
            {callType === 'inbound' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={sameConfig}
                    name="sameConfig"
                  />
                }
                label={
                  <Typography variant="subtitle2">Same as outbound</Typography>
                }
                onChange={(e) => {
                  setSameConfig(e.target.checked);
                }}
              />
            ) : null}
            {callType === 'outbound' || (callType === 'inbound' && !sameConfig)
              ? Object.keys(contentScoringConfig?.[callType] || {})?.map(
                  (configKey) => {
                    return configKey === 'criteriaList' ? (
                      <Box
                        sx={{
                          border:
                            configType === 'json'
                              ? '1px solid #d3d3d3'
                              : 'none',
                          borderRadius: 2,
                          position: 'relative',
                          // padding: 2,
                        }}
                        key={configKey}
                      >
                        {configType === 'form' ? (
                          <>
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              sx={{
                                position: 'absolute',
                                top: -12,
                                left: 5,
                                background: '#fff',
                                padding: '0 5px',
                                zIndex: 9999,
                              }}
                            >
                              Categories
                            </Typography>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                              // sx={{ background: '#f1f1f1' }}
                              // padding={2}
                            >
                              {contentScoringConfig[callType][configKey] ? (
                                contentScoringConfig[callType][configKey]
                                  ?.length > 0 ? (
                                  <Box sx={{}}>
                                    <Box sx={{ background: '#d3d3d3', p: 2 }}>
                                      {contentScoringConfig[callType][
                                        configKey
                                      ]?.map((criteriaKey, index) => (
                                        <Box
                                          key={
                                            Object.keys(criteriaKey || {})?.[0]
                                          }
                                          display={'flex'}
                                          flexDirection={'column'}
                                          // gap={2}
                                        >
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx={
                                              {
                                                // background: '#f1f1f1',
                                                // padding: 1,
                                              }
                                            }
                                          >
                                            <Typography variant="body1">
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {index + 1}.
                                              </span>{' '}
                                              {
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              }
                                            </Typography>
                                            <IconButton
                                              onClick={() => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]: prev[
                                                          callType
                                                        ][configKey]?.filter(
                                                          (criteriaMap) =>
                                                            Object.keys(
                                                              criteriaMap || {},
                                                            )?.[0] !==
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0],
                                                        ),
                                                        categoryWeight: Object.keys(
                                                          prev[callType][
                                                            'categoryWeight'
                                                          ] || {},
                                                        )
                                                          ?.filter(
                                                            (key) =>
                                                              key !==
                                                              Object.keys(
                                                                criteriaKey ||
                                                                  {},
                                                              )?.[0],
                                                          )
                                                          ?.reduce(
                                                            (acc, curr) => {
                                                              acc[curr] =
                                                                prev[callType][
                                                                  'categoryWeight'
                                                                ][curr];
                                                              return acc;
                                                            },
                                                            {},
                                                          ),
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                            >
                                              <Delete sx={{ fontSize: 18 }} />
                                            </IconButton>
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            sx={{
                                              padding: 1,
                                            }}
                                          >
                                            <Typography flex={1}>
                                              Category Weight
                                            </Typography>
                                            <TextField
                                              placeholder={'Category Weight'}
                                              variant="outlined"
                                              name={'factorKey'}
                                              size="small"
                                              sx={{
                                                flex: 2,
                                              }}
                                              value={
                                                contentScoringConfig[callType][
                                                  'categoryWeight'
                                                ][
                                                  Object.keys(
                                                    criteriaKey || {},
                                                  )?.[0]
                                                ]
                                              }
                                              onChange={(e) => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]:
                                                          prev[callType][
                                                            configKey
                                                          ],
                                                        categoryWeight: {
                                                          ...prev[callType][
                                                            'categoryWeight'
                                                          ],
                                                          [Object.keys(
                                                            criteriaKey || {},
                                                          )?.[0]]: Number(
                                                            e.target.value,
                                                          ),
                                                        },
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                              type={'number'}
                                            />
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            gap={2}
                                          >
                                            {contentScoringConfig[callType][
                                              configKey
                                            ][index][
                                              Object.keys(
                                                criteriaKey || {},
                                              )?.[0]
                                            ] ? (
                                              contentScoringConfig[callType][
                                                configKey
                                              ][index][
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              ]?.length > 0 ? (
                                                <>
                                                  {contentScoringConfig[
                                                    callType
                                                  ][configKey][index][
                                                    Object.keys(
                                                      criteriaKey || {},
                                                    )?.[0]
                                                  ]?.map(
                                                    (
                                                      criteriaKeyFactor,
                                                      factorIndex,
                                                    ) => {
                                                      return (
                                                        <Box
                                                          key={`${
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          }${factorIndex}`}
                                                          display={'flex'}
                                                          flexDirection={
                                                            'column'
                                                          }
                                                          backgroundColor={
                                                            '#fff'
                                                          }
                                                        >
                                                          <Box
                                                            display={'flex'}
                                                            gap={1}
                                                            alignItems={
                                                              'center'
                                                            }
                                                            justifyContent={
                                                              'space-between'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                              border:
                                                                '1px solid #d3d3d3',
                                                            }}
                                                          >
                                                            <Typography variant="body1">
                                                              {
                                                                Object.keys(
                                                                  criteriaKeyFactor ||
                                                                    {},
                                                                )?.[0]
                                                              }
                                                            </Typography>
                                                            <IconButton
                                                              onClick={() => {
                                                                setContentScoringConfig(
                                                                  (prev) => {
                                                                    return {
                                                                      ...prev,
                                                                      [callType]: {
                                                                        ...prev[
                                                                          callType
                                                                        ],
                                                                        [configKey]: prev[
                                                                          callType
                                                                        ][
                                                                          configKey
                                                                        ]?.map(
                                                                          (
                                                                            ck,
                                                                            cki,
                                                                          ) =>
                                                                            cki ===
                                                                            index
                                                                              ? {
                                                                                  [Object.keys(
                                                                                    ck ||
                                                                                      {},
                                                                                  )?.[0]]: prev[
                                                                                    callType
                                                                                  ][
                                                                                    configKey
                                                                                  ][
                                                                                    index
                                                                                  ][
                                                                                    Object.keys(
                                                                                      criteriaKey ||
                                                                                        {},
                                                                                    )?.[0]
                                                                                  ]?.filter(
                                                                                    (
                                                                                      key,
                                                                                    ) =>
                                                                                      Object.keys(
                                                                                        key ||
                                                                                          {},
                                                                                      )?.[0] !==
                                                                                      Object.keys(
                                                                                        criteriaKeyFactor ||
                                                                                          {},
                                                                                      )?.[0],
                                                                                  ),
                                                                                }
                                                                              : ck,
                                                                        ),
                                                                      },
                                                                    };
                                                                  },
                                                                );
                                                              }}
                                                            >
                                                              <Delete
                                                                sx={{
                                                                  fontSize: 18,
                                                                }}
                                                              />
                                                            </IconButton>
                                                          </Box>
                                                          {contentScoringConfig[
                                                            callType
                                                          ][configKey][index][
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0]
                                                          ][factorIndex][
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          ]
                                                            ? Object.keys(
                                                                contentScoringConfig[
                                                                  callType
                                                                ][configKey][
                                                                  index
                                                                ][
                                                                  Object.keys(
                                                                    criteriaKey ||
                                                                      {},
                                                                  )?.[0]
                                                                ][factorIndex][
                                                                  Object.keys(
                                                                    criteriaKeyFactor ||
                                                                      {},
                                                                  )?.[0]
                                                                ] || {},
                                                              )?.map(
                                                                (factorKey) => {
                                                                  return factorKey ===
                                                                    'description' ||
                                                                    factorKey ===
                                                                      'weight' ||
                                                                    factorKey ===
                                                                      'question' ? (
                                                                    <Box
                                                                      display={
                                                                        'flex'
                                                                      }
                                                                      flexDirection={
                                                                        'column'
                                                                      }
                                                                    >
                                                                      <Box
                                                                        display={
                                                                          'flex'
                                                                        }
                                                                        gap={1}
                                                                        // alignItems={'center'}
                                                                        sx={{
                                                                          padding: 1,
                                                                        }}
                                                                      >
                                                                        <Typography
                                                                          flex={
                                                                            1
                                                                          }
                                                                        >
                                                                          {
                                                                            factorKey
                                                                          }
                                                                        </Typography>
                                                                        <TextField
                                                                          placeholder={
                                                                            factorKey
                                                                          }
                                                                          variant="outlined"
                                                                          name={
                                                                            'factorKey'
                                                                          }
                                                                          size="small"
                                                                          multiline={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          rows={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 3
                                                                              : 1
                                                                          }
                                                                          sx={{
                                                                            flex: 2,
                                                                          }}
                                                                          value={
                                                                            contentScoringConfig[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorIndex
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKeyFactor ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorKey
                                                                            ]
                                                                          }
                                                                          onChange={(
                                                                            e,
                                                                          ) => {
                                                                            setContentScoringConfig(
                                                                              (
                                                                                prev,
                                                                              ) => {
                                                                                return {
                                                                                  ...prev,
                                                                                  [callType]: {
                                                                                    ...prev[
                                                                                      callType
                                                                                    ],
                                                                                    [configKey]: prev[
                                                                                      callType
                                                                                    ][
                                                                                      configKey
                                                                                    ]?.map(
                                                                                      (
                                                                                        ck,
                                                                                        cki,
                                                                                      ) =>
                                                                                        cki ===
                                                                                        index
                                                                                          ? {
                                                                                              [Object.keys(
                                                                                                ck ||
                                                                                                  {},
                                                                                              )?.[0]]: prev[
                                                                                                callType
                                                                                              ][
                                                                                                configKey
                                                                                              ][
                                                                                                index
                                                                                              ][
                                                                                                Object.keys(
                                                                                                  criteriaKey ||
                                                                                                    {},
                                                                                                )?.[0]
                                                                                              ]?.map(
                                                                                                (
                                                                                                  key,
                                                                                                  keyIndex,
                                                                                                ) =>
                                                                                                  keyIndex ===
                                                                                                  factorIndex
                                                                                                    ? {
                                                                                                        ...prev[
                                                                                                          callType
                                                                                                        ][
                                                                                                          configKey
                                                                                                        ][
                                                                                                          index
                                                                                                        ][
                                                                                                          Object.keys(
                                                                                                            criteriaKey ||
                                                                                                              {},
                                                                                                          )?.[0]
                                                                                                        ][
                                                                                                          factorIndex
                                                                                                        ],
                                                                                                        [Object.keys(
                                                                                                          criteriaKeyFactor ||
                                                                                                            {},
                                                                                                        )?.[0]]: {
                                                                                                          ...prev[
                                                                                                            callType
                                                                                                          ][
                                                                                                            configKey
                                                                                                          ][
                                                                                                            index
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKey ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ][
                                                                                                            factorIndex
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKeyFactor ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ],
                                                                                                          [factorKey]:
                                                                                                            e
                                                                                                              .target
                                                                                                              .value,
                                                                                                        },
                                                                                                      }
                                                                                                    : key,
                                                                                              ),
                                                                                            }
                                                                                          : ck,
                                                                                    ),
                                                                                  },
                                                                                };
                                                                              },
                                                                            );
                                                                          }}
                                                                          type={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 'text'
                                                                              : 'number'
                                                                          }
                                                                        />
                                                                      </Box>
                                                                    </Box>
                                                                  ) : null;
                                                                },
                                                              )
                                                            : null}
                                                        </Box>
                                                      );
                                                    },
                                                  )}
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                  >
                                                    <TextField
                                                      placeholder="Metric Name"
                                                      variant="outlined"
                                                      name={
                                                        'criteriaFactorName'
                                                      }
                                                      size="small"
                                                      sx={{
                                                        width: '100%',
                                                      }}
                                                      value={criteriaFactorName}
                                                      onChange={(e) =>
                                                        setCriteriaFactorName(
                                                          e.target.value,
                                                        )
                                                      }
                                                      type="text"
                                                    />
                                                    <Button
                                                      variant="contained"
                                                      size="small"
                                                      startIcon={<Add />}
                                                      disabled={
                                                        !criteriaFactorName?.trim()
                                                      }
                                                      onClick={() => {
                                                        setContentScoringConfig(
                                                          (prev) => {
                                                            return {
                                                              ...prev,
                                                              [callType]: {
                                                                ...prev[
                                                                  callType
                                                                ],
                                                                [configKey]: prev[
                                                                  callType
                                                                ][
                                                                  configKey
                                                                ]?.map(
                                                                  (ck, cki) =>
                                                                    cki ===
                                                                    index
                                                                      ? {
                                                                          [Object.keys(
                                                                            criteriaKey,
                                                                          )[0]]: [
                                                                            ...prev[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey,
                                                                              )[0]
                                                                            ],
                                                                            {
                                                                              [criteriaFactorName]: {
                                                                                description:
                                                                                  '',
                                                                                weight: 1,
                                                                                question:
                                                                                  '',
                                                                              },
                                                                            },
                                                                          ],
                                                                        }
                                                                      : ck,
                                                                ),
                                                              },
                                                            };
                                                          },
                                                        );
                                                        setCriteriaFactorName(
                                                          '',
                                                        );
                                                      }}
                                                      fullWidth
                                                    >
                                                      Add new metric
                                                    </Button>
                                                  </Box>
                                                </>
                                              ) : (
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  gap={1}
                                                >
                                                  <TextField
                                                    placeholder="Metric Name"
                                                    variant="outlined"
                                                    name={'criteriaFactorName'}
                                                    size="small"
                                                    sx={{
                                                      width: '100%',
                                                    }}
                                                    value={criteriaFactorName}
                                                    onChange={(e) =>
                                                      setCriteriaFactorName(
                                                        e.target.value,
                                                      )
                                                    }
                                                    type="text"
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<Add />}
                                                    disabled={
                                                      !criteriaFactorName?.trim()
                                                    }
                                                    onClick={() => {
                                                      setContentScoringConfig(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [callType]: {
                                                              ...prev[callType],
                                                              [configKey]: prev[
                                                                callType
                                                              ][configKey]?.map(
                                                                (ck, cki) =>
                                                                  cki === index
                                                                    ? {
                                                                        [Object.keys(
                                                                          criteriaKey,
                                                                        )[0]]: [
                                                                          {
                                                                            [criteriaFactorName]: {
                                                                              description:
                                                                                '',
                                                                              weight: 1,
                                                                              question:
                                                                                '',
                                                                            },
                                                                          },
                                                                        ],
                                                                      }
                                                                    : ck,
                                                              ),
                                                            },
                                                          };
                                                        },
                                                      );
                                                      setCriteriaFactorName('');
                                                    }}
                                                    fullWidth
                                                  >
                                                    Add new metric
                                                  </Button>
                                                </Box>
                                              )
                                            ) : null}
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      p={2}
                                    >
                                      <TextField
                                        placeholder="Category Name"
                                        variant="outlined"
                                        name={'criteriaName'}
                                        size="small"
                                        sx={{
                                          width: '100%',
                                        }}
                                        value={criteriaName}
                                        onChange={(e) =>
                                          setCriteriaName(e.target.value)
                                        }
                                        type="text"
                                      />
                                      <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Add />}
                                        disabled={!criteriaName?.trim()}
                                        onClick={() => {
                                          setContentScoringConfig((prev) => {
                                            return {
                                              ...prev,
                                              [callType]: {
                                                ...prev[callType],
                                                [configKey]: [
                                                  ...prev[callType][configKey],
                                                  {
                                                    [criteriaName]: [],
                                                  },
                                                ],
                                                categoryWeight: {
                                                  ...prev[callType][
                                                    'categoryWeight'
                                                  ],
                                                  [criteriaName]: 1,
                                                },
                                              },
                                            };
                                          });
                                          setCriteriaName('');
                                        }}
                                        fullWidth
                                      >
                                        Add new category
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    p={2}
                                  >
                                    <TextField
                                      placeholder="Category Name"
                                      variant="outlined"
                                      name={'criteriaName'}
                                      size="small"
                                      sx={{
                                        width: '100%',
                                      }}
                                      value={criteriaName}
                                      onChange={(e) =>
                                        setCriteriaName(e.target.value)
                                      }
                                      type="text"
                                    />
                                    <Button
                                      variant="contained"
                                      size="small"
                                      startIcon={<Add />}
                                      disabled={!criteriaName?.trim()}
                                      onClick={() => {
                                        setContentScoringConfig((prev) => {
                                          return {
                                            ...prev,
                                            [callType]: {
                                              ...prev[callType],
                                              [configKey]: [
                                                ...prev[callType][configKey],
                                                {
                                                  [criteriaName]: [],
                                                },
                                              ],
                                              categoryWeight: {
                                                ...prev[callType][
                                                  'categoryWeight'
                                                ],
                                                [criteriaName]: 1,
                                              },
                                            },
                                          };
                                        });
                                        setCriteriaName('');
                                      }}
                                      fullWidth
                                    >
                                      Add new category
                                    </Button>
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </>
                        ) : configType === 'json' ? (
                          <TextField
                            placeholder="JSON CONFIG"
                            variant="outlined"
                            name={'contentScoreConfig'}
                            fullWidth
                            size="small"
                            multiline
                            rows={17}
                            value={contentScoreConfigText[callType]}
                            onChange={(e) =>
                              setContentScoreConfigText((prev) => {
                                return {
                                  ...prev,
                                  [callType]: e.target.value,
                                };
                              })
                            }
                            type={'text'}
                          />
                        ) : (
                          <Box display={'flex'} alignItems={'center'} gap={2}>
                            <TextField
                              placeholder="SHEET URL"
                              variant="outlined"
                              name={'sheetLink'}
                              // fullWidth
                              size="small"
                              value={sheetLink[callType]}
                              onChange={(e) =>
                                setSheetLink((prev) => {
                                  return {
                                    ...prev,
                                    [callType]: e.target.value,
                                  };
                                })
                              }
                              type={'text'}
                              sx={{ flex: 1 }}
                            />
                            <Button
                              // fullWidth
                              variant="contained"
                              size="small"
                              disabled={
                                extractingConfig || !sheetLink[callType]
                              }
                              startIcon={
                                extractingConfig ? (
                                  <CircularProgress size={20} />
                                ) : null
                              }
                              onClick={extractConfig}
                            >
                              Extract Config
                            </Button>
                          </Box>
                        )}
                      </Box>
                    ) : null;
                  },
                )
              : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: 1,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                setShowEditContentScoringConfig('');
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setShowEditContentScoringConfig('');
                if (configType === 'form') {
                  try {
                    setContentScoreConfigText((prev) => {
                      return {
                        ...prev,
                        outbound: JSON.stringify(
                          contentScoringConfig?.outbound || '{}',
                          null,
                          2,
                        ),
                        inbound: JSON.stringify(
                          contentScoringConfig?.inbound || '{}',
                          null,
                          2,
                        ),
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert('Failed to convert json to text. please try again.');
                  }
                } else {
                  try {
                    const outboundObj = JSON.parse(
                      contentScoreConfigText?.outbound || '{}',
                    );
                    const inboundObj = JSON.parse(
                      contentScoreConfigText?.inbound || '{}',
                    );
                    setContentScoringConfig((prev) => {
                      return {
                        ...prev,
                        outbound: outboundObj,
                        inbound: inboundObj,
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert(
                      'Failed to parse json text. please validate the json.',
                    );
                  }
                }
              }}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fixed>
  );
};

export default InviteV2Customer;
